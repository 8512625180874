import { IUserProfile } from "../../redux/settings/user-profile"


export enum userRoles {
    ADMIN = "ADMIN",
    None = "None",
}

export interface IUserAuthInfo {
    user_uuid: string,
    email: string,
    role_id: number,
    user_password: string | null,
    user_fact_id: string | null,
    first_name: string | null,
    last_name: string | null,
    mobile_no: string | null,
    status: "UNAUTHORIZE" | "ACTIVE" | "INACTIVE" | "BLOCKED"
    outlet_uuid: string | null,
    outlet_name: string | null
    role_value: string | null
    outlet_details: IUserProfile["outlet_details"]
}

export const defaultUserAuthInfo: IUserAuthInfo = {
    user_uuid: "",
    email: "",
    role_id: 0,
    user_password: null,
    user_fact_id: null,
    first_name: null,
    last_name: null,
    mobile_no: null,
    status: "ACTIVE",
    outlet_uuid: null,
    outlet_name: null,
    role_value: null,
    outlet_details: []
}

export interface IAuthenticationState {
    authInfo: {
        isOTPPreview: boolean;
        token: string | null;
        name: string;
        user: IUserAuthInfo;
    };
    user_uuid: string | null,
    outlet_uuid: string | null,
    isLoggedIn: boolean;
    loginAsync: (
        email: string,
        password: string,
        onSuccess: (isOTPPreview: boolean, userRole: string) => void,
        onError: (message: string) => void,
    ) => void;
    verifyOTPAsync: (
        otp: string,
        onSuccess: () => void,
        onError: (message: string) => void,
    ) => void;
    logoutAsync: () => void;
    // addUserName: (name: string) => void;
    sendForgetPasswordMailAsync: (
        email: string,
        onSuccess: (message: string) => void,
        onError: (message: string) => void,
    ) => void;
    resetPasswordAsync: (
        email: string,
        password: string,
        otp: number,
        onSuccess: (message: string) => void,
        onError: (message: string) => void,
    ) => void;
}