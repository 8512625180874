import React, { useState } from 'react'
import { Box, Button, InputAdornment, Paper, Typography } from "@mui/material";
import { useAppDispatch, useAppSelector } from '../../../../redux';
import { changeItemQuantitySync, insertItemIntoOrderSync, removeItemFromOrderSync } from '../../../../redux/orders';

import { IProduct } from '../../../../redux/inventory';
import { CustomizeProductDialog } from './CustomizeProductDialog';
import { OutlinedTextField } from '../../../../Components/formComponents';
import { Add, AutoFixHigh, Remove } from '@mui/icons-material';
import { isNotNull } from '../../../../helpers/isNotNull';
import { useOutletContext } from '../../../../providers';



export interface IMenuProductCardProps {
    data: IProduct;
}

export const MenuProductCard: React.FC<IMenuProductCardProps> = ({
    data,
}) => {
    const dispatch = useAppDispatch();
    const { default_tax_rate, OUTLET_CURRENCY_SYMBOL } = useOutletContext()
    const currentOrderInfo = useAppSelector((state) => state.orders.single_order.data)
    const quantity = currentOrderInfo?.menu_items?.find((item) => item.product_uuid === data.product_uuid)?.quantity;

    const [openCustomizationDialog, setOpenCustomizationDialog] = useState<boolean>(false)
    const handleAddItemInOrder = () => {
        dispatch(insertItemIntoOrderSync({
            product: data,
            quantity: (isNotNull(quantity)) + 1,
            outletDefaultTax: isNotNull(default_tax_rate)
        }))
    }

    const handleRemoveItemFromOrder = () => {
        if (quantity && quantity > 0) {
            dispatch(removeItemFromOrderSync({
                product: data,
                quantity: (isNotNull(quantity)) - 1
            }))
        }
    }

    const handleChangeProductQuantity = (itemQuantity: number) => {
        dispatch(changeItemQuantitySync({
            product: data,
            quantity: itemQuantity
        }))
    }

    return (
        <Box
            display={"flex"}
            alignItems={"flex-start"}
            justifyContent={"flex-start"}
        >
            <Paper
                square={false}
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    alignContent: "space-between",
                    bgcolor: "#2C2D2C" /* FBC1D9, 2C2D2C */,
                    color: "#FFFFFF",
                    borderRadius: 1,
                    width: "100%",
                }}
            >
                <Box
                    width={"4%"}
                    bgcolor={"#FBC1D9"}
                    borderRadius={"8px 0 0 8px"}
                ></Box>
                <Box
                    display={"flex"}
                    flexDirection={"column"}
                    gap={1.8}
                    width={"96%"}
                    px={1.5}
                    pb={1.5}
                >
                    <Box pt={1} display={"flex"} justifyContent={"flex-start"} gap={1}>
                        <Typography
                            color={"#D0D0D0"}
                            variant="h5"
                            fontWeight={"bold"}
                        >
                            {data.category_name}
                        </Typography>

                        {data.is_out_of_stock &&
                            <Typography
                                color={"#545454"}
                                variant="h5"
                                fontWeight={"bold"}
                                sx={{
                                    background: "#ffe413",
                                    paddingX: 1,
                                    borderRadius: 0.5
                                }}
                            >
                                Out of stock
                            </Typography>
                        }
                    </Box>
                    <Box display="flex" justifyContent="space-between" alignItems="center" gap={1} >
                        <Box minWidth={"40%"}>
                            <Typography variant="h4" fontWeight={"bold"} letterSpacing={-0.5}>
                                {data.product_name}
                            </Typography>
                            <Typography
                                variant="h4"
                                color={"#D0D0D0"}
                                fontWeight={"600"}
                            >
                                {data.product_price_by === "WEIGHT" ? `${OUTLET_CURRENCY_SYMBOL}${data.product_price}/${data.weight_per_unit}${data.product_weight_unit}` : `${OUTLET_CURRENCY_SYMBOL}${data.product_price}`}
                            </Typography>
                        </Box>

                        <Button variant='contained'
                            disabled={data.is_out_of_stock}
                            onClick={handleRemoveItemFromOrder}>
                            <Remove />
                        </Button>

                        <OutlinedTextField
                            sx={{
                                maxWidth: data.product_price_by === "WEIGHT" ? 100 : 60,
                                "& input[type=number]": {
                                    "-moz-appearance": "textfield",
                                },
                                "& input[type=number]::-webkit-outer-spin-button": {
                                    "-webkit-appearance": "none",
                                    margin: 0,
                                },
                                "& input[type=number]::-webkit-inner-spin-button": {
                                    "-webkit-appearance": "none",
                                    margin: 0,
                                },
                                "& .MuiOutlinedInput-input": {
                                    padding: "6px 6px",
                                    textAlign: "center"
                                },
                            }}
                            type="number"
                            disabled={data.is_out_of_stock}
                            value={quantity || 0}
                            autoFocus
                            onChange={(evt) => {
                                if (Number(evt.target.value) >= 0) {
                                    handleChangeProductQuantity(Number(evt.target.value))
                                }
                            }}
                            InputProps={{
                                endAdornment: data.product_price_by === "WEIGHT" && (
                                    <InputAdornment position="end">
                                        <Typography variant="h5">{data.product_weight_unit}</Typography>
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <Button variant='contained'
                            disabled={data.is_out_of_stock}
                            onClick={handleAddItemInOrder}>
                            <Add />
                        </Button>

                        <Button
                            variant='contained'
                            disabled={data.is_out_of_stock}
                            // size='small'
                            // sx={{ background: "#fff", color: "#000" }}
                            onClick={() => setOpenCustomizationDialog(true)}>
                            <AutoFixHigh />
                        </Button>
                    </Box>

                </Box>
            </Paper>

            {openCustomizationDialog &&
                <CustomizeProductDialog
                    open={openCustomizationDialog}
                    product={data}
                    onClose={() => setOpenCustomizationDialog(false)}
                />}
        </Box>
    );
};

