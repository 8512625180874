/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useNavigate } from "react-router-dom";
import { IStoreState, useAppDispatch, useAppSelector } from "../../../redux";
import { IDataTableProps, MobileLogoRenderType, RenderType } from "../../../Components/Table/interfaces/IDataTableProps";
import PageContainer from "../../../Components/PageContainer/PageContainer";
import { Avatar, Stack, Typography } from "@mui/material";
import { DataTable } from "../../../Components/Table/DataTable";
import { LoadState } from "../../../constants/enum";
import { ISecurityRole, fetchSecurityRolesListAsync } from "../../../redux/settings/security";
import { CustomLink } from "../../../Components/Customlink/CustomLink";
import { StatusRenderer } from "../../../Components/ActiveInActiveStatus/ActiveInActiveStatus";
import { StandardTableActions } from "../../../Components/StandardTableActions/StandardTableActions";
import { SECURITY_GROUPS_ROUTE } from "./Security.constatnts";
import { CustomButton } from "../../../Components/CustomButton";


export const ListSecurityGroups: React.FC = () => {
 
  const { data: securityRolesList, totalRecords, loading } = useAppSelector(
    (storeState: IStoreState) => storeState.settings.security.security_roles_list,
  );
  const [tablePagination, setTablePagination] = React.useState({
    pageNumber: 1,
    rowsInPerPage: 50,
  });
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleClick = () => {
    navigate(`${SECURITY_GROUPS_ROUTE}/create-security-group`);
  };

  const rolesTableProps: IDataTableProps = {
    isPagination: true,
    totalRecords: totalRecords,
    isDataLoading: loading === LoadState.pending,
    mobileLogo: {
      type: MobileLogoRenderType.reactNode,
      onMobileLogoRender: (data: any) => {
        return (
          <Avatar
            sx={{
              width: 45,
              height: 45,
              color: "#fff",
              ml: "-8px",
              mr: 2,
            }}
          ></Avatar>
        );
      },
    },
    columns: [
      {
        key: "role_id",
        headerName: "ID",
        fieldName: "role_id",
        renderType: RenderType.CUSTOM_RENDER,
        onRowCellRender: (value, row) => {
          return (
            <CustomLink
              to={"/create-security-group/" + (row as ISecurityRole).role_uuid}
              label={value.value}
            />
          );
        },
      },
      {
        key: "role_name",
        headerName: "Role Name",
        fieldName: "role_name",
        exportCellWidth: 25,
        renderType: RenderType.TEXT_DARK,
      },

      {
        key: "status",
        headerName: "Status",
        fieldName: "status",
        exportCellWidth: 25,
        renderType: RenderType.CUSTOM_RENDER,
        onRowCellRender: (value, row) => {
          return <StatusRenderer status={row.status} />;
        },
      },

      {
        key: "view",
        headerName: "Actions",
        fieldName: "",
        renderType: RenderType.CUSTOM_RENDER,
        headingAlign: "center",
        onRowCellRender: (value, row: ISecurityRole) => {
          return (
            <StandardTableActions
              onEditClick={() => {
                navigate(`${SECURITY_GROUPS_ROUTE}/create-security-group/${row.role_uuid}`);
              }}
              onDuplicateClick={() => {
                navigate(`${SECURITY_GROUPS_ROUTE}/security-role-duplicate/${row.role_uuid}`)
              }}
            // more={{ menuItems: menuItems }}
            />
          );
        },
      },
    ],

    items: securityRolesList,

    onPageChange: (newPageNumber: number) => {
      setTablePagination({ ...tablePagination, pageNumber: newPageNumber });
    },
    onRowsPerPageChange: (pageNumber: number, rowsPerPage: number) => {
      setTablePagination({
        pageNumber: pageNumber,
        rowsInPerPage: rowsPerPage,
      });
    },
  };

  React.useEffect(() => {
    dispatch(fetchSecurityRolesListAsync());
  }, []);

  return (
    <PageContainer title="Security list" description="this is security list">
        <Stack direction="row" justifyContent="space-between" alignItems="center" my={2} >
          <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={2}>
            <Typography variant='h2'>Security Roles</Typography>
          </Stack>
          <CustomButton
            variant="contained"
            color="primary"
            sx={{
              display: "flex",
              marginLeft: "auto",
            }}
            onClick={handleClick}
          >
            Create Security Group
          </CustomButton>
        </Stack>
        <DataTable {...rolesTableProps} />
    </PageContainer>
  );
};
