import { ACCESSIBLE_MUDULE_KEYS } from "../../../constants/enum";
import { DASHBOARD_ROUTES } from "../../../views/Dashboard/Dashboard.constants";
import { PRODUCTS_ROUTE } from "../../../views/Inventory/Inventory.constants";
import { MARKETING_CAMPAIGNS_ROUTE, MARKETING_COUPON_CODES_ROUTE } from "../../../views/Marketing";
import { RESERVATIONS_LIST_ROUTE } from "../../../views/Reservations/Reservations.constants";
import { COMPANY_ROUTE } from "../../../views/Settings/Company/Companies.constants";
import { COMPANY_OUTLETS_ROUTE } from "../../../views/Settings/CompanyOutlets/CompanyOutlets.constants";
import { RECEIPT_COMPOSER_ROUTE } from "../../../views/Settings/ReceiptComposer/ReceiptComposer.constants";
import { SECURITY_GROUPS_ROUTE, SECURITY_ROLE_GROUPS_ROUTE } from "../../../views/Settings/Security/Security.constatnts";
import { ALL_USERS_LIST_ROUTE } from "../../../views/Settings/UserProfile/UserProfile.constant";
import { ALL_SHIFTS_ROUTE, SHIFTS_PUNCH_IN_OUT_ROUTE } from "../../../views/ShiftAndPunchInOut/ShiftAndPunchInOut.constants";
import { ACCOUNTING_PAYMENT_RECEIVED_ROUTE } from "../../../views/Accounting/Accounting.constants";
import { POS_APP_MENU_ROUTE_NEW_ORDER, POS_ORDERS_ROUTE } from "../../../views/MenuAndOrders/MenuAndOrders.constants";
import { ISidebarMenuItem } from "./Siderbar.types";
import { uberEatsMenu } from "../../../views/UberEats/UberEats.constants";
import { RESERVATIONS_V2_ROUTE } from "../../../views/ReservationsV2/ReservationsV2.constants";


export const posMenu: ISidebarMenuItem[] = [
  {
    title: "Dashboard",
    icon: "Dashboard",
    href: DASHBOARD_ROUTES,
    allowFullAccess: true,
    accessibleModules: [],
  },
  {
    title: "Qr Code",
    icon: "Dashboard",
    href: "/outlet-qr-code",
    allowFullAccess: true,
    accessibleModules: [],
  },
  {
    title: "Menu",
    icon: "Menu",
    href: `${POS_APP_MENU_ROUTE_NEW_ORDER}/create`,
    accessibleModules: [ACCESSIBLE_MUDULE_KEYS.PRODUCT, ACCESSIBLE_MUDULE_KEYS.CATEGORY]
  },
  {
    title: "Orders",
    icon: "Inventory",
    href: POS_ORDERS_ROUTE,
    accessibleModules: [ACCESSIBLE_MUDULE_KEYS.ORDER]
  },
  uberEatsMenu,
  {
    title: "Tables",
    icon: "Table",
    href: "tables",
    accessibleModules: [ACCESSIBLE_MUDULE_KEYS.LAYOUT]
  },
  {
    title: "Reservations",
    icon: "Reservations",
    href: RESERVATIONS_LIST_ROUTE,
    accessibleModules: [ACCESSIBLE_MUDULE_KEYS.RESERVATION]
  },
  {
    title: "Reservations V2",
    icon: "Profile",
    href: RESERVATIONS_V2_ROUTE,
    accessibleModules: [ACCESSIBLE_MUDULE_KEYS.RESERVATION]
  },
  {
    title: "Accounting",
    icon: "Accounting",
    href: ACCOUNTING_PAYMENT_RECEIVED_ROUTE,
    accessibleModules: [ACCESSIBLE_MUDULE_KEYS.PAYMENT_RECEIVED, ACCESSIBLE_MUDULE_KEYS.PAYMENT_PAID,]
  },
  {
    title: "Inventory",
    icon: "Inventory",
    href: PRODUCTS_ROUTE,
    accessibleModules: [ACCESSIBLE_MUDULE_KEYS.PRODUCT, ACCESSIBLE_MUDULE_KEYS.CATEGORY]
  },

  {
    title: "Shifts",
    icon: "Profile",
    href: "/shifts",
    accessibleModules: [ACCESSIBLE_MUDULE_KEYS.SHIFT],
    children: [
      {
        title: "All Shifts",
        icon: "Profile",
        href: ALL_SHIFTS_ROUTE,
        accessibleModules: [ACCESSIBLE_MUDULE_KEYS.SHIFT],
        children: [

        ]
      },
      {
        title: "Punch In/Out",
        icon: "Profile",
        href: SHIFTS_PUNCH_IN_OUT_ROUTE,
        accessibleModules: [ACCESSIBLE_MUDULE_KEYS.SHIFT],
      },
    ]
  },
  {
    title: "Marketing",
    icon: "Marketing",
    href: "/Marketing",
    allowFullAccess: true,
    accessibleModules: [ACCESSIBLE_MUDULE_KEYS.COUPON, ACCESSIBLE_MUDULE_KEYS.CAMPAIGN],
    children: [
      {
        title: "Coupons",
        icon: "Setting",
        href: MARKETING_COUPON_CODES_ROUTE,
        allowFullAccess: true,
        accessibleModules: [ACCESSIBLE_MUDULE_KEYS.COUPON],
      },
      {
        title: "Campaigns",
        icon: "Setting",
        href: MARKETING_CAMPAIGNS_ROUTE,
        allowFullAccess: true,
        accessibleModules: [ACCESSIBLE_MUDULE_KEYS.CAMPAIGN],
      },
    ]
  },
  {
    title: "Setting",
    icon: "Setting",
    href: "/setting",
    accessibleModules: [ACCESSIBLE_MUDULE_KEYS.COMPANY_INFORMATION, ACCESSIBLE_MUDULE_KEYS.OUTLET, ACCESSIBLE_MUDULE_KEYS.RECEIPT_COMPOSER,
    ACCESSIBLE_MUDULE_KEYS.USERS,
    ACCESSIBLE_MUDULE_KEYS.SECURITY, ACCESSIBLE_MUDULE_KEYS.ROLE_GROUPS
    ],
    children: [
      {
        title: "Company",
        icon: "Setting",
        href: COMPANY_ROUTE,
        accessibleModules: [ACCESSIBLE_MUDULE_KEYS.COMPANY_INFORMATION],
      },
      {
        title: "Outlets",
        icon: "Setting",
        href: COMPANY_OUTLETS_ROUTE,
        accessibleModules: [ACCESSIBLE_MUDULE_KEYS.OUTLET],
      },
      // {
      //   title: "Tax",
      //   icon: "Setting",
      //   href: TAX_INFO_ROUTE,
      //   accessibleModules: [ACCESSIBLE_MUDULE_KEYS.CUSTOMERS],
      // },
      {
        title: "Receipts",
        icon: "Setting",
        href: RECEIPT_COMPOSER_ROUTE,
        accessibleModules: [ACCESSIBLE_MUDULE_KEYS.RECEIPT_COMPOSER],
      },
      {
        title: "Users",
        icon: "Setting",
        href: ALL_USERS_LIST_ROUTE,
        accessibleModules: [ACCESSIBLE_MUDULE_KEYS.USERS],
      },
      {
        title: "Security",
        icon: "shields",
        href: SECURITY_GROUPS_ROUTE,
        navLabel: false,
        accessibleModules: [ACCESSIBLE_MUDULE_KEYS.SECURITY],
      },
      {
        title: "Role Groups",
        icon: "boxs",
        href: SECURITY_ROLE_GROUPS_ROUTE,
        navLabel: false,
        accessibleModules: [ACCESSIBLE_MUDULE_KEYS.ROLE_GROUPS],
      },
    ]
  },


];
