import { UberEatsReadyForPickupTable } from "./components/ReadyForPickup/ReadyForPickupTable";
import { UberEatsInProgressOrdersTable } from "./components/InProgressOrders/InProgressOrdersTable";
import { UberEatsNewOrdersTable } from "./components/NewOrders/NewOrdersTable";
import { UBER_EATS_IN_PROGRESS_ORDERS_ROUTE, UBER_EATS_NEW_ORDERS_ROUTE, UBER_EATS_ORDER_HISTORY_ROUTE, UBER_EATS_ORDER_READY_FOR_PICKUP_ROUTE } from "./UberEats.constants";
import { UberEatsOrderHistoryTable } from "./components/OrderHistory/OrderHistoryTable";
import { AuthorizedRoute } from "../../router/AuthorizedRoute";
import { ACCESSIBLE_MUDULE_KEYS } from "../../constants/enum";


export const uberEatsRoutes = [
    {
        path: UBER_EATS_NEW_ORDERS_ROUTE,
        exact: true,
        element: <AuthorizedRoute moduleIds={[ACCESSIBLE_MUDULE_KEYS.UBER_EATS]}><UberEatsNewOrdersTable /></AuthorizedRoute>
    },
    {
        path: UBER_EATS_IN_PROGRESS_ORDERS_ROUTE,
        exact: true,
        element: <AuthorizedRoute moduleIds={[ACCESSIBLE_MUDULE_KEYS.UBER_EATS]}><UberEatsInProgressOrdersTable /></AuthorizedRoute>
    },
    {
        path: UBER_EATS_ORDER_READY_FOR_PICKUP_ROUTE,
        exact: true,
        element: <AuthorizedRoute moduleIds={[ACCESSIBLE_MUDULE_KEYS.UBER_EATS]}><UberEatsReadyForPickupTable /></AuthorizedRoute>
    },
    {
        path: UBER_EATS_ORDER_HISTORY_ROUTE,
        exact: true,
        element: <AuthorizedRoute moduleIds={[ACCESSIBLE_MUDULE_KEYS.UBER_EATS]}><UberEatsOrderHistoryTable /></AuthorizedRoute>
    },
] 