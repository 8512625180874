import React from 'react'
import QRCode from 'react-qr-code'
import { useOutletContext } from '../../providers'
import { useNavigate } from 'react-router-dom'
import { Box, Typography } from '@mui/material'

export const OutletQrCodeView = () => {
  const navigate = useNavigate()
  const { outlet_details: { outlet_uuid } } = useOutletContext()

  const targetURL = `${window.location.origin}/public/dashboard?outlet=${outlet_uuid}`;

  return (
    <Box sx={{ height: "80vh", display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 2, flexDirection: "column", gap: 2 }}>
      <Box sx={{ maxWidth: 400 }}>
        <QRCode
          size={256}
          style={{ height: "auto", maxWidth: "100%", width: "100%" }}
          value={targetURL}
          viewBox={`0 0 256 256`}
        />
      </Box>
      <Typography variant='h3'>Scan QR Code to Check Menu</Typography>
    </Box>
  )
}
