import { ACCESSIBLE_MUDULE_KEYS } from "../../constants/enum";
import { AuthorizedRoute } from "../../router/AuthorizedRoute";
import { ACCOUNTING_BANK_INFO_ROUTE, ACCOUNTING_CHARTS_OF_ACCOUNT_ROUTE, ACCOUNTING_PAYMENT_PAID_ROUTE, ACCOUNTING_PAYMENT_RECEIVED_ROUTE } from "./Accounting.constants";
import { AccountingTabsPanel } from "./AccountingTabsPanel";

export const accountingRoutes = [

    // ##################### Payment Received Route ############################
    {
        path: `${ACCOUNTING_PAYMENT_RECEIVED_ROUTE}`,
        exact: true,
        element: <AuthorizedRoute moduleIds={[ACCESSIBLE_MUDULE_KEYS.PAYMENT_RECEIVED]}><AccountingTabsPanel activeTab={0} /></AuthorizedRoute>
    },
  
    // ##################### Categories Route ############################
    {
        path: ACCOUNTING_PAYMENT_PAID_ROUTE,
        exact: true,
        element: <AuthorizedRoute moduleIds={[ACCESSIBLE_MUDULE_KEYS.PAYMENT_PAID]}><AccountingTabsPanel activeTab={1} /></AuthorizedRoute>
    },
    // ##################### Customization Route ############################
    {
        path: `${ACCOUNTING_BANK_INFO_ROUTE}`,
        exact: true,
        element:<AuthorizedRoute moduleIds={[ACCESSIBLE_MUDULE_KEYS.BANK_INFORMATION]}><AccountingTabsPanel activeTab={2} /></AuthorizedRoute>
    },
    // ##################### Inventory List Route ############################
    {
        path: `${ACCOUNTING_CHARTS_OF_ACCOUNT_ROUTE}`,
        exact: true,
        element: <AuthorizedRoute moduleIds={[ACCESSIBLE_MUDULE_KEYS.CHARTS_OF_ACCOUNTS]}><AccountingTabsPanel activeTab={3} /></AuthorizedRoute>
    },


] 