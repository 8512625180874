import { GET_ORDERS_BASE_URL, axios_api } from "../../../../../api";
import { GET_OUTLET_BASE_URL } from "../../../../../redux/settings/outlets";





export const fetchColumnNamesForReceipt = (key: "OUTLET" | "ORDER") => new Promise<string[]>((resolve, reject) => {

    axios_api.get(`/general/get-table-or-column-name?table_name=${key === "OUTLET" ? 'latest_outlet' : 'latest_orders'}&table_type=VIEW`).then((response) => {
        resolve( response.data.data)
        // const { insert_ts, create_ts, ...resColumns } = data
        // resolve(Object.keys(resColumns).map((column) => column))
    })
})

