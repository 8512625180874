import React from 'react'
import { useAppDispatch } from "../../../../../../redux";
import { BackspaceRounded } from "@mui/icons-material";
import { Typography, Box, Button, Stack } from "@mui/material";
import Masonry from "react-responsive-masonry";
import { CustomRoundButton } from "../../../../../../Components/CustomButton";
import { defaultPaymentReceived, upsertSinglePaymentReceivedAsync } from "../../../../../../redux/accounting/payment-received";
import { useNavigate } from "react-router-dom";
import { IOrder, upsertSingleOrderWithCallbackAsync } from "../../../../../../redux/orders";
import { useFormik } from 'formik';
import { useOutletContext } from '../../../../../../providers';




export interface ICashPaymentScreenProps {
    orderInfo: IOrder;
    onSuccess: (data: IOrder) => void
}
export const CardPaymentScreen: React.FC<ICashPaymentScreenProps> = ({ orderInfo, onSuccess }) => {
     const { OUTLET_CURRENCY_SYMBOL, outlet_details: { outlet_uuid, outlet_name } } = useOutletContext()
    const dispatch = useAppDispatch();


    const {
        values,
        errors,
        isSubmitting,
        setSubmitting,
        handleChange,
        handleSubmit,
        setValues
    } = useFormik({
        initialValues: {
            ...defaultPaymentReceived,
            order_type: orderInfo.order_type,
            order_number: orderInfo.order_number,
            total_tax: orderInfo.total_tax.toString(),
            total_amount: orderInfo.total_amount.toString(),
            order_details: orderInfo.menu_items,
            payment_mode: orderInfo.payment_mode,
            cash_received: orderInfo.total_amount.toString(),
            outlet_uuid,
            outlet_name
        },
        validate: (values) => {
            const errors: any = {};
            // if (!values.category_name) {
            //     errors.category_name = "*This field is required.";
            // }
            return errors;
        },
        onSubmit: async (values) => {
            dispatch(upsertSingleOrderWithCallbackAsync({
                payload: {
                    ...orderInfo,
                    is_paid: 1,
                    payment_mode: "CREDIT_DEBIT_CARD",
                },
                onSuccess(isSuccess, data) {
                    if (isSuccess && data) {
                        dispatch(
                            upsertSinglePaymentReceivedAsync({
                                ...values,
                                order_status: "PAID"
                            })
                        ).then((res => {
                            if (res.meta.requestStatus === "fulfilled") {
                                onSuccess(data)
                            }
                        })).catch((error) => {
                            alert(error.message)
                        }).finally(() => {
                            setSubmitting(false);
                        });
                    }
                },
            })).finally(() => {
                setSubmitting(false);
            });
        },
    });


    return (
        <Box
            sx={{
                minHeight: "50vh",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "center",
                gap: 2,
            }}
            component={"form"}
            onSubmit={handleSubmit}
        >
            <Stack direction="column" justifyContent="center" alignItems="center" spacing={2} mt={5}>
                <Typography variant="h4" fontWeight={"700"}>
                    {`Total Amount : ${OUTLET_CURRENCY_SYMBOL}${values.total_amount}`}
                </Typography>

                <Typography variant="h2" fontWeight={"700"}>
                    Received Amount
                </Typography>
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontWeight: "bold",
                        color: "#fff",
                        gap: 1,
                    }}
                >
                    <Typography variant="h1" letterSpacing={0.5}
                        sx={{
                            border: "2px solid #8e8e8e",
                            padding: "2px 29px",
                            borderRadius: 2,
                            minWidth: 250,
                        }}>
                        {`${OUTLET_CURRENCY_SYMBOL} ${values.cash_received || 0}`}
                    </Typography>
                </Box>
            </Stack>



            <Stack direction="row" width="100%" p={2}>
                <CustomRoundButton
                    title="Complete Order"
                    type="submit"
                    disabled={
                        isNaN(Number(values.cash_received)) ||
                        Number(values.cash_received) < Number(values.total_amount) || isSubmitting
                    }
                    fullWidth />
            </Stack>
        </Box>
    )
}
