import { Box, Button } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { pos_app_routes } from '../constants/constants'
import { usePublicOutletContext } from '../providers/PublicOutletContext'
import { useQueryParams } from '../hooks'

export const PublicDashboard = () => {
    const navigate = useNavigate();
    const outletUUID = useQueryParams("outlet")

    const handleOrderOnlineClick = () => {
        window.localStorage.clear();
        navigate('/auth/login')
    }

    return (
        <Box
            sx={{
                height: "100vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: 2
            }}
        >
            <Button size='large' sx={{ minWidth: 100, minHeight: 50 }} variant='contained'
                onClick={() => navigate(`${pos_app_routes.public.menu}?outlet=${outletUUID}`)}
            >Check Menu</Button>
            <Button size='large' sx={{ minWidth: 100, minHeight: 50 }} variant='contained'
                onClick={handleOrderOnlineClick}
            >Order Online</Button>
        </Box>
    )
}
