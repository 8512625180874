import { Box, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import { NumberDialPad } from "../../../Components/NumberDialPad/NumberDialPad";
import Webcam from "react-webcam";
import PageContainer from "../../../Components/PageContainer/PageContainer";
import { IPunchType, defaultPunchInOut, upsertSinglePunchInOutAsync } from "../../../redux/shifts";
import { useAppDispatch } from "../../../redux";
import { CurrentDateTime } from "./components/CurrentDateTime";
import { useNavigate } from "react-router-dom";
import { useAuthProvider } from "../../../providers";
import { ALL_SHIFTS_ROUTE } from "../ShiftAndPunchInOut.constants";
import { convertBase64ToFile } from "../../../helpers/convertBase64ToFile";

export const ManagePunchInOut = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { authInfo } = useAuthProvider()
  const [fileToUpload, setFileToUpload] = React.useState<File | null>(null);

  const videoConstraints = {
    width: 250,
    height: 250,
    facingMode: "user",
  };
  const webcamRef = React.useRef<Webcam>(null);
  // const capture = React.useCallback(() => {
  //   const imageSrc =
  //     webcamRef.current &&
  //     webcamRef.current.getScreenshot({ width: 1080, height: 1080 });

  //   // Save the image locally
  //   // const downloadLink = document.createElement("a");
  //   // downloadLink.href = imageSrc as string;
  //   // downloadLink.download = "captured_image.jpg"; // You can change the filename as needed
  //   // downloadLink.click();
  // }, [webcamRef]);

  const handleSave = (punchType: IPunchType, loginPin: string) => {
    // capture();
    let fileToUpload = null
    const imageSrc = webcamRef.current && webcamRef.current.getScreenshot({ width: 1080, height: 1080 });
    if (imageSrc) {
      fileToUpload = convertBase64ToFile(imageSrc)
    }
    dispatch(upsertSinglePunchInOutAsync({
      ...defaultPunchInOut,
      login_pin: loginPin,
      type: punchType,
      user_punch_in_out_time: new Date().toISOString(),
      punch_date: new Date().toISOString(),
      user_image: imageSrc || "",
      file: fileToUpload
    })).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        navigate(ALL_SHIFTS_ROUTE)
      }
    }).catch((error) => console.error("error ===> ", error))
  };

  return (
    <PageContainer title="Login" description="login page">
      <Stack
        spacing={2}
        direction={"row"}
        justifyContent={"center"}
      >
        <Typography variant="h2">
          Punch In/Out
        </Typography>
      </Stack>
      <Grid container spacing={5} paddingBlock={4} sx={{ height: "100%" }}>
        <Grid item xs={12} md={6}>
          <Box sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center"
          }}>
            <Box sx={{
              borderRadius: "50%", width: "80%",
              background: "#1e1e1e"
            }}>

            </Box>
            <Webcam
              videoConstraints={videoConstraints}
              screenshotFormat="image/jpeg"
              ref={webcamRef}
              style={{ borderRadius: "50%", width: "80%" }}
            />

            <Box mt={2}>
              <CurrentDateTime />
            </Box>

          </Box>


          {/* <CustomerNameCard name={"John Doe"} url="/shifts" />
          <CustomerNameCard name={"Leslie K."} url="/shifts-2" />
          <CustomerNameCard name={"John Wick"} url="/shifts-3" /> */}
        </Grid>

        <Grid item xs={12} md={5}>
          <NumberDialPad forOtp onSave={handleSave} />
        </Grid>
        {/* {imgSrc && (
        <img src={imgSrc} alt="" style={{ width: "400px", height: "400px" }} />
      )} */}
      </Grid>
    </PageContainer>
  );
};
