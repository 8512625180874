import type { CardProps } from "@mui/material/Card";

import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import { useTheme } from "@mui/material/styles";

import { useTabs } from "../hooks/use-tabs";

import { fPercent, fCurrency } from "../utils/format-number";

import { Label } from "../components/label";
import { Iconify } from "../components/iconify";
import { Chart, useChart } from "../components/chart";
import { CustomTabs } from "../components/custom-tabs";
import { ControlledCustomSelect } from "../../../Components/formComponents";
import moment from "moment";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { IStoreState } from "../../../redux";
import { fetchEarningBoardAsync } from "../../../redux/analytics/analytics.actions";
import { useOutletContext } from "../../../providers";

// ----------------------------------------------------------------------


export function BankingOverview({ sx, ...other }: CardProps) {
  const theme = useTheme();
  const {OUTLET_CURRENCY_SYMBOL} = useOutletContext()
  const { earningGraph, earningGraphLoading } = useSelector(
    (storeState: IStoreState) => storeState.analytics
  );

  const tabs = useTabs("income");
  const [dateFilter, setDateFilter] = React.useState<string>("week");
  const dispatch = useDispatch<any>();

  const chartColors =
    tabs.value === "income" ? ["#fff"] : [theme.palette.warning.dark];

  const chartOptions = useChart({
    colors: chartColors,
    xaxis: {
      categories: earningGraph.categories,
    },
    stroke: { width: 3 },
    tooltip: {
      y: {
        formatter: (value: number) => `${OUTLET_CURRENCY_SYMBOL}${value}`,
        title: { formatter: () => "" },
      },
    },
  });


  const TABS = [
    {
      value: "income",
      label: "Income",
      percent: 8.2,
      total: earningGraph.totalReceive,
      chart: { series: [{ data: earningGraph.data.length > 0 ? earningGraph.data[0].data : [] }] },
    },
    {
      value: "expenses",
      label: "Expenses",
      percent: -6.6,
      total: earningGraph.totalPaid,
      chart: { series: [{ data: earningGraph.data.length > 1 ? earningGraph.data[1].data : [] }] },
    },
  ];

  const renderBalance = (
    <Box sx={{ flexGrow: 1 }}>
      <Box
        sx={{
          mb: 1,
          gap: 0.5,
          display: "flex",
          alignItems: "center",
          color: "#fff",
          typography: "h3",
        }}
      >
        Earnings
        <Tooltip title="Vestibulum ullamcorper mauris">
          <Iconify
            width={16}
            icon="eva:info-outline"
            sx={{ color: "text.disabled" }}
          />
        </Tooltip>
      </Box>
      <Box sx={{ typography: "h3" }}>{`${OUTLET_CURRENCY_SYMBOL}${earningGraph.balance}`}</Box>
    </Box>
  );

  const renderActions = (
    <Box sx={{ gap: 1, display: "flex" }}>
      <ControlledCustomSelect
        label="Search By"
        options={[
          { label: "Daily", value: "day" },
          { label: "Weekly", value: "week" },
          { label: "Monthly", value: "month" },
          { label: "Yearly", value: "year" },
        ]}
        value={dateFilter}
        onChange={(evt) => setDateFilter(evt.target.value as string)}
        sx={{ minWidth: 150 }}
        fullWidth
        size="small"
      />

      {/* <Button
        // variant="soft"
        variant="contained"
        size="small"
        startIcon={<Iconify width={16} icon="eva:arrow-upward-fill" />}
      >
        Send
      </Button>
      <Button
        // variant="soft"
        variant="contained"
        size="small"
        startIcon={<Iconify width={16} icon="mingcute:add-line" />}
      >
        Add card
      </Button>
      <Button
        // variant="soft"
        variant="contained"
        size="small"
        startIcon={<Iconify width={16} icon="eva:arrow-downward-fill" />}
      >
        Request
      </Button> */}
    </Box>
  );

  const renderTabs = (
    <CustomTabs
      value={tabs.value}
      onChange={tabs.onChange}
      variant="fullWidth"
      sx={{
        my: 3,
        borderRadius: 2,
        "& .MuiTab-root.Mui-selected": {
          bgcolor: "#383838",
          borderRadius: 0.75,
        },
      }}
      slotProps={{
        indicator: {
          borderRadius: 1.5,
          // boxShadow: theme.customShadows.z4
        },
        tab: { p: 3 },
      }}
      TabIndicatorProps={{
        sx: {
          backgroundColor: "red",
        },
      }}
    >
      {TABS.map((tab) => (
        <Tab
          key={tab.value}
          value={tab.value}
          sx={
            {
              // bgcolor: tab.value === 1 ? "red",
            }
          }
          label={
            <Box
              sx={{
                width: 1,
                display: "flex",
                gap: { xs: 1, md: 2.5 },
                flexDirection: { xs: "column", md: "row" },
                alignItems: { xs: "center", md: "flex-start" },
                color: "#fff",
              }}
            >
              <Box
                sx={{
                  width: 48,
                  height: 48,
                  flexShrink: 0,
                  borderRadius: "50%",
                  alignItems: "center",
                  color: "#fff",
                  // color: 'primary.lighter',
                  justifyContent: "center",
                  bgcolor: "#5e5e5e",
                  display: { xs: "none", md: "inline-flex" },
                  ...(tab.value === "expenses" && {
                    color: "#fff",
                    // bgcolor: 'warning.darker',
                    bgcolor: "#5e5e5e",
                  }),
                }}
              >
                <Iconify
                  width={24}
                  icon={
                    tab.value === "expenses"
                      ? "eva:diagonal-arrow-right-up-fill"
                      : "eva:diagonal-arrow-left-down-fill"
                  }
                />
              </Box>

              <div>
                <Box
                  sx={{
                    mb: 1,
                    gap: 0.5,
                    display: "flex",
                    alignItems: "center",
                    typography: "h5",
                    fontWeight: "bold",
                  }}
                >
                  {tab.label}
                  <Tooltip title={tab.label} placement="top">
                    <Iconify
                      width={16}
                      icon="eva:info-outline"
                      sx={{ color: "#ddd" }}
                    />
                  </Tooltip>
                </Box>

                <Box sx={{ typography: "h1", fontWeight: "bold" }}>
                  {`${OUTLET_CURRENCY_SYMBOL}${tab.total}`}
                </Box>
              </div>

              <Label
                color={tab.percent < 0 ? "error" : "success"}
                startIcon={
                  <Iconify
                    width={24}
                    icon={
                      tab.percent < 0
                        ? "solar:double-alt-arrow-down-bold-duotone"
                        : "solar:double-alt-arrow-up-bold-duotone"
                    }
                  />
                }
                sx={{
                  background: "#5e5e5e",
                  top: 8,
                  right: 8,
                  position: { md: "absolute" },
                }}
              >
                {tab.percent > 0 && "+"}
                {fPercent(tab.percent)}
              </Label>
            </Box>
          }
        />
      ))}
    </CustomTabs>
  );

  React.useEffect(() => {
    dispatch(fetchEarningBoardAsync({ duration_type: dateFilter, duration: "1" }));
  }, [dateFilter]);

  return (
    <Card sx={{ p: 3, ...sx }} {...other}>
      <Box
        sx={{
          gap: 2,
          display: "flex",
          alignItems: "flex-start",
          flexDirection: { xs: "column", md: "row" },
        }}
      >
        {renderBalance}
        {renderActions}
      </Box>

      {renderTabs}

      <Chart
        type="line"
        series={
          tabs.value === "income" ? TABS[0].chart.series : TABS[1].chart.series as any
        }
        options={chartOptions}
        height={270}
      />
    </Card>
  );
}
