import { ACCESSIBLE_MUDULE_KEYS } from "../../constants/enum";
import { AuthorizedRoute } from "../../router/AuthorizedRoute";
import { CampaignsTable, ManageSingleCampaign } from "./Campaigns";
import { CouponCodesTable, ManageSingleCouponCode } from "./CouponCodes";
import { MARKETING_CAMPAIGNS_ROUTE, MARKETING_COUPON_CODES_ROUTE } from "./Marketing.constants";

export const marketingsRoutes = [
    { path: `${MARKETING_COUPON_CODES_ROUTE}`, exact: true, element: <AuthorizedRoute moduleIds={[ACCESSIBLE_MUDULE_KEYS.COUPON]} allowFullAccess><CouponCodesTable /></AuthorizedRoute> },
    { path: `${MARKETING_COUPON_CODES_ROUTE}/manage`, exact: true, element: <AuthorizedRoute moduleIds={[ACCESSIBLE_MUDULE_KEYS.COUPON]} allowFullAccess><ManageSingleCouponCode /></AuthorizedRoute> },
    { path: `${MARKETING_COUPON_CODES_ROUTE}/manage/:uuid`, exact: true, element: <AuthorizedRoute moduleIds={[ACCESSIBLE_MUDULE_KEYS.COUPON]} allowFullAccess><ManageSingleCouponCode /></AuthorizedRoute> },

    { path: `${MARKETING_CAMPAIGNS_ROUTE}`, exact: true, element: <AuthorizedRoute moduleIds={[ACCESSIBLE_MUDULE_KEYS.CAMPAIGN]} allowFullAccess><CampaignsTable /></AuthorizedRoute> },
    { path: `${MARKETING_CAMPAIGNS_ROUTE}/manage`, exact: true, element: <AuthorizedRoute moduleIds={[ACCESSIBLE_MUDULE_KEYS.CAMPAIGN]} allowFullAccess><ManageSingleCampaign /> </AuthorizedRoute>},
    { path: `${MARKETING_CAMPAIGNS_ROUTE}/manage/:uuid`, exact: true, element: <AuthorizedRoute moduleIds={[ACCESSIBLE_MUDULE_KEYS.CAMPAIGN]} allowFullAccess><ManageSingleCampaign /></AuthorizedRoute> },
]