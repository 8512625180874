/* eslint-disable react-hooks/exhaustive-deps */
import { LockOpen } from "@mui/icons-material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import LockPersonIcon from "@mui/icons-material/LockPerson";
import {
  Box,
  Checkbox,
  Collapse,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import { has, isEmpty, isObject } from "lodash";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { IBreadcrumbProps } from "../../../layout/FullLayout/breadcrumb/IBreadcrumbProps";
import { IStoreState, useAppDispatch, useAppSelector } from "../../../redux";
import { ISecurityGroup, ISecurityGroupWithChildren, clearSecurityRoleGroupListSync, fetchSecurityGroupAsync, fetchSecurityRoleGroupListAsync, upsertSecurityGroupAsync } from "../../../redux/settings/security";
import { PageLoader } from "../../../Components/PageLoader/PageLoader";
import { Breadcrumb } from "../../../layout/FullLayout/breadcrumb/Breadcrumb";
import { StandardCard } from "../../../Components/Card";
import { ControlledCustomSelect, CustomFormLabel, CustomSelect, CustomTextField, OutlinedTextField } from "../../../Components/formComponents";
import { SecurityRoleGroupAutoSearch } from "./SecurityRoleGroupAutoSearch";
import { ACCESSIBLE_MUDULE_KEYS, LoadState } from "../../../constants/enum";
import { RecordPermissionsRightPanel } from "./RecordPermissions/RecordPermissionsRightPanel";
import { produce } from "immer";
import { RoleBasedCustomButton } from "../../../security/RoleBasedComponents/RoleBasedCustomButton/RoleBasedCustomButton";
import { renderDropdownValue } from "../../../helpers/renderDropdownValue";
import { SECURITY_APPROVALS_ROUTE, SECURITY_GROUPS_ROUTE } from "./Security.constatnts";


export const CreateSecurityGroup: React.FC<{ isDuplicate?: boolean }> = (
  props,
) => {
  const { roleId } = useParams() as { roleId?: string };
  const { isDuplicate } = props;

  const BCrumb: IBreadcrumbProps["items"] = [
    {
      to: "/dashboard",
      title: "dashboard",
    },
    {
      to: "/list-security-groups",
      title: "roles",
    },
    {
      title: "Create/Edit Role",
    },
  ];

  const { group, roleName, role_group, status, loading, error } = useAppSelector(
    (storeState: IStoreState) => storeState.settings.security.groups,
  );

  const roleGroupList = useAppSelector(
    (storeState: IStoreState) => storeState.settings.security.roleGroups.data,
  );

  const [saveLoading, setSaveLoading] = React.useState(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [role, setRole] = React.useState(roleId);
  const isUpdate = role ? true : false;

  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    setFieldValue,
    setValues,
  } = useFormik({
    initialValues: {
      roleName: "",
      group: group,
      role_group: "",
      status: "ACTIVE",
    },
    validate: (values) => { },
    onSubmit: (values) => {
      setSaveLoading(true);
      dispatch(
        upsertSecurityGroupAsync({
          data: values.group,
          roleId: roleId || null,
          role_group: values.role_group,
          roleName: values.roleName,
          status: values.status,
          onCallback: (isSuccess: boolean) => {
            if (isSuccess) {
              navigate(SECURITY_GROUPS_ROUTE);
            }
            setSaveLoading(false);
          },
          isDuplicate,
        }),
      ).finally(() => setSaveLoading(false));
    },
  });

  const handleParentAccessChange =
    (parentKey: string) => (key: string, checked: number) => {
      const newState = produce(values, (draftValues) => {
        for (let index in values.group.modules[parentKey].children) {
          draftValues.group.modules[parentKey].children[index][
            key as "view_access"
          ] = checked ? 1 : 0;
        }
      });
      setValues(newState);
    };

  const handlChildAccessChange =
    (parentKey: string) => (key: string, index: number, checked: number) => {
      const newState = produce(values, (draftValues) => {
        draftValues.group.modules[parentKey].children[index][
          key as "view_access"
        ] = checked;
      });
      setValues(newState);
    };

  const handlePremissionsSave =
    (parentKey: string) =>
      (updatedGroup: ISecurityGroup, childIndex: number) => {
        const newState = produce(values, (draftValues) => {
          draftValues.group.modules[parentKey as string].children.splice(
            childIndex,
            1,
            updatedGroup,
          );
        });
        setValues(newState);
      };

  React.useEffect(() => {
    dispatch(fetchSecurityGroupAsync(roleId || ""));
  }, [roleId]);

  React.useEffect(() => {
    dispatch(fetchSecurityRoleGroupListAsync({
      page: 1,
      rowsPerPage: 1000
    }));
    return () => {
      dispatch(clearSecurityRoleGroupListSync());
    };
  }, []);

  React.useEffect(() => {
    let finalRoleName = roleName || "";
    if (isDuplicate) {
      finalRoleName = finalRoleName + " -  Copy";
    }
    setValues({
      ...values,
      group: group,
      roleName: finalRoleName,
      role_group: role_group as string,
      status: status || "ACTIVE",
    });
  }, [group, roleName, role_group, status]);

  return (
    <PageLoader
      loading={loading === LoadState.pending}
      error={error ? { message: error } : null}
    >
      {/* <Breadcrumb title="" items={BCrumb} /> */}

      <form onSubmit={handleSubmit}>
        <StandardCard
          sx={{ mt: 2 }}
          heading={`${isUpdate ? "Edit" : "Create"} Role`}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <OutlinedTextField
                label="Role Name"
                name="roleName"
                value={values.roleName}
                onChange={handleChange}
                disabled={roleId && !isDuplicate ? true : false}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              {/* <CustomSelect
                variant="outlined"
                size="small"
                type="string"
                name="role_group"
                fullWidth
                value={values.role_group}
                onChange={handleChange}
                displayEmpty
                renderValue={renderDropdownValue(`Select One`)}
              >
                {roleGroupList.map((tab) => {
                  return (
                    <MenuItem value={tab.role_group_uuid || ""}>
                      {tab.role_group}
                    </MenuItem>
                  );
                })}
              </CustomSelect> */}
              <SecurityRoleGroupAutoSearch
                label="Role Group"
                value={values.role_group}
                onSelect={(value) => {
                  setValues({
                    ...values,
                    role_group: value.role_group,
                  });
                }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <ControlledCustomSelect
                label="Status"
                fullWidth
                options={[
                  { value: "ACTIVE", label: "ACTIVE" },
                  { value: "INACTIVE", label: "INACTIVE" },
                ]}
                value={values.status}
                onChange={handleChange}
                displayEmpty
                renderValue={renderDropdownValue(`Select One`)}
              />
            </Grid>
          </Grid>

          <Box marginTop={3} marginBottom={4}>
            <Typography variant="h4" fontWeight={500}>
              Module Level Access
            </Typography>
            <Divider />
          </Box>

          <TableContainer component={Paper}>
            <Table
              aria-label="collapsible table"
              sx={{
                whiteSpace: {
                  xs: "nowrap",
                  sm: "unset",
                },
              }}
            >
              <TableHead>
                <TableRow>
                  <TableCell />
                  <TableCell>
                    <Typography variant="h5">Module Name</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="h5">Show</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="h5">Read</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="h5">Write</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="h5">Import</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="h5">Export</Typography>
                  </TableCell>
                  {/* <TableCell>
                    <Typography variant="h5">Send Call</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="h5">Send Mail</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="h5">Send SMS</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="h5">Send Whatsapp</Typography>
                  </TableCell> */}
                  <TableCell>
                    <Typography variant="h5">Permissions</Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.keys(values.group.modules).map((key) => (
                  <Row
                    key={key}
                    moduleName={key}
                    group={values.group.modules[key]}
                    onParentAccessChange={handleParentAccessChange(key)}
                    onChildAccessChange={handlChildAccessChange(key)}
                    onPremissionsChange={handlePremissionsSave(key)}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <Stack direction={"row"} spacing={3} sx={{ mt: 4 }}>
            <RoleBasedCustomButton
              sx={{ width: "15%" }}
              disabled={saveLoading}
              variant="contained"
              moduleId={ACCESSIBLE_MUDULE_KEYS.ROLE_GROUPS}
              type="submit"
            >
              Save
            </RoleBasedCustomButton>
          </Stack>
        </StandardCard>
      </form>
    </PageLoader>
  );
};

function Row(props: {
  moduleName: string;
  group: ISecurityGroupWithChildren;
  onParentAccessChange: (key: string, checked: number) => void;
  onChildAccessChange: (key: string, index: number, checked: number) => void;
  onPremissionsChange: (group: ISecurityGroup, childIndex: number) => void;
}) {
  const { group, moduleName } = props;
  const [open, setOpen] = React.useState(false);
  const [openPremissions, setOpenPremissions] = React.useState<{
    group: ISecurityGroup;
    isSubMoule: boolean;
    index: number;
  } | null>(null);
  const handleOpenPremissions =
    (premission: ISecurityGroup, isSubMoule: boolean, index: number) => () => {
      setOpenPremissions({
        group: premission,
        isSubMoule: isSubMoule,
        index: index,
      });
    };

  const handleParentAccess =
    (key: string) =>
      (e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        props.onParentAccessChange(key, Number(checked));
      };

  const handleChildAccessChange =
    (key: string, index: number) =>
      (e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        props.onChildAccessChange(key, index, Number(checked));
      };

  const handleSave = (updatedModule: ISecurityGroup) => {
    if (openPremissions) {
      props.onPremissionsChange(updatedModule, openPremissions.index);
      setOpenPremissions(null);
    }
  };

  return (
    <>
      <TableRow
        sx={
          open
            ? {
              background: "#363636",
            }
            : null
        }
      >
        <TableCell>
          {group.children.length > 1 && (
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          )}
        </TableCell>
        <TableCell>
          <Typography color="textSecondary" variant="h5" fontWeight="600">
            {moduleName}
          </Typography>
        </TableCell>
        <TableCell>
          <Checkbox
            checked={
              group.children.filter((x) => x.show_module === 1).length ===
              group.children.length
            }
            onChange={handleParentAccess("show_module")}
          />
        </TableCell>
        <TableCell>
          <Checkbox
            checked={
              group.children.filter((x) => x.view_access === 1).length ===
              group.children.length
            }
            onChange={handleParentAccess("view_access")}
          />
        </TableCell>
        <TableCell>
          <Checkbox
            checked={
              group.children.filter((x) => x.edit_access === 1).length ===
              group.children.length
            }
            onChange={handleParentAccess("edit_access")}
          />
        </TableCell>
        <TableCell>
          <Checkbox
            checked={
              group.children.filter((x) => x.bulk_import === 1).length ===
              group.children.length
            }
            onChange={handleParentAccess("bulk_import")}
          />
        </TableCell>
        <TableCell>
          <Checkbox
            checked={
              group.children.filter((x) => x.bulk_export === 1).length ===
              group.children.length
            }
            onChange={handleParentAccess("bulk_export")}
          />
        </TableCell>
        {/* <TableCell>
          <Checkbox
            checked={
              group.children.filter((x) => x.send_call === 1).length ===
              group.children.length
            }
            onChange={handleParentAccess("send_call")}
          />
        </TableCell>
        <TableCell>
          <Checkbox
            checked={
              group.children.filter((x) => x.send_mail === 1).length ===
              group.children.length
            }
            onChange={handleParentAccess("send_mail")}
          />
        </TableCell>
        <TableCell>
          <Checkbox
            checked={
              group.children.filter((x) => x.send_sms === 1).length ===
              group.children.length
            }
            onChange={handleParentAccess("send_sms")}
          />
        </TableCell>
        <TableCell>
          <Checkbox
            checked={
              group.children.filter((x) => x.send_whatsapp === 1).length ===
              group.children.length
            }
            onChange={handleParentAccess("send_whatsapp")}
          />
        </TableCell> */}
        <TableCell>
          {group.children.length === 1 && (
            <Tooltip title="Edit Premissions">
              <IconButton
                disabled={!group.children[0].view_access ? true : false}
                onClick={handleOpenPremissions(group.children[0], false, -1)}
              >
                {!hasKeysInOrAnd(group.children[0].filter_values) ? (
                  <LockPersonIcon
                    color="error"
                    sx={{
                      opacity: !group.children[0].view_access ? 0.3 : 1,
                    }}
                  />
                ) : (
                  <LockOpen
                    color="primary"
                    sx={{
                      opacity: !group.children[0].view_access ? 0.3 : 1,
                    }}
                  />
                )}
              </IconButton>
            </Tooltip>
          )}
        </TableCell>
      </TableRow>
      {group.children.length > 1 && (
        <TableRow>
          <TableCell
            sx={{ paddingBottom: 0, paddingTop: 0, pl: 0, pr: 0 }}
            colSpan={12}
          >
            <Collapse
              in={open}
              timeout="auto"
              unmountOnExit
              sx={{
                background: "#363636"
              }}
            >
              <Box marginBottom={5} marginTop={3}>
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <Typography variant="h6" fontWeight={600}>
                          Submodule Name
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="h6" fontWeight={600}>
                          Show
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="h6" fontWeight={600}>
                          Read
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="h6" fontWeight={600}>
                          Write
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="h6" fontWeight={600}>
                          Import
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="h6" fontWeight={600}>
                          Export
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="h5" fontWeight={600}>
                          Send Call
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="h5" fontWeight={600}>
                          Send Mail
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="h5" fontWeight={600}>
                          Send SMS
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="h5" fontWeight={600}>
                          Send Whatsapp
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="h6" fontWeight={600}>
                          Premissions
                        </Typography>
                      </TableCell>
                      {/* <TableCell>
                    <Typography variant="h6" fontWeight={600}>
                      Bluck Action
                    </Typography>
                  </TableCell> */}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {group.children.map((subModule, index) => {
                      return (
                        <TableRow key={subModule.module_name}>
                          <TableCell component="th" scope="row">
                            <Typography color="textSecondary" fontWeight="400">
                              {subModule.submodule_name}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Checkbox
                              checked={subModule.show_module === 1}
                              onChange={handleChildAccessChange(
                                "show_module",
                                index,
                              )}
                            />
                          </TableCell>
                          <TableCell>
                            <Checkbox
                              checked={subModule.view_access === 1}
                              onChange={handleChildAccessChange(
                                "view_access",
                                index,
                              )}
                            />
                          </TableCell>
                          <TableCell>
                            <Checkbox
                              checked={subModule.edit_access === 1}
                              onChange={handleChildAccessChange(
                                "edit_access",
                                index,
                              )}
                            />
                          </TableCell>
                          <TableCell>
                            <Checkbox
                              checked={subModule.bulk_import === 1}
                              onChange={handleChildAccessChange(
                                "bulk_import",
                                index,
                              )}
                            />
                          </TableCell>
                          <TableCell>
                            <Checkbox
                              checked={subModule.bulk_export === 1}
                              onChange={handleChildAccessChange(
                                "bulk_export",
                                index,
                              )}
                            />
                          </TableCell>
                          <TableCell>
                            <Checkbox
                              checked={subModule.send_call === 1}
                              onChange={handleChildAccessChange(
                                "send_call",
                                index,
                              )}
                            />
                          </TableCell>
                          <TableCell>
                            <Checkbox
                              checked={subModule.send_mail === 1}
                              onChange={handleChildAccessChange(
                                "send_mail",
                                index,
                              )}
                            />
                          </TableCell>
                          <TableCell>
                            <Checkbox
                              checked={subModule.send_sms === 1}
                              onChange={handleChildAccessChange(
                                "send_sms",
                                index,
                              )}
                            />
                          </TableCell>
                          <TableCell>
                            <Checkbox
                              checked={subModule.send_whatsapp === 1}
                              onChange={handleChildAccessChange(
                                "send_whatsapp",
                                index,
                              )}
                            />
                          </TableCell>
                          <TableCell>
                            <Tooltip title="Edit Premissions">
                              <IconButton
                                disabled={!subModule.view_access ? true : false}
                                onClick={handleOpenPremissions(
                                  subModule as any,
                                  true,
                                  index,
                                )}
                              >
                                {!hasKeysInOrAnd(subModule.filter_values) ? (
                                  <LockPersonIcon
                                    color="error"
                                    sx={{
                                      opacity: !subModule.view_access ? 0.3 : 1,
                                    }}
                                  />
                                ) : (
                                  <LockOpen
                                    color="primary"
                                    sx={{
                                      opacity: !subModule.view_access ? 0.3 : 1,
                                    }}
                                  />
                                )}
                              </IconButton>
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      )}

      {openPremissions && (
        <RecordPermissionsRightPanel
          module={openPremissions.group}
          open={true}
          onClose={() => setOpenPremissions(null)}
          onSave={handleSave}
        />
      )}
    </>
  );
}

function hasKeysInOrAnd(filterValues: Record<string, any>): boolean {
  if (isEmpty(filterValues)) {
    return false; // If filterValues is empty, return false
  }

  if (
    has(filterValues, "or") &&
    isObject(filterValues.or) &&
    !isEmpty(filterValues.or)
  ) {
    return true; // 'or' key exists and has keys inside
  } else if (
    has(filterValues, "and") &&
    isObject(filterValues.and) &&
    !isEmpty(filterValues.and)
  ) {
    return true; // 'and' key exists and has keys inside
  }

  return false; // No keys inside 'or' or 'and' or filterValues is empty or not an object
}
