import { createAsyncThunk } from "@reduxjs/toolkit";
import { GET_ORDERS_BASE_URL, UPSERT_ORDERS_BASE_URL } from "../../api/axios-api.endpoints";
import { IOrder } from "./orders.types";
import { IQueryParams, getSearchQuery } from "../../common";
import { axios_api } from "../../api/axios-api";
import { closeLoaderWithMessage, openLoaderWithMessage } from "../common";
import { GET_MARKETING_COUPONS_BASE_URL, ICoupon } from "../marketing";
import { createAsyncThunkPostWrapper } from "../store.wrapper";




export const fetchOrdersListAsync = createAsyncThunk<{ recordCount: number, data: IOrder[] }>(
    'orders/fetchOrdersListAsync', async () => {
        const response = await axios_api.get(`${GET_ORDERS_BASE_URL}`)
        const data = response.data.data;
        const count = response.data.totalRecords
        return { recordCount: count, data: data }
    },
)

export const fetchOrdersListWithArgsAsync = createAsyncThunk<{ recordCount: number, data: IOrder[] }, IQueryParams>(
    'orders/fetchOrdersListWithArgsAsync', async (queryParams: IQueryParams, thunkAPI) => {
        const searchQuery = getSearchQuery(queryParams);
        const response = await axios_api.get(`${GET_ORDERS_BASE_URL}${searchQuery}`)
        const data = response.data.data;
        const count = response.data.totalRecords
        return { recordCount: count, data: data }
    },
)


export const fetchSingleOrderAsync = createAsyncThunk<IOrder, string>(
    'orders/fetchSingleOrderAsync', async (uuid: string, thunkAPI) => {
        const response = await axios_api.get(`${GET_ORDERS_BASE_URL}?order_uuid=${uuid}`)
        const data = response.data.data[0];
        return data
    },
)

export interface IFetchOrderEjs {
    order_uuid: string;
    outlet_uuid: string;
    order_type: "ORDER" | "KITCHEN" | "TAKEOUT" | "REPORT";
    onCallback: (isSuccess: boolean, data?: string) => void
}
export const fetchSingleOrderEJSAsync = createAsyncThunk<IOrder, IFetchOrderEjs>(
    'orders/fetchSingleOrderEJSAsync', async ({ order_uuid, outlet_uuid, order_type, onCallback }: IFetchOrderEjs, thunkAPI) => {
        try {
            thunkAPI.dispatch(openLoaderWithMessage("Fetching Order Receipt...!"))
            const response = await axios_api.get(`/receipt/get-receipt-composer-pdf?order_uuid=${order_uuid}&outlet_uuid=${outlet_uuid}&receipt_type=${order_type}&is_receipt_composer_preview=true`)
            const data = response.data.data.htmlOne;
            onCallback(true, data)
            return thunkAPI.fulfillWithValue(data)
        } catch (error: any) {
            alert(error.message)
            return thunkAPI.rejectWithValue(error.message)
        } finally {
            thunkAPI.dispatch(closeLoaderWithMessage())
        }
    },
)

// export const upsertSingleOrderAsync = createAsyncThunk<IOrder, IOrder>(
//     'orders/upsertSingleCategoryAsync', async (payload: IOrder, thunkAPI) => {
//         const response = await axios_api.post(UPSERT_ORDERS_BASE_URL, payload)
//         // thunkAPI.dispatch(fetchOrdersListWithArgsAsync({
//         //     page: 1,
//         //     rowsPerPage: 25
//         // }))
//         return response.data
//     },
// )


export interface IUpsertSingleOrderWithCallback {
    payload: IOrder,
    onSuccess: (isSuccess: boolean, data?: IOrder) => void
}
export const upsertSingleOrderWithCallbackAsync = createAsyncThunkPostWrapper<IOrder, IUpsertSingleOrderWithCallback>(
    'orders/upsertSingleOrderWithCallbackAsync', async ({ payload, onSuccess }: IUpsertSingleOrderWithCallback, thunkAPI) => {
        const response = await axios_api.post(UPSERT_ORDERS_BASE_URL, payload)
        onSuccess(true, response.data.data)
        return thunkAPI.fulfillWithValue(response.data.data)
        // if (response.status === 200) {
           
        // } else {
        //     onSuccess(false)
        //     return thunkAPI.rejectWithValue(response.status)
        // }
    },
)

interface IValidateSingleCouponCodeAsyncProps {
    coupon_code: string,
    onCallback: (isSuccess: boolean, data?: ICoupon) => void
}
export const validateSingleCouponCodeAsync = createAsyncThunk<ICoupon, IValidateSingleCouponCodeAsyncProps>(
    'orders/validateSingleCouponCodeAsync', async ({ coupon_code, onCallback }: IValidateSingleCouponCodeAsyncProps, thunkAPI) => {
        const response = await axios_api.get(`${GET_MARKETING_COUPONS_BASE_URL}?columns=coupon_code&value=${coupon_code}&pageNo=1&itemPerPage=1`)
        const data: ICoupon = response.data.data[0];
        if (data && data.coupon_code === coupon_code) {
            onCallback(true, data)
        } else {
            onCallback(false)
        }
        return data
    },
)