import React, { useEffect } from "react";
import { useAppDispatch } from "../../redux";
import { clearSingleOrderStateSync } from "../../redux/orders";
import { POS_ORDERS_ROUTE, POS_APP_MENU_ROUTE_NEW_ORDER } from "./MenuAndOrders.constants";
import { OrderMenuLayout } from "./OrderMenu/OrderMenuLayout";
import { POSOrdersTable } from "./PosOrders/POSOrdersTable";
import { useNavigate } from "react-router-dom";
import { AuthorizedRoute } from "../../router/AuthorizedRoute";
import { ACCESSIBLE_MUDULE_KEYS } from "../../constants/enum";

export const CreateNewOrder: React.FC<{}> = () => {

    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    useEffect(() => {
        dispatch(clearSingleOrderStateSync())
        navigate(`${POS_APP_MENU_ROUTE_NEW_ORDER}?type=TAKE_AWAY`)
    }, [])

    return <></>
}

export const posOrderRoutes = (isUserView?: boolean) => {

    const baseMenuPath = isUserView ? "/user" : "";

    const orderRoute = baseMenuPath + POS_ORDERS_ROUTE
    const menuRoute = baseMenuPath + POS_APP_MENU_ROUTE_NEW_ORDER
    return [
        {
            path: orderRoute,
            exact: true,
            element: <AuthorizedRoute moduleIds={[ACCESSIBLE_MUDULE_KEYS.ORDER]}><POSOrdersTable /></AuthorizedRoute>
        },
        {
            path: `${orderRoute}/view/:uuid`,
            exact: true,
            element: <AuthorizedRoute moduleIds={[ACCESSIBLE_MUDULE_KEYS.ORDER]}><OrderMenuLayout /></AuthorizedRoute>
        },
        {
            path: `${menuRoute}/create`,
            exact: true,
            element: <AuthorizedRoute moduleIds={[ACCESSIBLE_MUDULE_KEYS.ORDER]}><CreateNewOrder /></AuthorizedRoute>
        },
        {
            path: `${menuRoute}`,
            exact: true,
            element: <AuthorizedRoute moduleIds={[ACCESSIBLE_MUDULE_KEYS.ORDER]}><OrderMenuLayout /></AuthorizedRoute>
        },
        {
            path: `${menuRoute}/edit/:uuid`,
            exact: true,
            element: <AuthorizedRoute moduleIds={[ACCESSIBLE_MUDULE_KEYS.ORDER]}><OrderMenuLayout /></AuthorizedRoute>
        },
    ]
}
