import { Box, SxProps, Theme } from "@mui/material";
import React from "react";
import { Helmet } from "react-helmet-async";

interface IPageContainerProps {
  title: string;
  description: string;
  children: React.ReactNode;
  sx?: SxProps<Theme>
}

const PageContainer: React.FC<IPageContainerProps> = (props) => {
  const { title, description, children, sx } = props;
  return (
    <Box sx={sx}>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
      </Helmet>
      {children}
    </Box>
  );
};

export default PageContainer;
