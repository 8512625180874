import { createSlice } from "@reduxjs/toolkit";
import { LoadState } from "../../../constants/enum";
import { fetchProductListAsync, fetchProductListWithArgsAsync, fetchPublicProductsListAsync, fetchSingleProductAsync } from "./products.actions";
import { defaultProductState } from "./products.state";

const productSlice = createSlice({
    initialState: defaultProductState,
    name: "product",
    reducers: {
        clearProductStateSync: (state) => {
            return defaultProductState
        },
        clearSingleProductStateSync: (state) => {
            state.single_product.data = defaultProductState["single_product"]["data"]
            state.single_product.loading = defaultProductState["single_product"]["loading"]
            state.single_product.error = defaultProductState["single_product"]["error"]
        },
    },
    extraReducers: (builder) => {
        //############################# fetchPublicProductsListAsync ######################################
        builder.addCase(fetchPublicProductsListAsync.pending, (state, action) => {
            state.product_list.loading = LoadState.pending
        })
        builder.addCase(fetchPublicProductsListAsync.fulfilled, (state, action) => {
            state.product_list.loading = LoadState.succeeded
            state.product_list.data = action.payload.data
            state.product_list.totalRecords = action.payload.recordCount
            state.product_list.error = null
        })
        builder.addCase(fetchPublicProductsListAsync.rejected, (state, action) => {
            state.product_list.error = action.error.message as string
        })

        //############################# fetchProductListAsync ######################################
        builder.addCase(fetchProductListAsync.pending, (state, action) => {
            state.product_list.loading = LoadState.pending
        })
        builder.addCase(fetchProductListAsync.fulfilled, (state, action) => {
            state.product_list.loading = LoadState.succeeded
            state.product_list.data = action.payload.data
            state.product_list.totalRecords = action.payload.recordCount
            state.product_list.error = null
        })
        builder.addCase(fetchProductListAsync.rejected, (state, action) => {
            state.product_list.error = action.error.message as string
        })

        //############################# fetchProductListWithArgsAsync ######################################
        builder.addCase(fetchProductListWithArgsAsync.pending, (state, action) => {
            state.product_list.loading = LoadState.pending
        })
        builder.addCase(fetchProductListWithArgsAsync.fulfilled, (state, action) => {
            state.product_list.loading = LoadState.succeeded
            state.product_list.data = action.payload.data
            state.product_list.totalRecords = action.payload.recordCount
            state.product_list.error = null
        })
        builder.addCase(fetchProductListWithArgsAsync.rejected, (state, action) => {
            state.product_list.error = action.error.message as string
        })


        //#################################### Single Product ##############################################
        builder.addCase(fetchSingleProductAsync.pending, (state, action) => {
            state.single_product.loading = LoadState.pending
        })
        builder.addCase(fetchSingleProductAsync.fulfilled, (state, action) => {
            state.single_product.loading = LoadState.succeeded
            state.single_product.data = action.payload
            state.single_product.error = null
        })
        builder.addCase(fetchSingleProductAsync.rejected, (state, action) => {
            state.single_product.error = action.error.message as string
        })
    },
});

export const productReducer = productSlice.reducer;
export const {
    clearProductStateSync,
    clearSingleProductStateSync
} = productSlice.actions;
