import React, { useState } from 'react'
import { ControlledCustomSelect } from '../../../../../Components/formComponents'
import { IStoreState, useAppDispatch, useAppSelector } from '../../../../../redux';
import { fetchOutletsListAsync } from '../../../../../redux/settings/outlets';
import { fetchSecurityRolesListAsync } from '../../../../../redux/settings/security';

interface IOutletsDropDownProps {
    value: string | null
    onChange: (data: string) => void
    error?: string
}
export const RolesDropDown: React.FC<IOutletsDropDownProps> = ({
    value,
    onChange,
    error
}) => {
    const {
        data: outletsList,
        loading,
    } = useAppSelector((storeState: IStoreState) => storeState.settings.security.security_roles_list);
    const dispatch = useAppDispatch();

    const [companiesList, setCompaniesList] = useState<{ label: string, value: string | number }[]>([])

    React.useEffect(() => {
        dispatch(
            fetchSecurityRolesListAsync(),
        );
    }, []);

    React.useEffect(() => {
        const newList = outletsList.map((data) => ({ label: data.role_name || "", value: data.role_uuid || "" }))
        setCompaniesList(newList)
    }, [outletsList]);

    return (
        <ControlledCustomSelect
            label="Select Role"
            options={companiesList}
            value={value}
            onChange={(evt) => onChange(evt.target.value as string)}
            sx={{ minWidth: 120 }}
            fullWidth size="small"
            error={error}
        />
    )
}
