import { createAsyncThunk } from "@reduxjs/toolkit"
import { axios_api } from "../../../api/axios-api"
import { IQueryParams, getSearchQuery } from "../../../common"
import { ICompany } from "./company.types"
import { uploadSingleFileAsync } from "../../../helpers/uploadFilesAsync"
import { closeLoaderWithMessage, openLoaderWithMessage } from "../../common"
import { defaultCompany } from "./company.state"

const GET_COMPANY_BASE_URL = "/company/get-company"
const UPSERT_COMPANY_BASE_URL = "/company/upsert-company"


export const fetchCompanyListAsync = createAsyncThunk<{ data: ICompany[], count: number }, IQueryParams>(
    'company/fetchCompanyListAsync', async (queryParams: IQueryParams) => {
        const searchQuery = getSearchQuery(queryParams);
        const response = await axios_api.get(`${GET_COMPANY_BASE_URL}${searchQuery}`)
        return response.data as { data: ICompany[], count: number }
    },
)

export const fetchSingleCompanyAsync = createAsyncThunk<ICompany>(
    'company/fetchSingleCompanyAsync', async () => {
        const response = await axios_api.get(`${GET_COMPANY_BASE_URL}`)
        return response.data.data[0] || defaultCompany
    },
)

interface IUpsertCompany extends ICompany {
    file: File | null,
}

export const upsertCompanyInfoAsync = createAsyncThunk<ICompany, IUpsertCompany>(
    'company/upsertCompanyInfoAsync', async (payload: IUpsertCompany, thunkAPI) => {
        const { file, create_ts, insert_ts, ...restPayload } = payload
        thunkAPI.dispatch(openLoaderWithMessage("Saving Changes..!"))
        if (file) {
            const path = await uploadSingleFileAsync({
                file,
                moduleName: "COMPANY",
                asPayload: {
                    company_name: payload.company_name,
                    file_name: file.name,
                    file_ext: file.name.split('.').pop() || "",
                }
            })
            restPayload.company_logo = path
        }

        const response = await axios_api.post(UPSERT_COMPANY_BASE_URL, restPayload)
        thunkAPI.dispatch(closeLoaderWithMessage())
        return response.data
    },
)


