import React from "react";
import { Box, Paper, Typography } from "@mui/material";
import { FaCoffee } from "react-icons/fa";
import { ICategory } from "../../../redux/inventory";


export interface IMenuCategoryCardProps {
    data: ICategory,
    isActive: boolean,
    onClick: () => void
}
export const MenuMenuCategoryCard: React.FC<IMenuCategoryCardProps> = ({
    data,
    isActive,
    onClick
}) => {

    return (
        <Box display={"flex"}
        >
            <Paper
                elevation={1}
                square={false}
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignContent: "flex-start",
                    gap: 4,
                    bgcolor: isActive ? "#FBC1D9" : "#E5DADE",
                    color: "black",
                    px: 1.5,
                    paddingY: 2,
                    borderRadius: 1,
                    width: "100%",
                    // height: "150px",
                    cursor: "pointer"
                }}
                onClick={onClick}
            >
                <Box>
                    <Typography variant="h4" fontWeight={"bold"} letterSpacing={-0.5}>
                        {data.category_name}
                    </Typography>
                    <Typography variant="h6" color={"#595959"} fontWeight={"500"}>
                        {/* {data.number_of_items} Items */}
                    </Typography>
                </Box>
                <Box sx={{ height: 36, width: 36 }}>
                    <FaCoffee fontSize={"2.2rem"} />
                </Box>
            </Paper>
        </Box>
    );
};
