import { LoadState } from "../../constants/enum";
import { IOrder } from "../orders";
import { IPunchInOut, IShiftPunchInOutState } from "../shifts";


export interface IAnalyticsState {
  earningGraph: IAnalyticsEarningGraph;
  earningGraphLoading: LoadState;

  take_away_orders_list: {
    loading: LoadState
    data: IOrder[];
    totalRecords: number;
    error: string | null;
  },
  expense_categories_list: {
    loading: LoadState
    data: {
      category: string,
      amount: number
    }[];
    totalRecords: number;
    error: string | null;
    overview: {
      categories: number,
      total_amount: number
    }[]
  }
  active_shifts_list: {
    loading: LoadState
    data: IPunchInOut[];
    totalRecords: number;
    error: string | null;
  },
}

export interface IAnalyticsEarningGraph {
  balance: string;
  totalReceive: string;
  totalPaid: string;
  categories: string[];
  data: {
    name: string;
    data: string[];
  }[];

}

export const initialAccountingDashboardState: IAnalyticsState = {
  earningGraph: {
    balance: "",
    totalReceive: "",
    totalPaid: "",
    categories: [],
    data: [],
  },
  earningGraphLoading: LoadState.pending,

  take_away_orders_list: {
    loading: LoadState.idle,
    data: [],
    totalRecords: 0,
    error: null,
  },
  expense_categories_list: {
    loading: LoadState.idle,
    data: [],
    totalRecords: 0,
    error: null,
    overview: []
  },
  active_shifts_list: {
    loading: LoadState.idle,
    data: [],
    totalRecords: 0,
    error: null,
  },
};
