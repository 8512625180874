import {


    Dialog as MuiDialog
} from '@mui/material';
import React, { useState } from 'react'
import { IUberEatsOrder } from '../../../../redux/uber-eats';
import { IOrderScreenType } from './ViewOrderDialog.types';
import { OrderDetailsScreen } from './components/OrderDetailsScreen';
import { HelpListViewScreen } from './components/HelpListViewScreen';
import { DelayOrderScreen } from './components/DelayOrderScreen';
import { CancelOrderScreen } from './components/CancelOrderScreen';
import { useOutletContext } from '../../../../providers';


export interface IUberEatsViewOrderDialogProps {
    open: boolean;
    onClose: () => void;
    order_info: IUberEatsOrder
}

export const UberEatsOrderDialog: React.FC<IUberEatsViewOrderDialogProps> = ({
    open,
    onClose,
    order_info
}) => {
    const { OUTLET_CURRENCY_SYMBOL } = useOutletContext()
    const [currentScreen, setCurrentScreen] = useState<IOrderScreenType>("ORDER")

    return (
        <MuiDialog
            open={open}
            maxWidth="sm"
            sx={{
                minWidth: 600,
                "& .MuiPaper-root": {
                    minHeight: 520,
                    minWidth: 600
                }
            }}
            onClose={onClose}
        >
            {/* <IconButton
                aria-label="close"
                onClick={onClose}
                sx={{
                    position: 'absolute',
                    padding: 0,
                    right: 0,
                    top: 0,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <Cancel sx={{fontSize: 32}} />
            </IconButton> */}

            {currentScreen === "ORDER" ? (
                <OrderDetailsScreen
                    order_info={order_info}
                    onHelpIconClick={() => setCurrentScreen("HELP")}
                />
            )
                : currentScreen === "HELP" ? (
                    <HelpListViewScreen
                        order_info={order_info}
                        onBackIconClick={() => setCurrentScreen("ORDER")}
                        onListClick={(type) => setCurrentScreen(type)}
                    />
                )
                    : currentScreen === "DELAY" ? (
                        <DelayOrderScreen
                            order_info={order_info}
                            onBackIconClick={() => setCurrentScreen("ORDER")}
                            onListClick={(type) => setCurrentScreen(type)}
                            onClose={onClose}
                        />
                    )
                        : (
                            <CancelOrderScreen
                                order_info={order_info}
                                onBackIconClick={() => setCurrentScreen("ORDER")}
                                onListClick={(type) => setCurrentScreen(type)}
                                onClose={onClose}
                            />
                        )
            }

        </MuiDialog>
    )
}



// export const MuiDialogTitle: React.FC<{ screen_type: IOrderScreenType, order_info: IUberEatsOrder }> = ({
//     screen_type,
//     order_info
// }) => {
//     if (screen_type === "HELP" || screen_type === "DELAY" || screen_type === "CANCEL") {
//         return (
//             <Box sx={{
//                 width: '100%',
//                 display: "flex",
//                 justifyContent: "flex-start",
//                 alignItems: "center",
//                 gap: 2,
//                 background: "#292929",
//                 paddingInline: 2,
//                 paddingBlock: 2,
//                 borderRadius: 1,
//                 cursor: "pointer"
//             }}
//             >
//                 <Typography variant='h3'>
//                     {screen_type === "HELP" ? `Help with order ${order_info.display_id}`
//                         : screen_type === "DELAY" ? "Delay"
//                             : "Cancel"
//                     }
//                 </Typography>

//             </Box >
//         )
//     }

//     return (
//         <Box sx={{
//             width: '100%',
//             display: "flex",
//             justifyContent: "space-between",
//             alignItems: "center",
//             gap: 2,
//             background: "#292929",
//             paddingInline: 2,
//             paddingBlock: 2,
//             borderRadius: 1,
//             cursor: "pointer"
//         }}
//         >
//             <Box sx={{
//                 display: "flex",
//                 flexDirection: "column",
//                 justifyContent: "center",
//                 alignItems: "flex-start",
//             }} >
//                 <Typography variant='h3'>Michelle T.</Typography>
//                 <Box sx={{
//                     display: "flex",
//                     flexDirection: "row",
//                     justifyContent: "flex-start",
//                     alignItems: "center",
//                     gap: 1
//                 }} >
//                     <Typography variant='h5'>3WEWSF3</Typography>
//                     {/* <Chip  label="EAT IN" size='small' sx={{padding: "4px 8px", fontWeight: "700", borderRadius: 1}} /> */}
//                 </Box>

//             </Box>

//             <Box sx={{
//                 display: "flex",
//                 justifyContent: "flex-end",
//                 alignItems: "center",
//                 gap: 1
//             }} >
//                 <Box sx={{
//                     display: "flex",
//                     flexDirection: "column",
//                     justifyContent: "center",
//                     alignItems: "flex-end",
//                 }} >
//                     <Typography variant='h5'>11:45am</Typography>
//                     <Typography variant='body1'>Due in 15 mins</Typography>
//                 </Box>
//                 <CustomButton
//                     variant="outlined"
//                     color="primary"
//                     size="large"
//                     sx={{
//                         minWidth: 100,
//                         paddingInline: "16px !important"
//                     }}

//                     startIcon={<Help />}
//                 >
//                     Help
//                 </CustomButton>
//             </Box>
//         </Box >
//     )
// }

