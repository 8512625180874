import { ISelectOption } from "./types";

export const STANDARD_APP_DATE_FORMAT = "MM/DD/YYYY";
export const STANDARD_APP_TIME_FORMAT = "HH:mm:ss";
export const STANDARD_APP_DATE_TIME_FORMAT = "MM/DD/YYYY hh:mm A";


export const AIR_KITCHEN_ROUTE = "/air-kitchen"
export const POS_ROUTE = ""


export const RUPPES_SYMBOL = "$"
export const DOLLER_SYMBOL = "$"


export const error_message = {
  required: "This field is required",
  email: "Email is required",
  invalid_email: "Invalid email address",
  phone: "Phone is required",
  date: "Date is required",
};




// Layout and colors CONSTANTS
export const LIGHT_THEME = "LIGHT_THEME";
export const DARK_THEME = "DARK_THEME";
export const THEME_COLOR = "THEME_COLOR";
export const NAVBAR_BG = "NAVBAR_BG";
export const SIDEBAR_BG = "SIDEBAR_BG";
export const DIRECTION = "DIRECTION";
export const BLUE_THEME = "BLUE_THEME";
export const LIGHT_BLUE_THEME = "LIGHT_BLUE_THEME";
export const GREEN_THEME = "GREEN_THEME";
export const RED_THEME = "RED_THEME";
export const BLACK_THEME = "BLACK_THEME";
export const PURPLE_THEME = "PURPLE_THEME";
export const INDIGO_THEME = "INDIGO_THEME";
export const ORANGE_THEME = "ORANGE_THEME";
export const MAROON_THEME = "MAROON_THEME";
export const ROCKWORTH_THEME = "ROCKWORTH_THEME";
export const USER_LOGOUT = "USER_LOGOUT";



export const MUI_MINIMAL_THEME_IMAGES_DIR = "https://assets.minimals.cc/public"


export const DEFAULT_CURRENCY = "USD";

export const currency_symbol = {
  USD: "$",
  INR: "₹",
  EUR: "€",
  CAD: "$",
  AUD: "$",
}

export const outlet_currencies_list: ISelectOption[] = [
  { label: `${currency_symbol['CAD']} CAD`, value: "CAD" },
  { label: `${currency_symbol['USD']} USD`, value: "USD" },
  { label: `${currency_symbol['INR']} INR`, value: "INR" },
  { label: `${currency_symbol['EUR']} EUR`, value: "EUR" },
  { label: `${currency_symbol['AUD']} AUD`, value: "AUD" },
]



export const pos_app_routes = {
  public: {
    root: "/public",
    dashboard: "/public/dashboard",
    menu: "/public/menu"
  },
  private: {
    users: {
      userProfile: "/my-profile",
    },
    accounting: {
      userPaymentHistory: "/payment-history"
    },
    orders: {
      userOrderHistory: "/order-history"
    }
  }
}