import React, { useEffect, useState } from "react";
import { Grid, } from "@mui/material";
import { useFormik } from "formik";
import { ICreateNewUserDialogProps } from "./CreateNewUserDialog.types";
import { Dialog } from "../../../../Components/Dialogs/Dialog";
import { OutlinedTextField } from "../../../../Components/formComponents";
import { useAppDispatch } from "../../../../redux";
import { OutletsDropDown } from "../../CompanyOutlets/components/OutletsDropDown";
import { UserRoleAutoSearch } from "../autoSearch/UserRoleAutoSearch";
import { defaultCreateUser } from "../../../../redux/settings/user-profile";
import { createNewUserUserAsync } from "../../../../redux/settings/user-profile/user-profile.actions";
import { OutletsAutoSearch } from "../../CompanyOutlets/AutoSearch/OutletsAutoSearch";
import { OutletMultiSelect } from "../../CompanyOutlets/components/OutletMultiSelect";



export const CreateNewUserDialog: React.FC<ICreateNewUserDialogProps> = (
  props
) => {
  const { open, onClose } = props;
  const dispatch = useAppDispatch();
  const [saveLoading, setSaveLoading] = useState<boolean>(false)
  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    setValues,
    setFieldValue
  } = useFormik({
    initialValues: defaultCreateUser,
    validate: (values) => {
      const errors: any = {};
      if (!values.first_name) {
        errors.first_name = "First name is required.";
      } if (!values.last_name) {
        errors.last_name = "Last name is required.";
      }
      if (!values.email) {
        errors.email = "Email is required.";
      } else if (
        values.email !== "" &&
        !/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(
          values.email,
        )
      ) {
        errors.email = "*Invalid email address.";
      }
      if (!values.user_password) {
        errors.user_password = "Password is required.";
      } else if (values.user_password.length < 6) {
        errors.user_password = "Password must be at least 6 characters long.";
      }
      if (!values.confirmPassword) {
        errors.confirmPassword = "Confirm password is required.";
      } else if (values.user_password !== values.confirmPassword) {
        errors.confirmPassword = "Passwords are not matching.";
      }
      // if (values.outlet_details && values.outlet_details?.length < 1) {
      //   errors.outlet_details = "*User has at least one outlet";
      // }
      if (!values.role_uuid) {
        errors.role_uuid = "This field is required.";
      }
      return errors;
    },
    onSubmit: async (values) => {
      setSaveLoading(true);
      dispatch(
        createNewUserUserAsync(values)
      ).then(res => {
        if (res.meta.requestStatus === "fulfilled") {
          onClose()
        }
      }).finally(() => {
        setSaveLoading(false);
      });
    },
  });

  return (
    <Dialog
      open={open}
      title="Create New User"
      size="sm"
      contentWrappedWithForm={{ onSubmit: handleSubmit }}
      onClose={onClose}
      actions={[
        {
          type: "button",
          variant: "outlined",
          label: "Cancel",
          onClick: onClose,
        },
        {
          type: "submit",
          variant: "contained",
          label: "Add",
        },
      ]}
    >
      <form>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <OutlinedTextField
              label="First Name"
              id="first_name"
              value={values.first_name}
              onChange={handleChange}
              error={errors.first_name}
            />
          </Grid>
          <Grid item xs={12}>
            <OutlinedTextField
              label="Last Name"
              id="last_name"
              value={values.last_name}
              onChange={handleChange}
              error={errors.last_name}
            />
          </Grid>
          <Grid item xs={12}>
            <OutlinedTextField
              label="Email"
              id="email"
              value={values.email}
              onChange={handleChange}
              error={errors.email}
            />
          </Grid>
          <Grid item xs={12}>
            <OutlinedTextField
              label="Password"
              id="user_password"
              value={values.user_password}
              onChange={handleChange}
              error={errors.user_password}
            />
          </Grid>
          <Grid item xs={12}>
            <OutlinedTextField
              label="Confirm Password"
              id="confirmPassword"
              value={values.confirmPassword}
              onChange={handleChange}
              error={errors.confirmPassword}
            />
          </Grid>
          <Grid item xs={12}>
            <OutletMultiSelect
              value={values.outlet_details || []}
              onChange={(newValue) => setFieldValue("outlet_details", newValue)}
              error={errors.outlet_details as string}
            />
            {/* <OutletsAutoSearch
              value={values.outlet_details || []}
              onSelect={(newValue) => setValues({
                ...values,
                outlet_uuid: newValue.outlet_uuid as string,
                outlet_name: newValue.outlet_name || ""
              })}
              error={errors.outlet_uuid}
            /> */}
          </Grid>
          <Grid item xs={12}>
            <UserRoleAutoSearch
              value={values.role_uuid}
              onSelect={(newValue) => setValues({
                ...values,
                role_uuid: newValue.value as string
              })}
              error={errors.role_uuid}
            />
          </Grid>
        </Grid>
      </form>
    </Dialog>
  );
};
