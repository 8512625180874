import { ACCESSIBLE_MUDULE_KEYS } from "../../../constants/enum";
import { AuthorizedRoute } from "../../../router/AuthorizedRoute";
import { CreateSecurityGroup } from "./CreateSecurityGroup";
import { ListSecurityGroups } from "./ListSecurityGroups";
import { SECURITY_APPROVALS_ROUTE, SECURITY_GROUPS_ROUTE, SECURITY_ROLE_GROUPS_ROUTE } from "./Security.constatnts";
import { ApprovalList } from "./approval/ApprovalList";
import { RoleGroupTable } from "./roleGroup/RoleGroupTable";



export const securityRoutes = [
    { path: `${SECURITY_GROUPS_ROUTE}`, exact: true, element: <AuthorizedRoute moduleIds={[ACCESSIBLE_MUDULE_KEYS.SECURITY]}><ListSecurityGroups /></AuthorizedRoute> },
    { path: `${SECURITY_GROUPS_ROUTE}/create-security-group`, exact: true, element: <AuthorizedRoute moduleIds={[ACCESSIBLE_MUDULE_KEYS.SECURITY]}><CreateSecurityGroup /></AuthorizedRoute> },
    { path: `${SECURITY_GROUPS_ROUTE}/create-security-group/:roleId`, exact: true, element: <AuthorizedRoute moduleIds={[ACCESSIBLE_MUDULE_KEYS.SECURITY]}><CreateSecurityGroup  /></AuthorizedRoute> },
    { path: `${SECURITY_GROUPS_ROUTE}/security-role-duplicate/:roleId`, exact: true, element: <AuthorizedRoute moduleIds={[ACCESSIBLE_MUDULE_KEYS.SECURITY]}><CreateSecurityGroup isDuplicate  /></AuthorizedRoute> },

    

    { path: `${SECURITY_ROLE_GROUPS_ROUTE}`, exact: true, element: <AuthorizedRoute moduleIds={[ACCESSIBLE_MUDULE_KEYS.ROLE_GROUPS]}><RoleGroupTable /></AuthorizedRoute> },
]