import React from 'react'
import { Box, Grid } from '@mui/material'
import { IStoreState, useAppDispatch, useAppSelector } from '../../../../redux';
import { MenuProductCard } from './MenuProductCard';
import { OutlinedTextField } from '../../../../Components/formComponents';
import { IProduct, fetchProductListAsync } from '../../../../redux/inventory';
import { useOutletContext } from '../../../../providers';

export const MenuProductList = () => {
  const { outlet_details: { outlet_uuid } } = useOutletContext()
  const {
    data: menu_products_list,
    loading,
  } = useAppSelector((storeState: IStoreState) => storeState.inventory.products.product_list);
  const selectedCategory = useAppSelector((storeState: IStoreState) => storeState.inventory.categories.selected_category);
  const dispatch = useAppDispatch()
  const [searchText, setSearchText] = React.useState("")
  const [menuProductList, setMenuProductList] = React.useState<IProduct[]>([])

  React.useEffect(() => {
    if (!outlet_uuid) return;
    dispatch(fetchProductListAsync(outlet_uuid))
  }, [outlet_uuid])

  React.useEffect(() => {
    let newFilteredList: IProduct[] = menu_products_list
    if (selectedCategory) {
      newFilteredList = menu_products_list.filter((product) => product.category_uuid === selectedCategory)
    }
    if (searchText.length > 0) {
      newFilteredList = newFilteredList.filter((product) => product.product_name.toLowerCase().includes(searchText.toLowerCase()))
    }
    setMenuProductList(newFilteredList)
  }, [searchText, selectedCategory, menu_products_list])

  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <OutlinedTextField
          label="Search Products"
          id="account_name"
          value={searchText}
          onChange={(evt) => setSearchText(evt.target.value)}
        />
      </Box>
      <Grid
        container
        spacing={1.5}
        sx={{
          maxHeight: "77vh",
          overflowY: "auto",
          pr: 1,
          mt: 2
        }}
      >
        {menuProductList.map((product) => {
          return <Grid item xs={12} sm={12} md={12} lg={6} width={"100%"}>
            <MenuProductCard
              data={product}
            />
          </Grid>
        })}
      </Grid>
    </>

  )
}
