import { ACCESSIBLE_MUDULE_KEYS } from "../../../constants/enum";
import { AuthorizedRoute } from "../../../router/AuthorizedRoute";
import { AllUsersTable } from "./AllUsersTable";
import { ManageSingleUserProfile } from "./ManageSingleUserProfile";
import { ALL_USERS_LIST_ROUTE, CURRENT_USER_PROFILE_ROUTE } from "./UserProfile.constant";

export const userProfileRoutes = [
    { path: `${ALL_USERS_LIST_ROUTE}`, exact: true, element: <AuthorizedRoute moduleIds={[ACCESSIBLE_MUDULE_KEYS.USERS]}><AllUsersTable /></AuthorizedRoute> },
    { path: `${ALL_USERS_LIST_ROUTE}/manage`, exact: true, element: <AuthorizedRoute moduleIds={[ACCESSIBLE_MUDULE_KEYS.USERS]}><ManageSingleUserProfile /></AuthorizedRoute> },
    { path: `${ALL_USERS_LIST_ROUTE}/manage/:uuid`, exact: true, element: <AuthorizedRoute moduleIds={[ACCESSIBLE_MUDULE_KEYS.USERS]}><ManageSingleUserProfile /></AuthorizedRoute> },
    { path: `${CURRENT_USER_PROFILE_ROUTE}`, exact: true, element: <ManageSingleUserProfile />},
]