import { ACCESSIBLE_MUDULE_KEYS } from "../../constants/enum";
import { AuthorizedRoute } from "../../router/AuthorizedRoute";
import { RESERVATIONS_LIST_ROUTE } from "./Reservations.constants";
import { ReservationsTable } from "./ReservationsTable";


export const reservationsRoutes = [
    {
        path: RESERVATIONS_LIST_ROUTE,
        exact: true,
        element: <AuthorizedRoute moduleIds={[ACCESSIBLE_MUDULE_KEYS.RESERVATION]}><ReservationsTable /></AuthorizedRoute>
    },
] 