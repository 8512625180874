export enum LoadState {
  idle = 0,
  pending = 1,
  succeeded = 2,
  failed = 3,
}


export enum ACCESSIBLE_MUDULE_KEYS {
  PRODUCT = "INVENTORY|PRODUCT|LATEST_PRODUCT",
  CATEGORY =  "INVENTORY|CATEGORY|LATEST_CATEGORY",
  
  ORDER = "ORDER|ORDER|LATEST_ORDERS",
  
  RESERVATION = "RESERVATION|RESERVATION|LATEST_RESERVATION",
  
  LAYOUT = "LAYOUT|LAYOUT|LATEST_LAYOUT",
  
  PAYMENT_RECEIVED = "ACCOUNTING|PAYMENT RECEIVED|LATEST_ACCOUNTING_PAYMENT",
  PAYMENT_PAID = "ACCOUNTING|PAYMENT PAID|LATEST_ACCOUNTING_PAYMENT_PAID",
  BANK_INFORMATION = "ACCOUNTING|BANK INFORMATION|LATEST_ACCOUNTING_BANK_INFO",
  CHARTS_OF_ACCOUNTS = "ACCOUNTING|CHARTS OF ACCOUNTS|LATEST_ACCOUNTING_CHART_OF_ACCOUNTS",

  SHIFT = "SHIFT|SHIFT|LATEST_SHIFT",
  
  COMPANY_INFORMATION = "COMPANY|COMPANY INFORMATION|LATEST_COMPANY",
  OUTLET = "COMPANY|OUTLET|LATEST_OUTLET",
  
  RECEIPT_COMPOSER = "RECEIPT|RECEIPT COMPOSER|LATEST_RECEIPT_COMPOSER",
  
  USERS = "USERS|USERS|LATEST_USER",
  
  SECURITY = "SECURITY|SECURITY|ROLE_MODULE",
  ROLE_GROUPS = "SECURITY|ROLE GROUPS|LATEST_ROLE_GROUPS",

  COUPON = "",
  CAMPAIGN= "",
  UBER_EATS= ""
}
