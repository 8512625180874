import { DragEvent, DragEventHandler, useEffect } from 'react';
import ReactFlow, {
  Background,
  Panel,
  NodeTypes,
  Controls,
  useReactFlow,
  MiniMap,
  useNodesState,
} from 'reactflow';

import 'reactflow/dist/style.css';

import ShapeNode from './components/shape-node';
import Sidebar from './components/sidebar';
import MiniMapNode from './components/minimap-node';
import { ICustomTableNode } from './ReactFlowShapes.types';
import "./ReactFlowShapes.css"
import { ShapeType } from './components/shape/types';
import { Button, Stack } from '@mui/material';
import useUndoRedo from './useUndoRedo';
import { getRandomColorFromStringArray } from '../../helpers/getRandomNumber';

const nodeTypes: NodeTypes = {
  shape: ShapeNode,
};

const proOptions = { account: 'paid-pro', hideAttribution: true };

type ExampleProps = {
  theme?: string;
  snapToGrid?: boolean;
  panOnScroll?: boolean;
  zoomOnDoubleClick?: boolean;
  initialNodes: ICustomTableNode[]
  onSaveClick: (data: ICustomTableNode[]) => void
  onCancel: () => void
};

export const ReactFlowShapes: React.FC<ExampleProps> = ({
  theme = 'dark',
  snapToGrid = true,
  panOnScroll = true,
  zoomOnDoubleClick = false,
  initialNodes,
  onSaveClick,
  onCancel
}) => {
  const { undo, redo, canUndo, canRedo, takeSnapshot } = useUndoRedo();
  const { screenToFlowPosition, addNodes } = useReactFlow();
  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);

  useEffect(() => {
    setNodes(initialNodes)
  }, [initialNodes])

  const onDragOver = (evt: DragEvent<HTMLDivElement>) => {
    evt.preventDefault();
    evt.dataTransfer.dropEffect = 'move';
  };

  // this function is called when a node from the sidebar is dropped onto the react flow pane
  const onDrop: DragEventHandler = (evt: DragEvent<HTMLDivElement>) => {
    evt.preventDefault();
    const type = evt.dataTransfer.getData('application/reactflow');

    // this will convert the pixel position of the node to the react flow coordinate system
    // so that a node is added at the correct position even when viewport is translated and/or zoomed in
    const position = screenToFlowPosition({ x: evt.clientX, y: evt.clientY });

    const newNode = {
      id: Date.now().toString(),
      type: 'shape',
      position,
      style: { width: type === "line" ? 20 : type === "rectangle" ? 200 : 100, height:  type === "rectangle" ? 150 : 100 },
      data: {
        type: type as ShapeType,
        color: nodes.length > 0 ? getRandomColorFromStringArray(nodes[nodes.length - 1].data.color) : "#3F8AE2",
        title: type,
        mode: "editable",
        availability: "FREE",
        nodeType: type === "line" ? "CUSTOM" : "TABLE",
        table_number:  type === "line" ? "-1" : nodes.filter(table => table.data.nodeType === "TABLE" &&  table.data.status === "ACTIVE").length + 1,
        status: "ACTIVE"
      } as ICustomTableNode["data"],
      selected: true,
    };

    setNodes((nodes) =>
      nodes.map((n) => ({ ...n, selected: false })).concat([newNode as any])
    );
  };


  const handleSaveButtonClick = () => {
    onSaveClick(nodes as ICustomTableNode[])
  }



  // const onNodeDragStart: NodeDragHandler = useCallback(() => {
  //   // 👇 make dragging a node undoable
  //   takeSnapshot();
  //   // 👉 you can place your event handlers here
  // }, [takeSnapshot]);

  // const onSelectionDragStart: SelectionDragHandler = useCallback(() => {
  //   // 👇 make dragging a selection undoable
  //   takeSnapshot();
  // }, [takeSnapshot]);

  // const onNodesDelete: OnNodesDelete = useCallback(() => {
  //   // 👇 make deleting nodes undoable
  //   takeSnapshot();
  // }, [takeSnapshot]);





  return (
    <>
      <Stack direction={"row"} justifyContent="center" spacing={2} mb={1}>
        <Button variant="contained" onClick={handleSaveButtonClick}>Save</Button>
        <Button variant="contained" onClick={onCancel}>Cancel</Button>
      </Stack>

      <ReactFlow
        className={theme}
        proOptions={proOptions}
        nodeTypes={nodeTypes}
        nodes={nodes.filter(node => node.data.status === "ACTIVE")}
        onNodesChange={onNodesChange}
        // onNodeDragStart={onNodeDragStart}
        // onSelectionDragStart={onSelectionDragStart}
        // onNodesDelete={onNodesDelete}
        fitView
        onDrop={onDrop}
        snapToGrid={snapToGrid}
        snapGrid={[10, 10]}
        onDragOver={onDragOver}
        zoomOnDoubleClick={zoomOnDoubleClick}
      >
        <Background />
        <Panel position='top-left'>
          <Sidebar />
        </Panel>
        {/* <Panel position="top-right">
          <Stack direction={"row"} justifyContent="flex-end" alignItems="center" spacing={2} mb={1}>
            <Tooltip title="Undo">
              <IconButton disabled={canUndo}>
                <UndoIcon sx={{ cursor: "pointer" }} onClick={undo} />
              </IconButton>
            </Tooltip>
            <Tooltip title="Redo">
              <IconButton disabled={canRedo}>
                <RedoIcon sx={{ cursor: "pointer" }} onClick={undo} />
              </IconButton>
            </Tooltip>
          </Stack>
        </Panel> */}
        <Controls />
        <MiniMap zoomable draggable nodeComponent={MiniMapNode} />
      </ReactFlow>
    </>

  );
}