import React from 'react'
import { IStoreState, useAppDispatch, useAppSelector } from '../../../redux';
import { fetchDashboardExpenseCatgoriesAsync } from '../../../redux/analytics/analytics.actions';
import { BankingExpensesCategories } from './banking-expenses-categories';
import { Iconify } from '../components/iconify';

export const ExpenseCategories = () => {


    const {
        data: expense_categories_list,
        loading,
        totalRecords,
        overview
    } = useAppSelector((storeState: IStoreState) => storeState.analytics.expense_categories_list);
    const dispatch = useAppDispatch();

    React.useEffect(() => {
        dispatch(
            fetchDashboardExpenseCatgoriesAsync(),
        );

        // return () => {
        //   dispatch(clearOrdersStateSync());
        // };
    }, []);

    return (
        <BankingExpensesCategories
            title="Expenses categories"
            overview={overview}
            chart={{
                series: expense_categories_list.map((item) => ({ label: item.category, value: item.amount })),
                icons: [
                    <Iconify icon="streamline:dices-entertainment-gaming-dices-solid" />,
                    <Iconify icon="maki:fuel" />,
                ],
            }}
        />

    )
}
