import React from 'react'
import { ICompanyOutlet } from '../../../redux/settings/outlets'
import { IOutletContextState } from '../OutletContext.types'
import { Autocomplete, Box, styled, TextField, Typography } from '@mui/material'
import { getOutletContextStatefromOutlet, getOutletContextStatefromOutletV2 } from '../OutletContext.helpers'
import { IUserProfile, IUserProfileOutlets } from '../../../redux/settings/user-profile'


const CustomBox = styled(Box)(({ theme }) => ({
    padding: theme.spacing(2),
    backgroundColor: "#121212",
    height: '100vh',
    display: 'flex',
    flexDirection: "column",
    justifyContent: "center",
    alignItems: 'center'
}));

interface IMultipleOutletsScreenProps {
    outlets: IUserProfile["outlet_details"]
    onSelect: (outletInfo: IOutletContextState) => void
}
export const MultipleOutletsScreen: React.FC<IMultipleOutletsScreenProps> = ({
    outlets,
    onSelect
}) => {
    return (
        <CustomBox>
            <Typography variant='h4' sx={{ color: "#fff", fontWeight: 600 }}>It Seems you have multiple outlets.</Typography>
            <Typography variant='h6' sx={{ color: "#fff", mb: 2 }}>Please Select One to continue</Typography>
            <Autocomplete
                disablePortal
                options={outlets}
                onChange={async (event: any, newValue: IUserProfileOutlets | null) => {
                    if (newValue) {
                        onSelect(await getOutletContextStatefromOutletV2(newValue, true));
                    }
                }}
                getOptionLabel={(option) => typeof option === "string" ? option : option.outlet_name || ""}
                sx={{
                    width: 300,
                    // // ------------ options---------------
                    // '& .MuiAutocomplete-listbox': {
                    //     background: 'red',
                    // },
                    // // '& .MuiAutocomplete-option': {
                    // //     color: 'black',
                    // //     background: 'red',
                    // //     '&[aria-selected="true"]': {
                    // //         backgroundColor: 'lightgray', // Highlighted option color
                    // //     },
                    // //     '&:hover': {
                    // //         backgroundColor: 'lightgreen', // Hover effect
                    // //     },
                    // // },
                }}
                renderInput={(params) => <TextField
                    {...params}
                    label="Select Outlet"
                    sx={{
                        '& .MuiAutocomplete-popupIndicator': {
                            color: '#fff', // Change dropdown icon color
                        },

                        "& .MuiInputBase-input": { color: "#fff", }, // Change text color
                        "& .MuiInputLabel-root": { color: "#fff", }, // Change label color
                        '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
                            borderColor: '#fff', // Border color on hover
                        },
                        "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                                borderColor: "#fff", // Change border color
                            },
                            "&:hover fieldset": {
                                borderColor: "#fff", // Change border color on hover
                            },
                            "&.Mui-focused fieldset": {
                                borderColor: "#fff", // Change border color when focused
                            },
                        },
                    }}
                />}
            />
        </CustomBox>
    )
}
