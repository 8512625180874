import React from "react";
// import { DataTable, ITableColumn } from "../../../Components/Tables/Table";
import { Avatar } from "@mui/material";
import {
  IDataTableProps,
  MobileLogoRenderType,
  RenderType,
} from "../../../Components/Table/interfaces/IDataTableProps";
import { DataTable } from "../../../Components/Table/DataTable";
import { useDateFilter } from "../../../Components/Table/hooks/useDateFilter";
import { IStoreState, useAppDispatch, useAppSelector } from "../../../redux";
import { useNavigate } from "react-router-dom";
import { StandardTableActions } from "../../../Components/StandardTableActions/StandardTableActions";
import { formatDateWithTime } from "../../../helpers";
import { CustomButton } from "../../../Components/CustomButton";
import {
  IChartOfAccount,
  clearChartsOfAccountState,
  defaultChartOfAccount,
} from "../../../redux/accounting";
import { UpsertChartOfAccountDialog } from "./dialogs/UpsertChartOfAccountDialog";
import { fetchChartOfAccountsListWithArgsAsync } from "../../../redux/accounting/charts-of-account/charts-of-account.actions";
import { LoadState } from "../../../constants/enum";

export const ChartOfAccountTable = () => {
  const {
    data: charts_of_account_list,
    totalRecords,
    loading,
  } = useAppSelector(
    (storeState: IStoreState) =>
      storeState.accounting.chartOfAccount.charts_of_account_list
  );

  const [pagination, setPagination] = React.useState({
    pageNumber: 1,
    rowsPerPage: 10,
  });

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [openUpsertDialog, setOpenUpsertDialog] =
    React.useState<IChartOfAccount | null>(null);

  const dataTableProps: IDataTableProps = {
    isPagination: true,
    totalRecords: totalRecords,
    rowsPerPageOptions: pagination.rowsPerPage,
    isDataLoading: loading !== LoadState.succeeded,
    mobileLogo: {
      type: MobileLogoRenderType.reactNode,
      onMobileLogoRender: (data: any) => {
        return (
          <Avatar
            sx={{
              width: 45,
              height: 45,
              color: "#fff",
              ml: "-8px",
              mr: 2,
            }}
          ></Avatar>
        );
      },
    },
    columns: [
      {
        key: "view",
        headerName: "Actions",
        fieldName: "",
        headingAlign: "center",
        renderType: RenderType.CUSTOM_RENDER,
        onRowCellRender: (value, row: IChartOfAccount) => {
          return (
            <StandardTableActions
              onEditClick={() => setOpenUpsertDialog(row)}
            />
          );
        },
      },
      {
        key: "detail_type",
        headerName: "Detail Type",
        fieldName: "detail_type",
        enableSorting: true,
        renderType: RenderType.CUSTOM_RENDER,
      },
      {
        key: "account_name",
        headerName: "Account Name",
        fieldName: "account_name",
        enableSorting: true,
        renderType: RenderType.CUSTOM_RENDER,
      },
      {
        key: "account_number",
        headerName: "Account Number",
        fieldName: "account_number",
        enableSorting: true,
        renderType: RenderType.CUSTOM_RENDER,
      },
      {
        key: "account_type",
        headerName: "Account Type",
        fieldName: "account_type",
        enableSorting: true,
        renderType: RenderType.CUSTOM_RENDER,
      },
      {
        key: "current_balance",
        headerName: "Current Balance",
        fieldName: "current_balance",
        enableSorting: true,
        renderType: RenderType.CUSTOM_RENDER,
      },
      {
        key: "month_opening_balance",
        headerName: "Month Opening Balance",
        fieldName: "month_opening_balance",
        enableSorting: true,
        renderType: RenderType.CUSTOM_RENDER,
      },
      {
        key: "insert_ts",
        headerName: "Date",
        fieldName: "insert_ts",
        enableSorting: true,
        renderType: RenderType.DATE_DARK_COLOR,
        exportCellWidth: 25,
        onExportRender: (values) => {
          return formatDateWithTime(values.value);
        },
      },
    ],

    items: charts_of_account_list,
    onPageChange: (newPageNumber: number) => {
      setPagination({ ...pagination, pageNumber: newPageNumber });
    },
    onRowsPerPageChange: (pageNumber: number, rowsPerPage: number) => {
      setPagination({
        pageNumber: pageNumber,
        rowsPerPage: rowsPerPage,
      });
    },
  };

  React.useEffect(() => {
    fetchList();
  }, [pagination]);

  React.useEffect(() => {
    return () => {
      dispatch(clearChartsOfAccountState());
    };
  }, []);

  const fetchList = () => {
    dispatch(
      fetchChartOfAccountsListWithArgsAsync({
        page: pagination.pageNumber,
        rowsPerPage: pagination.rowsPerPage,
      })
    );
  };

  return (
    <>
      <CustomButton
        variant="contained"
        color="primary"
        sx={{
          display: "flex",
          marginLeft: "auto",
          marginY: 2,
          minWidth: "80px !important"
        }}
        onClick={() => setOpenUpsertDialog(defaultChartOfAccount)}
      >
        Add New
      </CustomButton>
      <DataTable {...dataTableProps} />
      {openUpsertDialog && (
        <UpsertChartOfAccountDialog
          open={true}
          initialState={openUpsertDialog}
          onClose={() => setOpenUpsertDialog(null)}
        />
      )}
    </>
  );
};
