import React from "react";
import LinearProgress from "@mui/material/LinearProgress";
import { axios_api } from "../../api/axios-api";
import { ICompanyOutlet, setCurrentUserOutletInfo } from "../../redux/settings/outlets";
import { useAppDispatch } from "../../redux";
import { IUserProfile } from "../../redux/settings/user-profile";
import { useSearchParams } from "react-router-dom";
import { defaultPublicOutletContextState, IPublicOutletContextState } from "./PublicOutletContext.types";
import { getPublicOutletContextStatefromOutlet } from "./PublicOutletContext.helpers";
import { PublicOutletContext } from "./PublicOutletContext.context";



export const PublicOutletContextProvider: React.FC<{ children: React.ReactNode }> = (
  props,
) => {
  const [searchParams] = useSearchParams();
  const outletUUID = searchParams.get("outlet")
  const [outletInfo, setOutletInfo] = React.useState<IPublicOutletContextState>(defaultPublicOutletContextState);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    if (outletUUID) {
      setLoading(true)
      axios_api.get(`/general/get-activation-key`).then((response) => {
        const activationData = response.data.data
        console.log("outletInfo IN===> ", activationData)
        if (activationData[0]) {
          setKeyInformation(activationData[0]["activation_key"])
          // setOutletInfo(getPublicOutletContextStatefromOutlet(activationData[0]["activation_key"]))
        }
      }).finally(() => {
        setLoading(false);
      })

    }
  }, [outletUUID]);


  const setKeyInformation = (activationKey: string) => {
    axios_api.defaults.headers.common["key"] = activationKey;
  }


  if (loading || !axios_api.defaults.headers.common["key"]) {
    return <LinearProgress />;
  }

  return (
    <PublicOutletContext.Provider
      value={outletInfo}
    >
      {props.children}
    </PublicOutletContext.Provider>
  );
};
