import { Autocomplete, CircularProgress } from "@mui/material";
import React, { useEffect } from "react";
import { debounce } from "lodash";
import { axios_api } from "../../../../api/axios-api";
import { OutlinedTextField } from "../../../../Components/formComponents";
import { defaultOutlet, GET_OUTLET_BASE_URL, ICompanyOutlet } from "../../../../redux/settings/outlets";

export interface IOutletsAutoSearchProps {
  value: any;
  onSelect: (value: ICompanyOutlet) => void;
  error?: string;
}


export const OutletsAutoSearch: React.FC<IOutletsAutoSearchProps> = (
  props,
) => {
  const { value, onSelect } = props;
  const [loading, setLoading] = React.useState(false);
  const [searchResults, setSearchResults] = React.useState<ICompanyOutlet[]>([]);
  const [searchText, setSearchText] = React.useState("");

  useEffect(() => {
    fetchSuggestion("");
  }, []);

  const fetchSuggestion = debounce(async (value) => {
    setLoading(true);
    try {
      const res = await axios_api.get(`${GET_OUTLET_BASE_URL}`);
      const finalData: ICompanyOutlet[] = res.data.data;
      setSearchResults(finalData);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  }, 500);

  React.useEffect(() => {
    if (searchText && searchText !== value.first_name) {
      fetchSuggestion(searchText);
    }
  }, [searchText]);

  React.useEffect(() => {
    if (value && typeof value === "object" && value?.user_uuid?.length > 0) {
      const option: ICompanyOutlet = {
        ...defaultOutlet,
        outlet_uuid: value.outlet_uuid,
        outlet_name: value.outlet_name,
      };
      setSearchResults([option]);
    }
  }, []);

  const getOptionLabel = (option: string | ICompanyOutlet) => {
    if (typeof option === "string") {
      return option;
    }
    return option.outlet_name || "";
  };

  React.useEffect(() => {
    if (value && typeof value === "object" && value?.user_uuid?.length > 0) {
      setSearchText(value.outlet_name);
      fetchSuggestion(value.outlet_name);
    } else {
      setSearchText("");
    }
  }, [value]);

  return (
    <>
      <Autocomplete
        options={searchResults}
        fullWidth
        disabled={loading}
        sx={{
          maxWidth: 300,
          ".MuiOutlinedInput-root": {
            paddingTop: "2px",
            paddingBottom: "2px",
            fontSize: "0.8rem",
            color: "rgb(38, 38, 38)",
          },
        }}
        value={searchResults.find((option) => option.outlet_uuid === value.outlet_uuid) || null}
        defaultValue={value}
        getOptionLabel={getOptionLabel}
        onChange={(e, newValue) => {
          if (newValue && typeof newValue !== "string") {
            onSelect(newValue);
          }
        }}
        onInputChange={(event, newInputValue) => {
          if ((event && event.type === "change") || !newInputValue) {
            setSearchText(newInputValue);
          }
        }}
        renderInput={(params) => (
          <OutlinedTextField
            {...params}
            label="Outlet"
            fullWidth
            value={searchText}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading && <CircularProgress color="inherit" size={20} />}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
    </>
  );
};
