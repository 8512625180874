import React from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import {
  Box,
  useMediaQuery,
  List,
  ListItem,
  Collapse,
  Typography,
  Drawer,
} from "@mui/material";
//@ts-ignore
import FeatherIcon from "feather-icons-react";
import { ISidebarProps } from "./types";
import { Scrollbar } from "../../../Components/customScroll/ScrollBar";
import { LogoIcon } from "../logo/Logo";
import { CustomListItemIcon, CustomTypography } from "../../../Components/formComponents";
import { SidebarMobileWidth, SidebarWidth } from "../../../assests/global/Theme-variable";
import { useSecureMenuList } from "../../../security";
import "./Sidebar.css"

const LEFT_DRAWER_BACKGROUND_COLOR = "#1e1e1e"
const MENU_TEXT_COLOR_BLACK = "#fff"
const ACTIVE_MENU_TEXT_COLOR = "#fff"
const ACTIVE_CHILD_MENU_TEXT_COLOR = "#8b8b8b"
const ACTIVE_MENU_BACKGROUND_COLOR = "#cfdedb29"


export const Sidebar: React.FC<ISidebarProps> = ({
  isMobileSidebarOpen,
  onSidebarClose,
  isSidebarOpen,
}) => {
  const [open, setOpen] = React.useState<number[]>([]);
  const finalMenuList = useSecureMenuList();
  const { pathname } = useLocation();
  const pathDirect = pathname;
  const pathWithoutLastPart = pathname.slice(0, pathname.lastIndexOf("/"));
  const lgUp = useMediaQuery((theme: any) => theme.breakpoints.up("lg"));

  const handleClick = (index: number) => {
    const openedItems = [...open];
    const itemIndex = openedItems.indexOf(index);
    if (itemIndex > -1) {
      openedItems.splice(itemIndex, 1);
      setOpen(openedItems);
    } else {
      setOpen([...openedItems, index]);
    }
  };

  const SidebarContent = (
    <Scrollbar style={{ height: "calc(100vh - 5px)", paddingInline: 10 }}>
      <Box>
        {/* <LogoIcon /> */}
        <Box>
          <List>
            {finalMenuList.map((item, index) => {
              if (true) {
                //item.allowedRoles.includes(user.role)
                // {/********SubHeader**********/}
                if (item.subHeader) {
                  return (
                    <ListItem key={item.subHeader}>
                      <CustomTypography variant="h6" sx={{ opacity: "0.4" }}>
                        {item.subHeader}
                      </CustomTypography>
                    </ListItem>
                  );
                  // {/********If Sub Menu**********/}
                  /* eslint no-else-return: "off" */
                } else if (item.children) {
                  const isChildSelected =
                    item.children.findIndex((x) => pathDirect.includes(x.href)) > -1;
                  return (
                    <React.Fragment key={item.title}>
                      <ListItem
                        button
                        component="li"
                        onClick={() => handleClick(index)}
                        selected={isChildSelected}
                        sx={{
                          margin: "0px 0px 8px",
                          padding: "8px 16px",
                          ...(isChildSelected && {
                            color: "black",
                            background: `${ACTIVE_MENU_BACKGROUND_COLOR} !important`,
                            borderRadius: "9px",
                          }),
                        }}
                      >
                        <CustomListItemIcon
                          sx={{
                            ...(isChildSelected && {
                              color: "black",
                            }),
                          }}
                        >
                          <FeatherIcon
                            icon={item.icon}
                            size={17}
                            fill="#ccc"
                          />
                        </CustomListItemIcon>
                        <Box
                          display="flex"
                          justifyContent={"space-between"}
                          alignItems={"center"}
                          width={"100%"}
                        >
                          <Typography sx={{
                            color: MENU_TEXT_COLOR_BLACK,
                            fontWeight: pathWithoutLastPart === item.href ? 700 : 600
                          }}>{item.title}</Typography>
                          {open.includes(index) ||
                            pathWithoutLastPart === item.href ? (
                            <FeatherIcon icon="chevron-down" className="chevron-icon"  size="16" />
                          ) : (
                            <FeatherIcon icon="chevron-right" className="chevron-icon" size="16" />
                          )}
                        </Box>
                      </ListItem>
                      <Collapse
                        in={open.includes(index)}
                        timeout="auto"
                        unmountOnExit
                      >
                        <List component="li" disablePadding>
                          {item.children.map((child) => {
                            return (
                              <ListItem
                                key={child.title}
                                button
                                component={NavLink}
                                to={child.href}
                                onClick={onSidebarClose}
                                selected={pathDirect === child.href}
                                sx={{
                                  mb: 1,
                                  padding: "4px 16px",
                                  borderRadius: "9px",
                                  ...(pathDirect === child.href && {
                                    background: "none !important",
                                  }),
                                }}
                              >
                                <CustomListItemIcon
                                  sx={{
                                    svg: {
                                      width: "14px",
                                      marginLeft: "18px",
                                      mr: 1,
                                    },
                                    ...(pathDirect === child.href && {
                                      color: ACTIVE_MENU_TEXT_COLOR,
                                    }),
                                  }}
                                >
                                  <FeatherIcon
                                    icon={child.icon}
                                    size={17}
                                  />
                                </CustomListItemIcon>

                                <Typography
                                  sx={{
                                    color: pathDirect.includes(child.href) ? ACTIVE_CHILD_MENU_TEXT_COLOR : MENU_TEXT_COLOR_BLACK,
                                    fontWeight: pathDirect.includes(child.href) ? 800 : 600
                                  }}
                                >
                                  {child.title}
                                </Typography>
                              </ListItem>
                            );
                          })}
                        </List>
                      </Collapse>
                    </React.Fragment>
                  );
                  // {/********If Sub No Menu**********/}
                } else {
                  return (
                    <List component="li" disablePadding key={item.title}>
                      <ListItem
                        // onClick={() => handleClick(index)}
                        button
                        component={NavLink}
                        to={item.href}
                        selected={pathDirect === item.href}
                        sx={{
                          margin: "0px 0px 8px",
                          padding: "8px 16px",
                          ...(pathDirect === item.href && {
                            color: "#fff",
                            // backgroundColor: (theme) =>
                            //   `${theme.palette.primary.main}!important`,
                            background: `${ACTIVE_MENU_BACKGROUND_COLOR} !important`,
                            borderRadius: "9px",
                          }),
                        }}
                      >
                        <CustomListItemIcon
                          sx={{
                            ...(pathDirect === item.href && {
                              color: "#fff",
                            }),
                          }}
                        >
                          <FeatherIcon
                            icon={item.icon}
                            size={17}
                            fill="#fff"
                            strokeWidth={4}
                          />
                        </CustomListItemIcon>
                        <Typography
                          sx={{
                            color: MENU_TEXT_COLOR_BLACK,
                            fontWeight: pathDirect === item.href ? 700 : 600
                          }}
                          onClick={onSidebarClose}
                        >
                          {item.title}
                        </Typography>
                      </ListItem>
                    </List>
                  );
                }
              }
            })}
          </List>
        </Box>
      </Box>
    </Scrollbar>
  );
  if (lgUp) {
    return (
      <Drawer
        anchor="left"
        open={isSidebarOpen}
        variant={"persistent"}
        PaperProps={{
          sx: {
            borderRight: 0,
            width: SidebarWidth,
            borderRadius: "9px",
            top: "100px",
            padding: 0,
            left: "10px",
            // boxShadow: "rgba(90, 114, 123, 0.11) 0px 7px 30px 0px",
            height: "calc(100vh - 130px)",
            background: LEFT_DRAWER_BACKGROUND_COLOR
          },
        }}
      >
        {SidebarContent}
      </Drawer>
    );
  }
  return (
    <Drawer
      anchor="left"
      open={isMobileSidebarOpen}
      onClose={onSidebarClose}
      PaperProps={{
        sx: {
          width: SidebarMobileWidth,
          border: "0 !important",
          borderRight: 0,
          padding: "10px 10px",
          // boxShadow: "rgba(90, 114, 123, 0.11) 0px 7px 30px 0px",
          background: LEFT_DRAWER_BACKGROUND_COLOR
        },
      }}
      variant="temporary"
    >
      <LogoIcon />
      {SidebarContent}
    </Drawer>
  );
};
