import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Typography } from '@mui/material'
import React from 'react'

interface IMuiWarningDialogProps {
    open: boolean
    message: string
    onClose: () => void
}

export const MuiWarningDialog: React.FC<IMuiWarningDialogProps> = ({ open, message, onClose }) => {
    return (
        <Dialog open={open} maxWidth="xs"
            sx={{
                ".MuiDialog-paper": {
                    width: "100%"
                }
            }}
        >
            <DialogTitle><Typography variant='h2'>Warning Message</Typography></DialogTitle>
            <DialogContent>
                <Typography variant='h3'> {message}</Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Close</Button>
            </DialogActions>
        </Dialog>
    )
}
