import React, { useState } from 'react'
import { Dialog } from '../../../../Components/Dialogs/Dialog';
import { IProduct, IProductModifier, clearProductModifierListSync, fetchCustomizedModifiersListAsync } from '../../../../redux/inventory';
import { Box, Chip, InputAdornment, Typography } from '@mui/material';
import { IStoreState, useAppDispatch, useAppSelector } from '../../../../redux';
import { addProductModifierIntoOrderSync, changeOrderItemNoteFieldSync, changeProductModifierQuantitySync, removeProductModifierFromOrderSync } from '../../../../redux/orders';
import { OutlinedTextField } from '../../../../Components/formComponents';
import { FaPlus } from 'react-icons/fa';
import { HiMinusSm } from 'react-icons/hi';
import { isNotNull } from '../../../../helpers/isNotNull';
import { useOutletContext } from '../../../../providers';

export interface ICustomizeProductDialogProps {
    open: boolean;
    product: IProduct;
    onClose: () => void;
}

export const CustomizeProductDialog: React.FC<ICustomizeProductDialogProps> = (props) => {
    const { open, product, onClose } = props;
    const { OUTLET_CURRENCY_SYMBOL } = useOutletContext()
    const dispatch = useAppDispatch();

    const currentOrderInfo = useAppSelector((state) => state.orders.single_order.data)
    const currentOrderProductInfo = currentOrderInfo.menu_items.find((item) => item.product_uuid === product.product_uuid);

    const [selectedCustomization, setSelectedCustomization] = useState<string | null>(null)

    const handleProductNotesChange = (value: string) => {
        dispatch(changeOrderItemNoteFieldSync({
            product,
            note: value
        }))
    }

    return (
        <Dialog
            open={open}
            title="Customize Product"
            size="sm"
            onClose={onClose}
            actions={[
                {
                    type: "button",
                    variant: "outlined",
                    label: "Close",
                    onClick: onClose,
                },
            ]}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: "flex-start",
                    alignItems: 'flex-start',
                    gap: 2,
                    // border: "1px solid #fff",
                    // borderRadius: 1,
                    padding: 1,
                    marginBottom: 2
                }}
            >
                {(product.product_customizations || []).length > 0 ? product.product_customizations.map((cmz) => {
                    return (
                        <Chip
                            sx={{ py: 1, px: 1 }}
                            key={cmz.customization_uuid}
                            label={cmz.customization_name}
                            variant={cmz.customization_uuid === selectedCustomization ? "filled" : "outlined"}
                            onClick={() => setSelectedCustomization(cmz.customization_uuid === selectedCustomization ? null : cmz.customization_uuid)}
                        />
                    )
                })
                    :
                    <Typography sx={{ py: 1, px: 2 }} variant='body1'>No Customization Available</Typography>
                }
            </Box>

            {selectedCustomization &&
                <CustomizationModifiers
                    product={product}
                    customizationUUID={selectedCustomization}

                />}
            <Box sx={{ paddingInline: 2, mt: 1 }}>
                <OutlinedTextField
                    label="Notes"
                    multiline
                    minRows={4}
                    value={currentOrderProductInfo?.notes}
                    onChange={(evt) => handleProductNotesChange(evt.target.value)}
                />
            </Box>
        </Dialog>
    );
};


interface ICustomizationModifiers {
    customizationUUID: string;
    product: IProduct;
}
const CustomizationModifiers: React.FC<ICustomizationModifiers> = ({
    customizationUUID,
    product
}) => {
    const {
        data: product_modifiers_list,
        loading,
    } = useAppSelector((storeState: IStoreState) => storeState.inventory.customization.product_modifiers_list);

    const dispatch = useAppDispatch();
    const { default_tax_rate, OUTLET_CURRENCY_SYMBOL } = useOutletContext();

    const currentOrderInfo = useAppSelector((state) => state.orders.single_order.data)
    const customizedOptions = currentOrderInfo.menu_items.find((item) => item.product_uuid === product.product_uuid)?.options;


    React.useEffect(() => {
        dispatch(
            fetchCustomizedModifiersListAsync(customizationUUID),
        );
    }, [customizationUUID]);

    React.useEffect(() => {
        return () => {
            dispatch(clearProductModifierListSync());
        };
    }, []);


    const handleAddModifierClick = (option: IProductModifier) => {
        dispatch(addProductModifierIntoOrderSync({
            product,
            modifier: option,
            outletDefaultTax: isNotNull(default_tax_rate)
        }))
    }

    const handleDeleteModifierClick = (option: IProductModifier) => {
        dispatch(removeProductModifierFromOrderSync({
            product,
            modifier: option
        }))
    }

    const handleChangeProductModifierQuantity = (option: IProductModifier, qt: number) => {
        dispatch(changeProductModifierQuantitySync({
            product,
            modifier: option,
            quantity: qt
        }))
    }

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: "flex-start",
                alignItems: 'flex-start',
                gap: 2,
                borderTop: product_modifiers_list.length > 0 ? "2px solid #fff" : "none",
            }}
        >
            {product_modifiers_list.map((opt, opt_index) => {
                const currentOrderOption = customizedOptions?.find(item => item.modifier_uuid === opt.modifier_uuid)
                const optionQuantity = currentOrderOption?.quantity || 0

                return (
                    <Box
                        sx={{
                            width: "100%",
                            display: "flex",
                            flexDirection: 'row',
                            justifyContent: "space-between",
                            alignItems: 'center',
                            gap: 2,
                            // border: "1px solid #fff",
                            // borderRadius: 1,
                            paddingBlock: 1,
                            paddingInline: 2,
                        }}
                    >
                        <Typography
                            variant='h5'
                        >{opt.modifier_name}</Typography>

                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: 'row',
                                justifyContent: "flex-end",
                                alignItems: 'center',
                                gap: 2,
                            }}
                        >
                            <Typography
                                variant='h5'
                            >{`${OUTLET_CURRENCY_SYMBOL} ${opt.modifier_price}`}</Typography>

                            {/* {customizedOptions?.findIndex(item => item.modifier_uuid === opt.modifier_uuid) === opt_index ?
                                <Button
                                    variant="outlined"
                                    color="error"
                                    endIcon={<Delete />}
                                    onClick={() => handleDeleteModifierClick(opt)}
                                >
                                    Delete
                                </Button>
                                :
                                <Button
                                    variant="outlined"
                                    endIcon={<AddIcon />}
                                    onClick={() => handleAddModifierClick(opt)}
                                >
                                    Add
                                </Button>
                            } */}

                            <Box
                                display={"flex"}
                                justifyContent={"space-between"}
                                alignItems={"center"}
                                gap={1}
                                flexWrap="wrap"
                            >

                                <HiMinusSm
                                    style={{
                                        width: 40,
                                        color: "#D0D0D0",
                                        border: "1px solid",
                                        borderColor: "#D0D0D0",
                                        borderRadius: "0.3rem",
                                        padding: "0.1rem",
                                        fontSize: "1.5rem",
                                    }}
                                    onMouseEnter={(e) => {
                                        e.currentTarget.style.backgroundColor = "#D0D0D0";
                                        e.currentTarget.style.color = "#2C2D2C";
                                        e.currentTarget.style.cursor = "pointer";
                                    }}
                                    onMouseLeave={(e) => {
                                        e.currentTarget.style.backgroundColor = "#2C2D2C";
                                        e.currentTarget.style.color = "#D0D0D0";
                                        e.currentTarget.style.cursor = "pointer";
                                    }}
                                    onClick={() => handleDeleteModifierClick(opt)}
                                />
                                <OutlinedTextField
                                    sx={{
                                        maxWidth: 60,
                                        "& input[type=number]": {
                                            "-moz-appearance": "textfield",
                                        },
                                        "& input[type=number]::-webkit-outer-spin-button": {
                                            "-webkit-appearance": "none",
                                            margin: 0,
                                        },
                                        "& input[type=number]::-webkit-inner-spin-button": {
                                            "-webkit-appearance": "none",
                                            margin: 0,
                                        },
                                        "& .MuiOutlinedInput-input": {
                                            padding: "1px 6px",
                                            textAlign: "center"
                                        },
                                    }}
                                    type="number"
                                    value={optionQuantity || 0}
                                    autoFocus
                                    onChange={(evt) => {

                                        if (Number(evt.target.value) >= 0) {
                                            handleChangeProductModifierQuantity(opt, Number(evt.target.value))
                                        }
                                    }}
                                    InputProps={{
                                        endAdornment: opt.modifier_price_by === "WEIGHT" && (
                                            <InputAdornment position="end">
                                                <Typography variant="h5">{opt.modifier_weight_unit}</Typography>
                                            </InputAdornment>
                                        ),
                                    }}
                                // onBlur={handleQuantityChange}
                                />

                                <FaPlus
                                    style={{
                                        width: 40,
                                        color: "#D0D0D0",
                                        border: "1px solid",
                                        borderColor: "#D0D0D0",
                                        borderRadius: "0.3rem",
                                        padding: "0.3rem",
                                        fontSize: "1.5rem",
                                    }}
                                    onMouseEnter={(e) => {
                                        e.currentTarget.style.backgroundColor = "#D0D0D0";
                                        e.currentTarget.style.color = "#2C2D2C";
                                        e.currentTarget.style.cursor = "pointer";
                                    }}
                                    onMouseLeave={(e) => {
                                        e.currentTarget.style.backgroundColor = "#2C2D2C";
                                        e.currentTarget.style.color = "#D0D0D0";
                                        e.currentTarget.style.cursor = "pointer";
                                    }}
                                    onClick={() => handleAddModifierClick(opt)}
                                />
                            </Box>
                        </Box>
                        {/* <Chip
                            key={opt.modifier_uuid}
                            label={`${opt.modifier_name}  ${OUTLET_CURRENCY_SYMBOL} ${opt.modifier_price}`}
                            variant="outlined"
                            // onClick={() => setSelectedCustomization(cmz.customization_uuid)}
                            // onDelete={handleDelete}
                            deleteIcon={ }
                        /> */}
                    </Box>
                )
            })}
        </Box>
    )
}