import { currency_symbol, DEFAULT_CURRENCY } from "../../constants/constants";
import { defaultOutlet, ICompanyOutlet } from "../../redux/settings/outlets";



export interface IOutletContextState {
    isMultipleOutlets: boolean
    outlet_details: ICompanyOutlet
    default_tax_rate: string
    OUTLET_CURRENCY_SYMBOL: string
}


export const defaultOutletContextState: IOutletContextState = {
    isMultipleOutlets: false,
    outlet_details: defaultOutlet,
    default_tax_rate: "13",
    OUTLET_CURRENCY_SYMBOL: currency_symbol[DEFAULT_CURRENCY]
}