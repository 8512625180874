import { axios_api } from "../../api";
import { currency_symbol, DEFAULT_CURRENCY } from "../../constants/constants";
import { ICompanyOutlet, ICompanyOutletState } from "../../redux/settings/outlets";
import { IUserProfile, IUserProfileOutlets } from "../../redux/settings/user-profile";
import { getOutletInfoFromLocalStore, saveOutletInfoToLocalStore } from "./OutletContext.local-store";
import { IOutletContextState } from "./OutletContext.types";




export const checkIfUserHasValidOutlet = (outletsInfo: ICompanyOutlet[]): IOutletContextState | null => {
    if (outletsInfo.length > 1) {
        const currentOutlet = getOutletInfoFromLocalStore();
        if (currentOutlet) {
            const hasValidOutlet = outletsInfo.find(outlet => outlet.outlet_uuid === currentOutlet.outlet_uuid);
            if (hasValidOutlet) {
                return getOutletContextStatefromOutlet(hasValidOutlet, true)
            }
            return null
        }
        return null
    }
    return getOutletContextStatefromOutlet(outletsInfo[0])
}



export const getOutletContextStatefromOutlet = (outletInfo: ICompanyOutlet, isMultiple?: boolean): IOutletContextState => {
    saveOutletInfoToLocalStore(outletInfo)
    return {
        ...outletInfo,
        isMultipleOutlets: isMultiple || false,
        outlet_details: outletInfo,
        default_tax_rate: outletInfo.default_tax_rate,
        OUTLET_CURRENCY_SYMBOL: outletInfo.outlet_currency ? currency_symbol[outletInfo.outlet_currency as "USD"] : currency_symbol[DEFAULT_CURRENCY]
    }
}


//-----v2


export const checkIfUserHasValidOutletV2 = async (outletsInfo: IUserProfile["outlet_details"]): Promise<IOutletContextState | null> => {
    if (outletsInfo.length > 1) {
        const currentOutlet = getOutletInfoFromLocalStore();
        if (currentOutlet) {
            const hasValidOutlet = outletsInfo.find(outlet => outlet.outlet_uuid === currentOutlet.outlet_uuid);
            if (hasValidOutlet) {
                return await getOutletContextStatefromOutletV2(hasValidOutlet, true)
            }
            return null
        }
        return null
    }
    return await getOutletContextStatefromOutletV2(outletsInfo[0])
}



export const getOutletContextStatefromOutletV2 = (outletInfo: IUserProfileOutlets, isMultiple?: boolean) => new Promise<IOutletContextState>((resolve, reject) => {
    axios_api.get(`/company/get-outlet?outlet_uuid=${outletInfo.outlet_uuid}`).then((response) => {
        const outletInfo = response.data.data[0] as ICompanyOutlet;
        saveOutletInfoToLocalStore(outletInfo)
        resolve({
            ...outletInfo,
            isMultipleOutlets: isMultiple || false,
            outlet_details: outletInfo,
            default_tax_rate: outletInfo.default_tax_rate,
            OUTLET_CURRENCY_SYMBOL: outletInfo.outlet_currency ? currency_symbol[outletInfo.outlet_currency as "USD"] : currency_symbol[DEFAULT_CURRENCY]
        })
    })
})