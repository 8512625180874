import { createAsyncThunk } from "@reduxjs/toolkit";
import { IAnalyticsEarningGraph, IAnalyticsState } from "./analytics.types";
import { axios_api } from "../../api";
import { IOrder } from "../orders";

export const fetchEarningBoardAsync = createAsyncThunk<
  { recordCount: number; data: IAnalyticsEarningGraph },
  { duration_type: string; duration: string }
>("analytics/fetchSingleAirlineMenuListAsync", async ({ duration_type, duration }) => {
  const finalUrl = `/analytics/get-analytics-dashboard-earnings?duration_type=${duration_type}&duration=${duration}`;
  const response = await axios_api.get(finalUrl);
  const data = response.data.data;
  const count = response.data.totalRecords;
  return { recordCount: count, data: data };
});


export const fetchDashboardTakeAwayOrdersAsync = createAsyncThunk<{ totalRecords: number; data: IOrder[] }>(
  "analytics/fetchDashboardTakeAwayOrdersAsync", async () => {
    const response = await axios_api.get(`/analytics/get-analytics-dashboard-take-away-orders?pageNo=${1}&itemPerPage=${5}`);
    const data = response.data.data;
    const count = response.data.totalRecords;
    return { totalRecords: count, data: data };
  });



export const fetchDashboardExpenseCatgoriesAsync = createAsyncThunk<{
  totalRecords: number;
  data: IAnalyticsState["expense_categories_list"]["data"]
  overview: IAnalyticsState["expense_categories_list"]["overview"]
}>(
  "analytics/fetchDashboardExpenseCatgoriesAsync", async () => {
    const response = await axios_api.get(`/analytics/get-analytics-dashboard-expenses-categories`);
    const data = response.data.data;
    const count = response.data.totalRecords;
    return { totalRecords: count, data: data[0], overview: data[1] };
  });

  export const fetchDashboardShiftsAsync = createAsyncThunk<{
    totalRecords: number;
    data: IAnalyticsState["active_shifts_list"]["data"]
  }>(
    "analytics/fetchDashboardShiftsAsync", async () => {
      const response = await axios_api.get(`/analytics/get-analytics-dashboard-shift-details`);
      const data = response.data.data;
      const count = response.data.totalRecords;
      return { totalRecords: count, data };
    });