import React from "react";
import { Dialog } from "../../../../Components/Dialogs/Dialog";
import { Box, Grid, InputAdornment, TextField } from "@mui/material";
import { useFormik } from "formik";
import { useAppDispatch } from "../../../../redux";
import { ControlledCustomSelect, OutlinedTextField } from "../../../../Components/formComponents";
import { IPaymentReceived, upsertSinglePaymentReceivedAsync } from "../../../../redux/accounting/payment-received";
import { order_Status_list, order_type_list } from "../PaymentsReceived.constants";
import { useOutletContext } from "../../../../providers";


export interface IUpsertPaymentsReceivedDialogProps {
  open: boolean;
  initialState: IPaymentReceived;
  onClose: () => void;
}
export const UpsertPaymentsReceivedDialog: React.FC<IUpsertPaymentsReceivedDialogProps> = (props) => {
  const { open, initialState, onClose } = props;

  const [saveLoading, setSaveLoading] = React.useState(false);
  const { default_tax_rate, OUTLET_CURRENCY_SYMBOL } = useOutletContext()

  const dispatch = useAppDispatch();
  const {
    values,
    errors,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: { ...initialState },
    validate: (values) => {
      const errors: any = {};
      if (!values.order_type) {
        errors.order_type = "*This field is required.";
      }
      return errors;
    },
    onSubmit: async (values) => {
      setSaveLoading(true);
      dispatch(
        upsertSinglePaymentReceivedAsync(values)
      ).then((res) => {
        if (res.meta.requestStatus === "fulfilled") {
          onClose()
        }
      }).finally(() => {
        setSaveLoading(false);
      });
    },
  });

  return (
    <Dialog
      open={open}
      title="Add/Edit Payment Received"
      size="sm"
      contentWrappedWithForm={{ onSubmit: handleSubmit }}
      onClose={onClose}
      actions={[
        {
          type: "button",
          variant: "outlined",
          label: "Cancel",
          onClick: onClose,
        },
        {
          type: "submit",
          variant: "contained",
          label: "Add",
          disabled: saveLoading
        },
      ]}
    >
      <form>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <ControlledCustomSelect
              label="Order Type"
              name="order_type"
              value={values.order_type}
              onChange={handleChange}
              options={order_type_list}
            />
          </Grid>
          <Grid item xs={12}>
            <OutlinedTextField
              label="Order Number"
              id="order_number"
              value={values.order_number}
              onChange={handleChange}
              error={errors.order_number}
            />
          </Grid>
          <Grid item xs={12}>
            <OutlinedTextField
              label="Invoice Number"
              id="invoice_number"
              value={values.invoice_number}
              onChange={handleChange}
              error={errors.invoice_number}
            />
          </Grid>
          <Grid item xs={12}>
            <OutlinedTextField
              label="Total Tax"
              id="total_tax"
              type="number"
              value={values.total_tax}
              onChange={handleChange}
              error={errors.total_tax}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Box component={"span"} sx={{ fontSize: 18 }}>{OUTLET_CURRENCY_SYMBOL}</Box>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <OutlinedTextField
              label="total Amount"
              id="total_amount"
              type="number"
              value={values.total_amount}
              onChange={handleChange}
              error={errors.total_amount}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Box component={"span"} sx={{ fontSize: 18 }}>{OUTLET_CURRENCY_SYMBOL}</Box>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <ControlledCustomSelect
              label="Order Status"
              name="order_status"
              value={values.order_status}
              onChange={handleChange}
              options={order_Status_list}
            />
          </Grid>
        </Grid>
      </form>
    </Dialog>
  );
};
