import React from "react";
import { Avatar, Stack, Typography } from "@mui/material";
import { IOrderItem } from "../../../../../redux/orders";
import { StandardCard } from "../../../../../Components/Card";
import { Comment } from "@mui/icons-material";
import { OrderItemCommentButton } from "./OrderItemCommentButton";
import { useOutletContext } from "../../../../../providers";

interface IOrderItemCardProps {
    product: IOrderItem,
    index: number
}
export const OrderItemCard: React.FC<IOrderItemCardProps> = ({
    product,
    index
}) => {
const { OUTLET_CURRENCY_SYMBOL } = useOutletContext()
    return (
        <StandardCard
            sx={{
                mb: 1,
                p: 2,
            }}
        >
            <Stack
                spacing={0.5}
                direction={"row"}
                justifyContent={"space-between"}
                alignItems={"center"}
            >
                <Stack spacing={2} direction={"row"} alignItems={"center"}>
                    {/* <Avatar sx={{ width: 24, height: 24, bgcolor: "#fff" }}>
                        {index}
                    </Avatar> */}
                    <Typography variant="h5" color={"#fff"} fontWeight={"500"}>
                        {product?.product_price_by === "WEIGHT" ? `${product.product_name} X ${product.quantity}${product.product_weight_unit}` : `${product.product_name} X ${product.quantity}`} 
                    </Typography>
                    {product.notes.length > 0 && <OrderItemCommentButton content={product.notes} />}

                </Stack>
                <Typography variant="h5" color={"#fff"} fontWeight={"700"}>
                    {`${OUTLET_CURRENCY_SYMBOL} ${product.product_price}`}
                </Typography>
            </Stack>
            {product.options.length > 0 && <Typography variant="h5" color={"#929292"} mt={1} fontWeight={"500"} pl="40px">
                Add-ons
            </Typography>}
            {(product.options || []).map((option) => {
                return (
                    <Stack spacing={2} direction={"row"} justifyContent={"space-between"} alignItems={"center"} width="100%" pl="26px">
                        <Avatar sx={{ width: 24, height: 24, bgcolor: "#fff", display: "none" }} >
                            {index}
                        </Avatar>
                        <Typography variant="body1" color={"#fff"} fontWeight={"500"}>
                            {option.modifier_name}  x {option.quantity}
                        </Typography>
                        <Typography variant="body1" color={"#fff"} fontWeight={"700"}>
                            {`${OUTLET_CURRENCY_SYMBOL} ${option.modifier_price}`}
                        </Typography>
                    </Stack>
                )
            })}

        </StandardCard>
    );
};
