import React, { useState } from 'react'
import { ControlledCustomSelect } from '../../../../Components/formComponents'
import { IStoreState, useAppDispatch, useAppSelector } from '../../../../redux';
import { fetchOutletsListAsync } from '../../../../redux/settings/outlets';
import { IUserProfile } from '../../../../redux/settings/user-profile';
import { Autocomplete, TextField } from '@mui/material';

interface IOutletMultiSelectProps {
    value: IUserProfile["outlet_details"]
    onChange: (data: IUserProfile["outlet_details"]) => void
    error?: string
}
export const OutletMultiSelect: React.FC<IOutletMultiSelectProps> = ({
    value,
    onChange,
    error
}) => {
    const {
        data: outletsList,
        loading,
    } = useAppSelector((storeState: IStoreState) => storeState.settings.outlets.outlets_list);
    const dispatch = useAppDispatch();

    const [outetsList, setOutetsList] = useState<{ outlet_name: string, outlet_uuid: string }[]>([])

    React.useEffect(() => {
        dispatch(
            fetchOutletsListAsync({
                page: 1,
                rowsPerPage: 1000,
            }),
        );
    }, []);

    React.useEffect(() => {
        const newList = outletsList.map((data) => ({ outlet_name: data.outlet_name || "", outlet_uuid: data.outlet_uuid || "" }))
        setOutetsList(newList)
    }, [outletsList]);

    return (
        <>
            <Autocomplete
                multiple
                id="tags-outlined"
                size='small'
                options={outetsList}
                getOptionLabel={(option) => option.outlet_name}
                filterSelectedOptions
                value={value}
                onChange={(evt, newValue) => {
                    onChange(newValue)
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Select Outlets"
                        placeholder="Favorites"
                        error={!!error}
                        helperText={error}
                    />
                )}
            />
        </>


    )
}
