import { currency_symbol, DEFAULT_CURRENCY } from "../../constants/constants";
import { defaultOutlet, ICompanyOutlet } from "../../redux/settings/outlets";



export interface IPublicOutletContextState {
    activationKey: string |null
    // outlet_details: ICompanyOutlet
    OUTLET_CURRENCY_SYMBOL: string
}


export const defaultPublicOutletContextState: IPublicOutletContextState = {
    activationKey: null,
    // outlet_details: defaultOutlet,
    OUTLET_CURRENCY_SYMBOL: currency_symbol[DEFAULT_CURRENCY]
}