import React from "react";
import LinearProgress from "@mui/material/LinearProgress";
import { axios_api } from "../../api/axios-api";
import { ICompanyOutlet, setCurrentUserOutletInfo } from "../../redux/settings/outlets";
import { OutletContext } from "./OutletContext.context";
import { useAuthProvider } from "../Authentication";
import { useAppDispatch } from "../../redux";
import { defaultOutletContextState, IOutletContextState } from "./OutletContext.types";

import { checkIfUserHasValidOutlet, checkIfUserHasValidOutletV2 } from "./OutletContext.helpers";
import { MultipleOutletsScreen } from "./views/MultipleOutletsScreen";
import { NoOutletScreen } from "./views/NoOutletScreen";
import { IUserProfile } from "../../redux/settings/user-profile";



export const OutletContextProvider: React.FC<{ children: React.ReactNode }> = (
  props,
) => {
  const [outletInfo, setOutletInfo] = React.useState<IOutletContextState>(defaultOutletContextState);
  const [loading, setLoading] = React.useState(false);
  const [multipleOutlets, setMultipleOutlets] = React.useState<IUserProfile["outlet_details"] | null>(null);
  const [zeroOutletDialog, setZeroOutletDialog] = React.useState<boolean>(false);

  const dispatch = useAppDispatch()
  const { outlet_uuid, authInfo: { user: { user_uuid, role_value, outlet_details } } } = useAuthProvider()
  const isAdmin = role_value === "ADMIN"

  React.useEffect(() => {
    if (user_uuid) {
      getOutDetails()
    }
  }, [user_uuid]);


  const getOutDetails = async () => {
    setLoading(true)
    const outletsData = outlet_details || [];
    if (outletsData?.length === 0) return setZeroOutletDialog(true)
    const currentOutlet = await checkIfUserHasValidOutletV2(outletsData)
    if (currentOutlet) {
      setOutletInformation(currentOutlet)
    } else {
      setMultipleOutlets(outletsData)
    }
    setLoading(false);
  }

  // React.useEffect(() => {
  //   if (outlet_uuid) {
  //     setLoading(true)
  //     axios_api.get(`/company/get-outlet?outlet_uuid=${outlet_uuid}`).then((response) => {
  //       const outletsData = response.data.data as ICompanyOutlet[];
  //       if (outletsData?.length === 0) return setZeroOutletDialog(true)
  //       const currentOutlet = checkIfUserHasValidOutlet(outletsData)
  //       if (currentOutlet) {
  //         setOutletInformation(currentOutlet)
  //       } else {
  //         setMultipleOutlets(outletsData)
  //       }
  //       dispatch(setCurrentUserOutletInfo(outletsData[0]))
  //     }).finally(() => {
  //       setLoading(false);
  //     })

  //   }
  // }, [outlet_uuid]);

  const setOutletInformation = (outletInfo: IOutletContextState) => {
    axios_api.defaults.headers.common["outlet_uuid"] = `${outletInfo.outlet_details.outlet_uuid}`;
    axios_api.defaults.headers.common["outlet_name"] = `${outletInfo.outlet_details.outlet_name}`;
    setOutletInfo(outletInfo)
  }


  if (!isAdmin && zeroOutletDialog) {
    return <NoOutletScreen />
  }

  if (!isAdmin && user_uuid && (loading || !axios_api.defaults.headers.common["outlet_uuid"])) {
    return <LinearProgress />;
  }

  if (!isAdmin && multipleOutlets) {
    return <MultipleOutletsScreen outlets={multipleOutlets} onSelect={(outlet) => {
      setOutletInformation(outlet);
      setMultipleOutlets(null)
    }} />
  }

  return (
    <OutletContext.Provider
      value={outletInfo}
    >
      {props.children}
    </OutletContext.Provider>
  );
};