import { Box, styled, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { ControlledCustomSelect, CustomFormLabel, CustomSelectorWithLabel, ICustomSelectProps } from '../../Components/formComponents'
import { AxiosError } from 'axios'
import { axios_api } from '../../api'
import { useFormik } from 'formik'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import moment from 'moment'
import { getYearsList, groupDataByMonth, months_list } from './ReservationsV2.helpers'



export const CustomTableContainer = styled(TableContainer)({
    overflow: "auto",

    /* Custom scrollbar for WebKit browsers (Chrome, Safari) */
    "&::-webkit-scrollbar": {
          width: 8, /* Width of the vertical scrollbar */
          height: 8, /* Height of the horizontal scrollbar */
    },

    "&::-webkit-scrollbar-track": {
          background: "#f1f1f1", /* Background of the scrollbar track */
    },

    "&::-webkit-scrollbar-thumb": {
          background: "#888", /* Color of the scrollbar thumb */
          borderRadius: 12, /* Rounded corners of the scrollbar thumb */
    },

    "&::-webkit-scrollbar-thumb:hover": {
          background: "#555" /* Color of the scrollbar thumb on hover */
    },

    /* Custom scrollbar for Firefox */
    scrollbarWidth: "thin", /* Thin scrollbar */
    scrollbarColor: "#4e4e4e #222222", /* Thumb color, Track color */
    
    /* Optional: Style the scrollbar corner for WebKit browsers */
    "&::-webkit-scrollbar-corner": {
          background: "#f1f1f1" /* Background of the scrollbar corner */
    }
})


export const CustomTableCell = styled(TableCell)({
    border: "1px solid #404040",
    background: "#222222",
    color: "#fff"
});

export const ReservationsV2 = () => {
    const [resortsList, setResortsList] = useState<ICustomSelectProps["options"]>([])
    

    const [selectedColumnToEdit, setSelectedColumnToEdit] = useState<{
        key: string,
        index: number,
        data: any
    } | null>(null)

    const [inventoryData, setInventoryData] = useState<any | null>(null)
    const [showInventoryError, setShowInventoryError] = useState<boolean>(false)


    const {
        values,
        errors,
        handleChange,
        handleSubmit,
        setValues
    } = useFormik({
        initialValues: {
            hotel_uuid: null,
            search_month: String(new Date().getMonth() + 1).padStart(2, '0'),
            search_year: new Date().getFullYear()
        },
        validate: (values) => {
            const errors: any = {};
            if (!values.search_month) {
                errors.search_month = "*This field is required.";
            }
            if (!values.search_year) {
                errors.search_year = "*This field is required.";
            }
            return errors;
        },
        onSubmit: async (values) => {
            let searchQuery = ""

            if (values.search_month && values.search_year) {
                searchQuery += `from_date=${values.search_year}-${values.search_month}-01&to_date=${values.search_year}-${values.search_month}-30`
            }

            if (values.hotel_uuid) {
                searchQuery += `&property_details_uuid=${values.hotel_uuid}`
            }

            axios_api.get(`/inventory/get-inventory?${searchQuery}`).then((res) => {
                setShowInventoryError(false)
                setInventoryData(res.data.data)
            }).catch((error: AxiosError) => {
                setInventoryData(null)
                console.error(" setShowInventoryError ", error)
                if (error?.response?.status === 404) {
                    console.error(" setShowInventoryError IN  ", error.status)
                    setShowInventoryError(true)
                }
            })
        },
    });


    // useEffect(() => {
    //     axios_api.get('/property/get-property').then((res) => {
    //         setResortsList(res.data.data.map((property: any) => {
    //             return { label: property.property_details_name, value: property.property_details_uuid }
    //         }))
    //     })
    // }, [])

    useEffect(() => {
        if(resortsList.length > 0)
        setValues({
            ...values,
            hotel_uuid: resortsList[0].value  as any
        })
    }, [resortsList])


    useEffect(() => {
        if (!values?.hotel_uuid && (values.hotel_uuid as any)?.length !> 0) return
        if (!values?.search_month && values.search_month?.length !> 0) return
        if (!values?.search_year && values.search_year !> 0) return
        let searchQuery = ""

        if (values.search_month && values.search_year) {
            searchQuery += `from_date=${values.search_year}-${values.search_month}-01&to_date=${values.search_year}-${values.search_month}-30`
        }

        if (values.hotel_uuid) {
            searchQuery += `&property_details_uuid=${values.hotel_uuid}`
        }

        axios_api.get(`/inventory/get-inventory?${searchQuery}`).then((res) => {
            setShowInventoryError(false)
            setInventoryData(res.data.data)
        }).catch((error: AxiosError) => {
            setInventoryData(null)
            console.error(" setShowInventoryError ", error)
            if (error.response?.status === 404) {
                console.error(" setShowInventoryError IN  ", error.status)
                setShowInventoryError(true)
            }
        })
    }, [values])


    return <Box sx={{ background: "#000", paddingBlock: 8, marginInline: -5, minHeight: "calc(100vh - 6.66vh)" }}>

    <Box
        // component="form"
        // onSubmit={handleSubmit}
        p={2}
        sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "flex-start",
            gap: 2,
            background: "#111111"
        }}>
        <Box>
            <CustomFormLabel>
                Select Resort
            </CustomFormLabel>

            <ControlledCustomSelect
                sx={{ minWidth: 250 }}
                name="hotel_uuid"
                options={resortsList}
                value={values.hotel_uuid}
                placeholder="Select One"
                onChange={handleChange}
                error={errors.hotel_uuid }
            />
        </Box>

        <Box>

            <CustomFormLabel>
                Select Month
            </CustomFormLabel>
            <ControlledCustomSelect
                sx={{ minWidth: 150 }}
                name="search_month"
                options={months_list}
                value={values.search_month}
                onChange={handleChange}
                error={errors.search_month}
            />
        </Box>


        <Box>
            <CustomSelectorWithLabel
                sx={{ minWidth: 150 }}
                label="Select Year"
                name="search_year"
                options={getYearsList()}
                value={values.search_year}
                onChange={handleChange}
                error={errors.search_year as string}
            />
        </Box>
        {/* <Button
            type="submit"
            variant="contained"
            sx={{ mt: 3 }}
        >
            Search
        </Button> */}
    </Box>

    {showInventoryError &&
        <Box sx={{ py: 4, display: "flex", justifyContent: "center", alignItems: "center", gap: 0.5 }}>
            <Typography variant="h6" sx={{ color: "#fff" }}>No rooms found!</Typography>
        </Box>
    }

    {inventoryData && Object.keys(inventoryData).length > 0 &&
        <Box sx={{ p: 3 }}>
            <Box sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center", gap: 0.5 }}>
                <Typography variant="h6" sx={{ color: "#fff" }}>Search Results</Typography>
                <CheckCircleIcon sx={{ color: "#fff" }} />
            </Box>

            <CustomTableContainer  >
                <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                    <TableBody>
                        <TableRow >
                            <CustomTableCell rowSpan={3}>
                                Unit Type
                            </CustomTableCell>
                            {Object.keys(groupDataByMonth(inventoryData)).map((object_key) => {
                                const columnLength = (groupDataByMonth(inventoryData) as any)[object_key].length
                                return (
                                    <CustomTableCell
                                        key={object_key}
                                        colSpan={columnLength}
                                    >
                                        <Typography variant="body1" textAlign={"center"}>
                                            {moment(object_key).format('MMM')}
                                        </Typography>
                                        <Typography variant="body1" textAlign={"center"}>
                                            {moment(object_key).format('YYYY')}
                                        </Typography>
                                    </CustomTableCell>
                                )
                            })}
                        </TableRow>
                        <TableRow >
                            {Object.keys(inventoryData).map((object_key) => (
                                <CustomTableCell key={object_key}>{moment(object_key).format('DD')}</CustomTableCell>
                            ))}
                        </TableRow>
                        <TableRow >
                            {Object.keys(inventoryData).map((object_key) => (
                                <CustomTableCell key={object_key}>{moment(object_key).format('dddd').charAt(0)}</CustomTableCell>
                            ))}
                        </TableRow>


                        {inventoryData[Object.keys(inventoryData)[0]].length > 0 && <TableRow >
                            <CustomTableCell colSpan={Object.keys(inventoryData).length + 3}>
                                <Typography variant="h6">{inventoryData[Object.keys(inventoryData)[0]][0]["property_details_name"]}</Typography>
                            </CustomTableCell>
                        </TableRow>}



                        {(inventoryData[Object.keys(inventoryData)[0]] || []).map((hotel: any, hotel_idx: number) => {
                            const hotelName = hotel["room_types_name"]
                            return (
                                <TableRow key={hotel_idx}>
                                    <CustomTableCell sx={{ minWidth: 120 }}>
                                        <Typography variant="body1">{hotelName}</Typography>
                                    </CustomTableCell>

                                    {Object.keys(inventoryData).map((object_key) => {
                                        const current_column_data = inventoryData[object_key][hotel_idx]
                                        const available_room = current_column_data["available_room"]

                                        // if (selectedColumnToEdit && selectedColumnToEdit["key"] === object_key && selectedColumnToEdit["index"] === hotel_idx) {
                                        //     return (
                                        //         <CustomTextField
                                        //             key={object_key + hotel_idx}
                                        //             type="number"
                                        //             value={selectedColumnToEdit.value}
                                        //             onChange={(evt) => setSelectedColumnToEdit({
                                        //                 ...selectedColumnToEdit,
                                        //                 value: Number(evt.target.value)
                                        //             })}
                                        //             sx={{ minWidth: 100 }}
                                        //             onBlur={() => handleColumnChange(current_column_data)}
                                        //         />
                                        //     )
                                        // }
                                        return (
                                            <CustomTableCell
                                                key={object_key + hotel_idx}
                                                sx={{
                                                    cursor: "pointer"
                                                }}
                                                onClick={() => {
                                                    setSelectedColumnToEdit({
                                                        key: object_key,
                                                        index: hotel_idx,
                                                        data: current_column_data
                                                    })
                                                }}
                                            >
                                                {available_room}
                                            </CustomTableCell>
                                        )
                                    })}
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </CustomTableContainer>
        </Box >
    }


    {/* {selectedColumnToEdit && (
        <InventoryColumnDataDialog
            open={true}
            onClose={() => setSelectedColumnToEdit(null)}
            data={selectedColumnToEdit.data}
            onSaveSuccess={(data) => handleColumnChange(data)}
        />
    )} */}
</Box >
}
