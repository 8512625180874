import { ICompanyOutlet } from "../../redux/settings/outlets"


const USER_OUTLET_STORAGE_KEY = "user_outlet"




export const saveOutletInfoToLocalStore = (OutletInfo: ICompanyOutlet) => {
    localStorage.setItem(USER_OUTLET_STORAGE_KEY, JSON.stringify(OutletInfo))
}


export const getOutletInfoFromLocalStore = (): ICompanyOutlet | null => {
    const OutletInfo = localStorage.getItem(USER_OUTLET_STORAGE_KEY)
    return OutletInfo ? JSON.parse(OutletInfo) : null
}