import React, { useEffect } from "react";
import { pos_app_routes } from "../constants/constants";
import { PublicDashboard } from "./PublicDashboard";
import { PublicOutletContextProvider } from "../providers/PublicOutletContext";
import { Outlet } from "react-router-dom";
import { AppPublicMenu } from "./PublicMenu/AppPublicMenu";



export const publicDahboardRoutes = [
    {
        path: pos_app_routes.public.root,
        exact: true,
        element: <PublicOutletContextProvider><Outlet /></PublicOutletContextProvider>,
        children: [
            {
                path: pos_app_routes.public.dashboard,
                exact: true,
                element: <PublicDashboard />
            },
            {
                path: pos_app_routes.public.menu,
                exact: true,
                element: <AppPublicMenu />
            },
        ]
    },
]

