import { createAsyncThunk } from "@reduxjs/toolkit";
import { IQueryParams, getSearchQuery } from "../../../common";
import { axios_api } from "../../../api/axios-api";
import { ICategory } from "./categories.types";
import { GET_CATEGORIES_BASE_URL, UPSERT_CATEGORIES_BASE_URL } from "../../../api/axios-api.endpoints";


export const fetchPublicCategoriesListAsync = createAsyncThunk<{ recordCount: number, data: ICategory[] }, string>(
    'inventory-categories/fetchPublicCategoriesListAsync', async (uuid) => {
        const response = await axios_api.get(`/inventory/get-public-category?outlet_uuid=${uuid}`)
        const data = response.data.data;
        const count = response.data.totalRecords
        return { recordCount: count, data: data }
    },
)


export const fetchCategoriesListAsync = createAsyncThunk<{ recordCount: number, data: ICategory[] }, string>(
    'inventory-categories/fetchCategoriesListAsync', async (uuid) => {
        const response = await axios_api.get(`${GET_CATEGORIES_BASE_URL}?outlet_uuid=${uuid}`)
        const data = response.data.data;
        const count = response.data.totalRecords
        return { recordCount: count, data: data }
    },
)

export const fetchCategoriesListWithArgsAsync = createAsyncThunk<{ recordCount: number, data: ICategory[] }, IQueryParams>(
    'inventory-categories/fetchCategoriesListWithArgsAsync', async (queryParams: IQueryParams, thunkAPI) => {
        const searchQuery = getSearchQuery(queryParams);
        const response = await axios_api.get(`${GET_CATEGORIES_BASE_URL}${searchQuery}`)
        const data = response.data.data;
        const count = response.data.totalRecords
        return { recordCount: count, data: data }
    },
)


export const upsertSingleCategoryAsync = createAsyncThunk<ICategory, ICategory>(
    'inventory-categories/upsertSingleCategoryAsync', async (payload: ICategory, thunkAPI) => {
        const response = await axios_api.post(UPSERT_CATEGORIES_BASE_URL, payload)
        thunkAPI.dispatch(fetchCategoriesListWithArgsAsync({
            outlet_uuid: payload.outlet_uuid as string,
            page: 1,
            rowsPerPage: 25
        }))
        return response.data
    },
)