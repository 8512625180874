import React from 'react'
import { Box, Button, styled, Typography } from '@mui/material'
import { useAuthProvider } from '../../Authentication'


const CustomBox = styled(Box)(({ theme }) => ({
    backgroundColor: "#121212",
    height: '100vh',
    display: 'flex',
    flexDirection: "column",
    justifyContent: "center",
    alignItems: 'center'
}));

export const NoOutletScreen: React.FC<{}> = () => {
    const { logoutAsync } = useAuthProvider()

    const handleLogoutClick = () => {
        logoutAsync()
        window.location.reload()
    }

    return (
        <CustomBox>
            <Typography variant='h2' sx={{ color: "#fff", fontWeight: 600 }}>Ooops..! It Seems you have No access to any outlet.</Typography>
            <Typography variant='h3' sx={{ color: "#fff" }}>Please contact your admin</Typography>
            <Typography variant='h3' sx={{ color: "#fff" }}>or</Typography>
            <Typography variant='h3' sx={{ color: "#fff", mb: 2 }}>Login with different account to continue</Typography>
            <Button variant='contained' onClick={handleLogoutClick}>Logout</Button>
        </CustomBox>
    )
}
