import React from "react";
//@ts-ignore
import FeatherIcon from "feather-icons-react";
import {
  AppBar,
  Box,
  IconButton,
  Toolbar,
  Menu,
  Typography,
  Button,
  LinearProgress,
  Stack,
  Divider,
} from "@mui/material";
import { ProfileDropdown } from "./ProfileDropdown";
import { IHeaderProps } from "./types";
import { Theme } from "@mui/material/styles";
import { LogoIcon } from "../logo/Logo";
import { useNavigate } from "react-router-dom";
import { AccountCircleOutlined, ContentPasteOutlined } from "@mui/icons-material";
import { useAuthProvider, useOutletContext } from "../../../providers";
import { CustomTypography } from "../../../Components/formComponents";
import { CURRENT_USER_PROFILE_ROUTE } from "../../../views/Settings/UserProfile/UserProfile.constant";
import { OutletsDropDown } from "../../../views/Settings/CompanyOutlets/components/OutletsDropDown";
import { OutletsAutoSearch } from "../../../views/Settings/CompanyOutlets/AutoSearch/OutletsAutoSearch";
import { saveOutletInfoToLocalStore } from "../../../providers/OutletContext/OutletContext.local-store";
import { pos_app_routes } from "../../../constants/constants";
import LocalMallIcon from '@mui/icons-material/LocalMall';
import PaymentIcon from '@mui/icons-material/Payment';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';

export const Header: React.FC<IHeaderProps> = ({
  sx,
  customClass,
  toggleSidebar,
  toggleMobileSidebar,
  isUserView
}) => {
  const { authInfo, logoutAsync } = useAuthProvider();
  const { isMultipleOutlets, outlet_details: { outlet_uuid, outlet_name } } = useOutletContext();

  // 4
  const [anchorEl4, setAnchorEl4] = React.useState<any>(null);

  const handleClick4 = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    setAnchorEl4(event.currentTarget);
  };

  const handleClose4 = () => {
    setAnchorEl4(null);
  };
  const navigate = useNavigate();
  return (
    <AppBar sx={sx} elevation={0} className={customClass}>
      <Toolbar>
        <LogoIcon />
        {!isUserView &&
          <>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={toggleSidebar}
              size="large"
              sx={{
                ml: 3,
                display: {
                  lg: "flex",
                  xs: "none",
                },
              }}
            >
              <FeatherIcon icon="menu" />
            </IconButton>

            <IconButton
              size="large"
              color="inherit"
              aria-label="menu"
              onClick={toggleMobileSidebar}
              sx={{
                display: {
                  lg: "none",
                  xs: "flex",
                },
              }}
            >
              <FeatherIcon icon="menu" size={20} />
            </IconButton>
          </>
        }

        {/* ------------ End Menu icon ------------- */}

        <Box flexGrow={1} />
        {isMultipleOutlets && <OutletsAutoSearch
          value={{
            outlet_uuid,
            outlet_name
          }}
          onSelect={(newValue) => {
            saveOutletInfoToLocalStore(newValue)
            window.location.reload()
          }}
        />}
        <Box
          sx={{
            width: "1px",
            backgroundColor: "rgba(0,0,0,0.1)",
            height: "25px",
            ml: 1,
            mr: 1,
          }}
        />
        {/* ------------------------------------------- */}
        {/* Profile Dropdown */}
        {/* ------------------------------------------- */}
        <Button
          aria-label="menu"
          color="inherit"
          aria-controls="profile-menu"
          aria-haspopup="true"
          onClick={handleClick4}
        >
          <Box display="flex" alignItems="center">
            <Box
              display={"flex"}
              justifyContent="center"
              alignItems={"center"}
              width="33px"
              height={"33px"}
              borderRadius={"100%"}
              //@ts-ignore
              sx={{ backgroundColor: "#3e3e3e" }}
            >
              <Typography variant="h6" color="primary.main" fontWeight={600}>
                {authInfo.name.charAt(0).toUpperCase()}
              </Typography>
            </Box>
            <Box
              sx={{
                display: {
                  xs: "none",
                  sm: "flex",
                },
                alignItems: "center",
              }}
            >
              <CustomTypography variant="body1" fontWeight="400" sx={{ ml: 1 }}>
                Hi,
              </CustomTypography>
              <CustomTypography
                variant="body1"
                sx={{
                  ml: 1,
                }}
              >
                {authInfo.name}
              </CustomTypography>
              <FeatherIcon icon="chevron-down" size={20} />
            </Box>
          </Box>
        </Button>
        <Menu
          id="profile-menu"
          anchorEl={anchorEl4}
          keepMounted
          open={Boolean(anchorEl4)}
          onClose={handleClose4}
          sx={{
            "& .MuiMenu-paper": {
              width: "385px",
              right: 0,
              top: "70px !important",
            },

            "& .MuiList-padding": {
              p: "30px",
            },
          }}
        >
          <Box
            sx={{
              mb: 1,
            }}
          >
            <Box display="flex" alignItems="center">
              <Typography variant="h4" fontWeight="500">
                User Profile
              </Typography>
            </Box>
          </Box>

          <ProfileDropdown />
          <Stack onClick={handleClose4}>
            <Button
              sx={{
                mt: 2,
                display: "flex",
                alignItems: "flex-start",
                flexDirection: "row",
                justifyContent: "flex-start",
                width: "100%",
                py: 1,
              }}
              variant="text"
              color="primary"
              onClick={() => navigate(CURRENT_USER_PROFILE_ROUTE)}
            >
              <AccountCircleOutlined
                sx={{
                  backgroundColor: "#727272",
                  color: "white",
                  p: 1,
                  borderRadius: 2,
                  fontSize: "3.1875rem",
                }}
              />
              <Stack
                direction="column"
                alignItems={"flex-start"}
                sx={{ ml: 1 }}
              >
                <Typography variant="h4" fontWeight="500">
                  My Profile
                </Typography>
                <Typography color={"grey"} variant="h5">
                  Setting
                </Typography>
              </Stack>
            </Button>
            {authInfo.user.role_value === "USER" &&
              <>
                <Button
                  sx={{
                    mt: 2,
                    display: "flex",
                    alignItems: "flex-start",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    width: "100%",
                    py: 1,
                  }}
                  variant="text"
                  color="primary"
                  onClick={() => navigate(pos_app_routes.private.orders.userOrderHistory)}
                >
                  <LocalMallIcon
                    sx={{
                      backgroundColor: "#727272",
                      color: "white",
                      p: 1,
                      borderRadius: 2,
                      fontSize: "3.1875rem",
                    }}
                  />
                  <Stack
                    direction="column"
                    alignItems={"flex-start"}
                    sx={{ ml: 1 }}
                  >
                    <Typography variant="h4" fontWeight="500">
                      My Orders
                    </Typography>
                    <Typography color={"grey"} variant="h5">
                      Setting
                    </Typography>
                  </Stack>
                </Button>
                <Button
                  sx={{
                    mt: 2,
                    display: "flex",
                    alignItems: "flex-start",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    width: "100%",
                    py: 1,
                  }}
                  variant="text"
                  color="primary"
                  onClick={() => navigate(pos_app_routes.private.accounting.userPaymentHistory)}
                >
                  <PaymentIcon
                    sx={{
                      backgroundColor: "#727272",
                      color: "white",
                      p: 1,
                      borderRadius: 2,
                      fontSize: "3.1875rem",
                    }}
                  />
                  <Stack
                    direction="column"
                    alignItems={"flex-start"}
                    sx={{ ml: 1 }}
                  >
                    <Typography variant="h4" fontWeight="500">
                      Payment History
                    </Typography>
                    <Typography color={"grey"} variant="h5">
                      Setting
                    </Typography>
                  </Stack>
                </Button>
                <Button
                  sx={{
                    mt: 2,
                    display: "flex",
                    alignItems: "flex-start",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    width: "100%",
                    py: 1,
                  }}
                  variant="text"
                  color="primary"
                  onClick={() => navigate(pos_app_routes.private.accounting.userPaymentHistory)}
                >
                  <AccountBalanceWalletIcon
                    sx={{
                      backgroundColor: "#727272",
                      color: "white",
                      p: 1,
                      borderRadius: 2,
                      fontSize: "3.1875rem",
                    }}
                  />
                  <Stack
                    direction="column"
                    alignItems={"flex-start"}
                    sx={{ ml: 1 }}
                  >
                    <Typography variant="h4" fontWeight="500">
                      Refer & Earn
                    </Typography>
                    <Typography color={"grey"} variant="h5">
                      Setting
                    </Typography>
                  </Stack>
                </Button>
              </>
            }
            {/* <Divider sx={{ my: 2 }} />
            <Button
              sx={{
                mt: 2,
                display: "flex",
                alignItems: "flex-start",
                flexDirection: "row",
                justifyContent: "flex-start",
                width: "100%",
                py: 1,
              }}
              variant="text"
              color="primary"
              onClick={() => navigate("/taskboard")}
            >
              <ContentPasteOutlined
                sx={{
                  backgroundColor: (theme) => theme.palette.primary.main,
                  color: "white",
                  p: 1,
                  borderRadius: 2,
                  fontSize: "3.1875rem",
                }}
              />
              <Stack
                direction="column"
                alignItems={"flex-start"}
                sx={{ ml: 1 }}
              >
                <Typography variant="h4" color={"black"} fontWeight="500">
                  My Tasks
                </Typography>
                <Typography color={"grey"} variant="h5">
                  To-do and Daily Tasks
                </Typography>
              </Stack>
            </Button> */}
          </Stack>

          <Button
            sx={{
              mt: 2,
              display: "block",
              width: "100%",
            }}
            variant="contained"
            color="primary"
            onClick={async () => await logoutAsync()}
          >
            Logout
          </Button>
        </Menu>
      </Toolbar>
    </AppBar>
  );
};
