import { Box, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { ControlledCustomSelect } from "../../Components/formComponents";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import PageContainer from "../../Components/PageContainer/PageContainer";
import { IStoreState, useAppDispatch, useAppSelector } from "../../redux";
import moment from "moment";
import { CustomDatePicker } from "../../Components/formComponents/CustomDatePicker";
import { IOrder, clearOrdersStateSync, fetchOrdersListWithArgsAsync } from "../../redux/orders";
import { CustomButton } from "../../Components/CustomButton";
import { LoadState } from "../../constants/enum";
import { useAuthProvider, useOutletContext } from "../../providers";
import { POSOrderTableCard } from "../../views/MenuAndOrders/PosOrders/POSOrderTableCard/POSOrderTableCard";

export const MyOrdersTable = () => {
    const { user_uuid } = useAuthProvider()
    const { outlet_details: { outlet_uuid } } = useOutletContext()
    const {
        data: posOrdersList,
        loading: ordersLoading,
        totalRecords: totalCount
    } = useAppSelector((storeState: IStoreState) => storeState.orders.orders_list);
    const dispatch = useAppDispatch();

    const [dateFilter, setDateFilter] = React.useState<string>("ALL");
    const [customDate, setCustomDate] = React.useState<string | null>(null);
    const [customDateDialog, setCustomDateDialog] = React.useState<boolean>(false);
    const [ordersList, setOrdersList] = useState<IOrder[]>([])
    const [pagination, setPagination] = React.useState({
        pageNumber: 1,
        rowsPerPage: 20,
    });

    React.useEffect(() => {
        if (!user_uuid || !outlet_uuid) return;
        dispatch(fetchOrdersListWithArgsAsync({
            user_uuid,
            outlet_uuid,
            page: pagination.pageNumber,
            rowsPerPage: pagination.rowsPerPage
        }));

    }, [pagination, user_uuid, outlet_uuid]);


    useEffect(() => {
        let newList = [...posOrdersList]
        if (dateFilter === "ALL") {
            setOrdersList(newList)
        } else if (dateFilter === "CUSTOM") {
            setCustomDateDialog(true)
        } else {
            newList = newList.filter((res) => moment(res.create_ts).format("yyyy-MM-DD") === dateFilter)
            setOrdersList(newList)
        }
    }, [dateFilter, posOrdersList])

    useEffect(() => {
        if (dateFilter === "CUSTOM" && customDate) {
            const newCustomDate = moment(customDate).format("yyyy-MM-DD")
            const newList = posOrdersList.filter((res) => moment(res.create_ts).format("yyyy-MM-DD") === newCustomDate)
            setOrdersList(newList)
        }
    }, [customDate])


    React.useEffect(() => {
        return () => {
            dispatch(clearOrdersStateSync())
        }
    }, []);

    const handleLoadMoreButtonClick = () => {
        setPagination({
            ...pagination,
            pageNumber: pagination.pageNumber + 1
        })
    }

    return (
        <PageContainer title="My Orders" description="My Orders">
            <Typography variant="h2" mb={2}>My Orders</Typography>
            <Stack
                spacing={2}
                direction={"row"}
                justifyContent={"flex-start"}
                alignItems={"center"}
                flexWrap={"wrap"}
                mt={2.5}
            >
                <Box sx={{ minWidth: 180, maxWidth: 180 }}>
                    <ControlledCustomSelect
                        label="Search by Date"
                        options={[
                            { label: "All", value: "ALL" },
                            { label: "Today", value: moment().format("yyyy-MM-DD") },
                            { label: "Yesterday", value: moment().subtract(1, "day").format("yyyy-MM-DD") },
                            { label: "Custom", value: "CUSTOM" },
                        ]}
                        value={dateFilter}
                        onChange={(evt) => setDateFilter(evt.target.value as string)}
                        fullWidth
                        size="small" />
                </Box>

                {customDateDialog &&
                    <Box sx={{ minWidth: 180, maxWidth: 220 }}>
                        <CustomDatePicker
                            label="Select Date"
                            fieldName="reservation_date"
                            value={customDate || ""}
                            onChange={(newValue, formattedDate) => {
                                setCustomDate(formattedDate);
                            }}
                            sx={{ maxWidth: 200 }}
                            fullWidth
                        />
                    </Box>
                }
            </Stack>
            <ResponsiveMasonry
                columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 4 }}
                style={{
                    height: "76vh",
                    overflowY: "auto",
                    marginTop: "18px",
                    paddingRight: "12px",
                }}
            >
                <Masonry gutter="12px">
                    {ordersList.map((order, o_idx) => {
                        return (<POSOrderTableCard key={o_idx} order={order} orderType={"ALL"} />)
                    })}
                </Masonry>

                <Stack width={"100%"} display={"flex"} justifyContent={"center"} alignItems={"center"} my={2}>
                    <CustomButton
                        variant="contained"
                        color="primary"
                        sx={{
                            maxWidth: 150,
                            fontWeight: 700
                        }}
                        onClick={handleLoadMoreButtonClick}
                        disabled={ordersLoading === LoadState.pending || posOrdersList.length < pagination.rowsPerPage}
                    >
                        {ordersLoading === LoadState.pending ? "Loading..." : "Load More"}
                    </CustomButton>
                </Stack>
            </ResponsiveMasonry>
        </PageContainer>
    );
};
