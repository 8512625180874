import { ACCESSIBLE_MUDULE_KEYS } from "../../constants/enum";
import { AuthorizedRoute } from "../../router/AuthorizedRoute";
import { ReservationsV2 } from "./ReservationsV2";
import { RESERVATIONS_V2_ROUTE } from "./ReservationsV2.constants";


export const reservationsV2Routes = [
    {
        path: RESERVATIONS_V2_ROUTE,
        exact: true,
        element: <AuthorizedRoute moduleIds={[ACCESSIBLE_MUDULE_KEYS.RESERVATION]}><ReservationsV2 /></AuthorizedRoute>
    },
] 