import { createAsyncThunk } from "@reduxjs/toolkit";
import { IQueryParams, getSearchQuery } from "../../../common";
import { axios_api } from "../../../api/axios-api";
import { IProduct } from "./products.types";
import { GET_PRODUCT_BASE_URL, UPSERT_PRODUCT_BASE_URL } from "../../../api/axios-api.endpoints";
import { uploadSingleFileAsync } from "../../../helpers/uploadFilesAsync";
import { closeLoaderWithMessage, openLoaderWithMessage } from "../../common";


export const fetchPublicProductsListAsync = createAsyncThunk<{ recordCount: number, data: IProduct[] }, string>(
    'products/fetchPublicProductsListAsync', async (uuid) => {
        const response = await axios_api.get(`/inventory/get-public-product?outlet_uuid=${uuid}`)
        const data = response.data.data;
        const count = response.data.totalRecords
        return { recordCount: count, data: data }
    },
)


export const fetchProductListAsync = createAsyncThunk<{ recordCount: number, data: IProduct[] }, string>(
    'products/fetchProductListAsync', async (uuid) => {
        const response = await axios_api.get(`${GET_PRODUCT_BASE_URL}?outlet_uuid=${uuid}`)
        const data = response.data.data;
        const count = response.data.totalRecords
        return { recordCount: count, data: data }
    },
)


export const fetchProductListWithArgsAsync = createAsyncThunk<{ recordCount: number, data: IProduct[] }, IQueryParams>(
    'products/fetchProductListWithArgsAsync', async (queryParams: IQueryParams, thunkAPI) => {
        const searchQuery = getSearchQuery(queryParams);
        const response = await axios_api.get(`${GET_PRODUCT_BASE_URL}${searchQuery}`)
        const data = response.data.data;
        const count = response.data.totalRecords
        return { recordCount: count, data: data }
    },
)

export const fetchSingleProductAsync = createAsyncThunk<IProduct, string>(
    'products/fetchSingleProductCategoryAsync', async (uuid: string, thunkAPI) => {
        const response = await axios_api.get(`${GET_PRODUCT_BASE_URL}?product_uuid=${uuid}`)
        const data = response.data.data[0];
        return data
    },
)

interface IUpsertProduct extends IProduct {
    file: File | null,
}
export const upsertSingleProductAsync = createAsyncThunk<IProduct, IUpsertProduct>(
    'product-categories/upsertSingleProductAsync', async (payload: IUpsertProduct, thunkAPI) => {
        const { file, create_ts, insert_ts, ...restPayload } = payload
        thunkAPI.dispatch(openLoaderWithMessage("Saving Changes...!"))
        if (file) {
            const path = await uploadSingleFileAsync({
                file,
                moduleName: "INVENTORY",
                asPayload: {
                    product_name: payload.product_name,
                    file_name: file.name
                }
            })
            restPayload.product_image = path
        }

        const response = await axios_api.post(UPSERT_PRODUCT_BASE_URL, restPayload)
        thunkAPI.dispatch(closeLoaderWithMessage())
        return response.data
    },
)