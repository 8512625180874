import React from "react";
import { OutletContext } from "./OutletContext.context";


export function useOutletContext() {
    const context = React.useContext(OutletContext);
  
    if (!context) {
      throw new Error('useOutletContext: Context must be used inside AuthProvider');
    }
  
    return context;
  }