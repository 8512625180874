import { LoadState } from "../../../constants/enum";
import { ICompanyOutlet, ICompanyOutletState } from "./company-outlets.types";


export const defaultOutlet: ICompanyOutlet = {
    outlet_uuid: null,
    outlet_code: null,
    outlet_name: null,
    company_uuid: null,
    outlet_logo: null,
    outlet_address: "",
    outlet_email: "",
    outlet_phone_number: "",
    outlet_gsthst_number: "",
    outlet_opening_time: null,
    outlet_closing_time: null,
    default_tax_rate: "13",
    outlet_currency: "USD",
    business_hours: {
        monday: {
            is_closed: 1,
            open_24_hours: 0,
            opening_hours: [
                {
                    opens_at: null,
                    closed_at: null
                }
            ]

        },
        tuesday: {
            is_closed: 1,
            open_24_hours: 0,
            opening_hours: [
                {
                    opens_at: null,
                    closed_at: null
                }
            ]
        },
        wednesday: {
            is_closed: 1,
            open_24_hours: 0,
            opening_hours: [
                {
                    opens_at: null,
                    closed_at: null
                }
            ]
        },
        thursday: {
            is_closed: 1,
            open_24_hours: 0,
            opening_hours: [
                {
                    opens_at: null,
                    closed_at: null
                }
            ]
        },
        friday: {
            is_closed: 1,
            open_24_hours: 0,
            opening_hours: [
                {
                    opens_at: null,
                    closed_at: null
                }
            ]
        },
        saturday: {
            is_closed: 1,
            open_24_hours: 0,
            opening_hours: [
                {
                    opens_at: null,
                    closed_at: null
                }
            ]
        },
        sunday: {
            is_closed: 1,
            open_24_hours: 0,
            opening_hours: [
                {
                    opens_at: null,
                    closed_at: null
                }
            ]
        },
    },

    status: "ACTIVE"
}


export const defaultCompanyOutletState: ICompanyOutletState = {
    outlets_list: {
        loading: LoadState.idle,
        data: [],
        count: 0,
        error: null,
    },
    single_outlet: {
        loading: LoadState.idle,
        data: defaultOutlet,
        error: null,
    },
    current_outlet: {
        loading: LoadState.idle,
        data: defaultOutlet,
        error: null,
    }
}