import React from "react";
// import { DataTable, ITableColumn } from "../../Components/Tables/Table";
import { Avatar, Button, Typography } from "@mui/material";
import {
    IDataTableProps,
    MobileLogoRenderType,
    RenderType,
} from "../../Components/Table/interfaces/IDataTableProps";
import { DataTable } from "../../Components/Table/DataTable";
import { IStoreState, useAppDispatch, useAppSelector } from "../../redux";
import { useNavigate } from "react-router-dom";
import { StandardTableActions } from "../../Components/StandardTableActions/StandardTableActions";
import { CustomButton } from "../../Components/CustomButton";
import {
    IPaymentReceived,
    clearPaymentReceivedStateSync,
    defaultPaymentReceived,
    fetchPaymentReceivedListWithArgsAsync,
} from "../../redux/accounting";
import { LoadState } from "../../constants/enum";
import { useAuthProvider, useOutletContext } from "../../providers";
import PageContainer from "../../Components/PageContainer/PageContainer";

export const PaymentHistoryTable = () => {
    const { user_uuid } = useAuthProvider()
    const { outlet_details: { outlet_uuid } } = useOutletContext()
    const {
        data: payments_received_list,
        totalRecords,
        loading,
    } = useAppSelector(
        (storeState: IStoreState) =>
            storeState.accounting.paymentReceived.payments_received_list
    );

    const [pagination, setPagination] = React.useState({
        pageNumber: 1,
        rowsPerPage: 10,
    });

    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const [openUpsertDialog, setOpenUpsertDialog] =
        React.useState<IPaymentReceived | null>(null);

    const dataTableProps: IDataTableProps = {
        isPagination: true,
        totalRecords: totalRecords,
        rowsPerPageOptions: pagination.rowsPerPage,
        isDataLoading: loading !== LoadState.succeeded,
        mobileLogo: {
            type: MobileLogoRenderType.reactNode,
            onMobileLogoRender: (data: any) => {
                return (
                    <Avatar
                        sx={{
                            width: 45,
                            height: 45,
                            color: "#fff",
                            ml: "-8px",
                            mr: 2,
                        }}
                    ></Avatar>
                );
            },
        },
        initialSortConfig: {
            insert_ts: {
                direction: "desc",
                filedName: "insert_ts",
            },
        },
        columns: [
            {
                key: "view",
                headerName: "Actions",
                fieldName: "",
                headingAlign: "center",
                renderType: RenderType.CUSTOM_RENDER,
                onRowCellRender: (value, row: IPaymentReceived) => {
                    return (
                        <StandardTableActions
                            onEditClick={() => setOpenUpsertDialog(row)}
                        />
                    );
                },
            },
            {
                key: "order_type",
                headerName: "Order Type",
                fieldName: "order_type",
                enableSorting: true,
                renderType: RenderType.CUSTOM_RENDER,
            },
            {
                key: "order_number",
                headerName: "Order Number",
                fieldName: "order_number",
                enableSorting: true,
                renderType: RenderType.CUSTOM_RENDER,
            },
            {
                key: "invoice_number",
                headerName: "Invoice Number",
                fieldName: "invoice_number",
                enableSorting: true,
                renderType: RenderType.CUSTOM_RENDER,
            },
            {
                key: "total_tax",
                headerName: "Total Tax",
                fieldName: "total_tax",
                enableSorting: true,
                renderType: RenderType.CUSTOM_RENDER,
            },
            {
                key: "total_amount",
                headerName: "Total Amount",
                fieldName: "total_amount",
                enableSorting: true,
                renderType: RenderType.CUSTOM_RENDER,
            },
            {
                key: "order_status",
                headerName: "Order Status",
                fieldName: "order_status",
                enableSorting: true,
                renderType: RenderType.CUSTOM_RENDER,
            },

            {
                key: "view",
                headerName: "View",
                fieldName: "",
                renderType: RenderType.CUSTOM_RENDER,
                onRowCellRender: (value, row: IPaymentReceived) => {
                    return <Button variant="contained">View Invoice</Button>;
                },
            },
        ],

        items: payments_received_list,
        onPageChange: (newPageNumber: number) => {
            setPagination({ ...pagination, pageNumber: newPageNumber });
        },
        onRowsPerPageChange: (pageNumber: number, rowsPerPage: number) => {
            setPagination({
                pageNumber: pageNumber,
                rowsPerPage: rowsPerPage,
            });
        },
    };

    React.useEffect(() => {
        fetchList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pagination, outlet_uuid, user_uuid]);

    React.useEffect(() => {
        return () => {
            dispatch(clearPaymentReceivedStateSync());
        };
    }, []);

    const fetchList = () => {
        if (!outlet_uuid || !user_uuid) return;
        dispatch(
            fetchPaymentReceivedListWithArgsAsync({
                user_uuid: user_uuid,
                outlet_uuid: outlet_uuid,
                page: pagination.pageNumber,
                rowsPerPage: pagination.rowsPerPage,
            })
        );
    };

    return (
        <PageContainer title="Payment history" description="Payment history">
            <Typography variant="h2" mb={2}>Payment history</Typography>
            <DataTable {...dataTableProps} />
        </PageContainer>
    );
};
