import { Box } from '@mui/material';
import React from 'react'
import { MenuMenuCategoryCard } from './MenuCategoryCard';
import { IStoreState, useAppDispatch, useAppSelector } from '../../../../redux';
import { OutlinedTextField } from '../../../../Components/formComponents';
import { ICategory, fetchCategoriesListAsync, selectSingleCategorySync } from '../../../../redux/inventory';
import { useOutletContext } from '../../../../providers';


export const MenuCategoryList = () => {
    const { outlet_details: { outlet_uuid } } = useOutletContext()
    const dispatch = useAppDispatch();

    const {
        data: pos_categories_list,
    } = useAppSelector((storeState: IStoreState) => storeState.inventory.categories.category_list);
    const selectedCategory = useAppSelector((storeState: IStoreState) => storeState.inventory.categories.selected_category);

    const [searchText, setSearchText] = React.useState("")
    const [categoryList, setCategoryList] = React.useState<ICategory[]>([])

    React.useEffect(() => {
        if (!outlet_uuid) return
        dispatch(fetchCategoriesListAsync(outlet_uuid))
    }, [outlet_uuid])

    React.useEffect(() => {
        setCategoryList(pos_categories_list)
    }, [pos_categories_list])


    React.useEffect(() => {
        if (searchText.length > 0) {
            const newList = pos_categories_list.filter((item) => item.category_name.toLowerCase().includes(searchText.toLowerCase()))
            setCategoryList(newList)
        } else (
            setCategoryList(pos_categories_list)
        )
    }, [searchText, pos_categories_list])


    const handleCategorySelect = (category: ICategory) => {
        dispatch(selectSingleCategorySync(selectedCategory === category.category_uuid ? null : category.category_uuid))
    }

    return (
        <>
            <OutlinedTextField
                label="Search Category"
                id="account_name"
                value={searchText}
                onChange={(evt) => setSearchText(evt.target.value)}
            />

            <Box
                sx={{
                    height: "77vh",
                    display: "flex",
                    flexDirection: "column",
                    gap: 1,
                    overflowY: "auto",
                    pr: 1,
                    mt: 2
                }}
            >

                {categoryList.map((item, index) => {
                    return (
                        <MenuMenuCategoryCard
                            key={index}
                            data={item}
                            isActive={item.category_uuid === selectedCategory}
                            onClick={() => handleCategorySelect(item)}
                        />
                    );
                })}
            </Box>
        </>

    )
}