import React from "react";
import { TabPanel } from "../../Components/Tab/TabPanel";
import { IInventoryProps } from "./interface/Inventory.types";
import { TabsList } from "../../Components/Tab/Tab";
import { useNavigate } from "react-router-dom";
import { CustomButton } from "../../Components/CustomButton";
import { ProductsTable } from "./Products/ProductsTable";
import { CategoriesTable } from "./Category/CategoriesTable";
import { CustomizationsTable } from "./Customization/CustomizationsTable";
import PageContainer from "../../Components/PageContainer/PageContainer";
import { UpsertCategoryDialog } from "./Category/dialogs/UpsertCategoryDialog";
import { defaultInventory, defaultCategory, defaultProductCustomization } from "../../redux/inventory";
import { InventoryTable } from "./InventoryList/InventoryTable";
import { UpsertInventoryDialog } from "./InventoryList/dialogs/UpsertInventoryDialog";
import { CATEGORIES_ROUTE, CUSTOMIZATION_ROUTE, INVENTORY_ROUTE, PRODUCTS_ROUTE } from "./Inventory.constants";
import { ManageCustomizationDialog } from "./Customization/dialogs/ManageCustomizationDialog";
import { useOutletContext } from "../../providers";

export const Inventory: React.FC<IInventoryProps> = (props) => {
  const { activeTab } = props;
  const { outlet_details: { outlet_uuid, outlet_name } } = useOutletContext()

  const navigate = useNavigate();
  const [tab, setTab] = React.useState(activeTab || 0);
  const [categoryDialogOpen, setCategoryDialogOpen] =
    React.useState<boolean>(false);
  const [inventoryListDialogOpen, setInventoryListDialogOpen] =
    React.useState<boolean>(false);
  const [customizationDialogOpen, setCustomizationDialogOpen] =
    React.useState<boolean>(false);

  const handleTabChange = (url: string) => {
    navigate(url);
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue);
    if (newValue === 0) handleTabChange(PRODUCTS_ROUTE);
    if (newValue === 1) handleTabChange(CATEGORIES_ROUTE);
    if (newValue === 2) handleTabChange(CUSTOMIZATION_ROUTE);
    if (newValue === 3) handleTabChange(INVENTORY_ROUTE);
  };

  const handleAddNew = () => {
    if (tab === 0) handleTabChange(PRODUCTS_ROUTE + "/manage");
    if (tab === 1) setCategoryDialogOpen(true);
    if (tab === 2) setCustomizationDialogOpen(true);
    if (tab === 3) setInventoryListDialogOpen(true);
  };

  return (
    <PageContainer title="Inventory" description="inventory page" sx={{ paddingInline: 2 }} >
      <TabsList
        selectedTab={tab}
        tabData={[
          { tabLabel: "Products", tabLink: PRODUCTS_ROUTE },
          { tabLabel: "Categories", tabLink: CATEGORIES_ROUTE },
          { tabLabel: "Customizations", tabLink: CUSTOMIZATION_ROUTE },
          { tabLabel: "Inventory List", tabLink: INVENTORY_ROUTE },
        ]}
        onChange={handleChange}
      />

      <CustomButton
        variant="contained"
        color="primary"
        sx={{
          display: "flex",
          marginLeft: "auto",
          marginY: 2,
        }}
        onClick={handleAddNew}
      >
        Add New
      </CustomButton>
      <TabPanel value={tab} index={0}>
        <ProductsTable />
      </TabPanel>
      <TabPanel value={tab} index={1}>
        <CategoriesTable />
      </TabPanel>
      <TabPanel value={tab} index={2}>
        <CustomizationsTable />
      </TabPanel>
      <TabPanel value={tab} index={3}>
        <InventoryTable />
      </TabPanel>

      {categoryDialogOpen && (
        <UpsertCategoryDialog
          open={true}
          category={{ ...defaultCategory, outlet_uuid, outlet_name }}
          onClose={() => setCategoryDialogOpen(false)}
        />
      )}

      {inventoryListDialogOpen && (
        <UpsertInventoryDialog
          open={true}
          inventory={defaultInventory}
          onClose={() => setInventoryListDialogOpen(false)}
        />
      )}
      {customizationDialogOpen && (
        <ManageCustomizationDialog
          open={true}
          customization={defaultProductCustomization}
          onClose={() => setCustomizationDialogOpen(false)}
        />
      )}
    </PageContainer>
  );
};
