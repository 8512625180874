import { FullLayout } from "../layout/FullLayout/FullLayout";
import { authenticationRoutes } from "../views/Authentication/Authentication.router";
import { ProtectedRoute } from "./ProtectedRoutes";
import { inventoryRoutes } from "../views/Inventory/Inventory.router";
import { tablesRoutes } from "../views/Tables/Tables.router";
import { accountingRoutes } from "../views/Accounting/Accounting.router";
import { posOrderRoutes } from "../views/MenuAndOrders/MenuAndOrders.router";
import { shiftAndPunchInOutRoutes } from "../views/ShiftAndPunchInOut/ShiftAndPunchInOut.router";
import { settingRoutes } from "../views/Settings";
import { marketingsRoutes } from "../views/Marketing";
import { reservationsRoutes } from "../views/Reservations/Reservations.router";
import { Navigate, useRoutes } from "react-router-dom";
import { uberEatsRoutes } from "../views/UberEats/UberEats.router";
import { PageNotFound } from "./PageNotFound";
import { dashboardV2Routes } from "../views/DashboardV2/DashboardV2.router";
import { APP_DASHBOARD_V2_ROUTE } from "../views/DashboardV2/DashboardV2.contants";
import { reservationsV2Routes } from "../views/ReservationsV2/ReservationsV2.router";
import { publicDahboardRoutes } from "../public-view/Public.router";
import { userViewRoutes } from "../user-views";
import { outletQrCodeRoutes } from "../views/OutletQrCode/OutletQrCode.router";

export const getAppRoutes = (isUserView: boolean) => {
  return [
    {
      path: "*",
      exact: true,
      element: <PageNotFound />,
    },
    {
      path: "/",
      exact: true,
      element: <ProtectedRoute><FullLayout isUserView={isUserView} /></ProtectedRoute>,
      children: [
        {
          path: "/",
          exact: true,
          element: <Navigate to={APP_DASHBOARD_V2_ROUTE} replace />,
        },
      ].concat(dashboardV2Routes)
        .concat(inventoryRoutes)
        .concat(tablesRoutes)
        .concat(shiftAndPunchInOutRoutes)
        .concat(accountingRoutes)
        .concat(posOrderRoutes())
        .concat(settingRoutes)
        .concat(marketingsRoutes)
        .concat(reservationsRoutes)
        .concat(uberEatsRoutes).concat(reservationsV2Routes).concat(userViewRoutes).concat(outletQrCodeRoutes)
    },

    // --------------------------------AUTH ROUTING----------------------------------------------
    ...authenticationRoutes,
    ...publicDahboardRoutes,
  ]
}
