import React, { useState } from "react";
import { StandardCard } from "../../../../Components/Card";
import { CustomSquareButton } from "../../../../Components/CustomButton";
import {
  Box,
  Chip,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { CustomDivider } from "../../../../Components/CustomDivider/CustomDivider";
import { POS_ORDERS_ROUTE, POS_APP_MENU_ROUTE_NEW_ORDER } from "../../MenuAndOrders.constants";
import Edit from "@mui/icons-material/Edit";
import { Print } from "@mui/icons-material";
import Visibility from "@mui/icons-material/Visibility";
import { OrderPrintDialog } from "../OrderPrintDialog";
import { IOrder } from "../../../../redux/orders";
import { useNavigate } from "react-router-dom";
import DoneIcon from '@mui/icons-material/Done';
import { useOutletContext } from "../../../../providers";

interface IOrderTableCardProps {
  order: IOrder;
  orderType: string
}
export const POSOrderTableCard: React.FC<IOrderTableCardProps> = ({
  order,
  orderType
}) => {
  const { OUTLET_CURRENCY_SYMBOL } = useOutletContext()
  let navigate = useNavigate()
  const [openPrintDialog, setOpenPrintDialog] = useState(false)

  const handleViewOrderClick = () => {
    navigate(`${POS_ORDERS_ROUTE}/view/${order.order_uuid}`)
  }

  const handleEditOrderClick = () => {
    navigate(`${POS_APP_MENU_ROUTE_NEW_ORDER}/edit/${order.order_uuid}`)
  }

  const handleCheckOutClick = () => {
    navigate(`${POS_APP_MENU_ROUTE_NEW_ORDER}/edit/${order.order_uuid}`)
  }

  const handlePrintOrderClick = () => {
    setOpenPrintDialog(true)
  }


  if (orderType === "TAKE_AWAY") {
    return <StandardCard
      heading={order.order_type === "TAKE_AWAY" ? "Take-Away" : "Dine-In"}
      rightHeading={
        <Typography variant="h3" fontWeight={"bold"}>{`Order No. ${order.order_number}`}</Typography>}
      sx={{
        display: "block",
        pageBreakInside: "avoid",
        breakInside: "avoid",
        position: 'relative'
      }}
    >
      {order.is_paid === 1 && <Chip
        sx={{ position: "absolute", background: "green", top: -2, right: 0, borderRadius: .25, height: 22 }}
        label="Paid"
        size="small"
        onClick={() => { }}
        onDelete={() => { }}
        deleteIcon={<DoneIcon />}
      />}
    </StandardCard>
  }



  if (orderType === "KITCHEN") {
    return <StandardCard
      heading={order.order_type === "TAKE_AWAY" ? "Take-Away" : "Dine-In"}
      rightHeading={`Order No. ${order.order_number}`}
      sx={{
        // mt: 2,
        display: "block",
        pageBreakInside: "avoid",
        breakInside: "avoid",
        // width: "32%"
        position: 'relative'
      }}
    >
      {order.is_paid === 1 && <Chip
        sx={{ position: "absolute", background: "green", top: -2, right: 0, borderRadius: .25, height: 22 }}
        label="Paid"
        size="small"
        onClick={() => { }}
        onDelete={() => { }}
        deleteIcon={<DoneIcon />}
      />}
      <CustomDivider borderColor="white" />

      <Table>
        <TableHead>
          <TableCell
            sx={{
              border: "none",
              p: 1,
            }}
          >
            <Typography fontWeight={"bold"}>QT</Typography>
          </TableCell>
          <TableCell
            sx={{
              border: "none",
              p: 1,
            }}
          >
            <Typography fontWeight={"bold"} textAlign={"left"}>Items</Typography>
          </TableCell>
          <TableCell
            sx={{
              border: "none",
              p: 1,
            }}
          >
            <Typography fontWeight={"bold"}>Notes</Typography>
          </TableCell>
        </TableHead>
        <TableBody sx={{ minHeight: 300 }}>
          {(order.menu_items || []).map((order_item, Item_idx) => {
            return (
              <>
                <TableRow>
                  <TableCell
                    sx={{
                      border: "none",
                      p: 1,
                    }}
                  >
                    <Typography fontWeight={"bold"}>{order_item.quantity}</Typography>
                  </TableCell>
                  <TableCell
                    sx={{
                      border: "none",
                      p: 1,
                    }}
                  >
                    <Typography fontWeight={"bold"} textAlign={"left"}>{order_item.product_name}</Typography>
                  </TableCell>
                  <TableCell
                    sx={{
                      border: "none",
                      p: 1,
                    }}
                  >
                    <Typography >{order_item.notes}</Typography>
                  </TableCell>
                </TableRow>
                {(order_item.options || []).map((option, option_idx) => {
                  return (
                    <>
                      <TableRow>
                        <TableCell
                          sx={{
                            border: "none",
                            p: 1,
                            py: 0.25
                          }}
                        >
                          {/* <Typography fontWeight={"bold"}>{Item_idx + 1}</Typography> */}
                        </TableCell>
                        <TableCell
                          sx={{
                            border: "none",
                            p: 1,
                            py: 0.25
                          }}
                        >
                          <Typography textAlign={"left"}>{`${option.modifier_name} [add-onn]`}</Typography>
                        </TableCell>
                        <TableCell
                          sx={{
                            border: "none",
                            p: 1,
                            py: 0.25
                          }}
                        >
                          <Typography fontWeight={"bold"}  ></Typography>
                        </TableCell>
                      </TableRow>
                    </>
                  )
                })}
              </>
            )
          })}
        </TableBody>
      </Table>

      <CustomDivider borderColor="grey" />


      <Stack gap={1} direction={"row"}>
        <CustomSquareButton title="Ready To Serve" />
      </Stack>
    </StandardCard>
  }


  return (
    <StandardCard
      heading={
        <Box sx={{ display: "flex", flexDirection: 'column', alignItems: 'flex-start' }}>
          <Typography variant="h3" fontWeight={"700"}>
            {order.order_type === "TAKE_AWAY" ? "Take-Away" : "Dine-In"}
          </Typography>
          <Typography variant="h5">
            {`Order No. ${order.order_number}`}
          </Typography>

        </Box>
      }
      rightHeading={
        order.is_paid === 1 && <Chip
          sx={{ background: "green", borderRadius: 1, height: 30, fontSize: 18, fontWeight: 600 }}
          label="PAID"
          size="small"
          onClick={() => { }}
          onDelete={() => { }}
          deleteIcon={<DoneIcon sx={{ height: 30, width: 30, color: "#fff !important" }} />}
        />
      }
      sx={{
        display: "block",
        pageBreakInside: "avoid",
        breakInside: "avoid",
      }}
    >

      <CustomDivider borderColor="white" />

      <Table>
        <TableHead>
          <TableCell
            sx={{
              border: "none",
              p: 0.5,
              width: "10%",
              display: "flex",
              alignItems: 'flex-start'
            }}
          >
            <Typography fontWeight={"bold"}>QT</Typography>
          </TableCell>
          <TableCell
            sx={{
              border: "none",
              p: 0.5,
              width: "60%",
            }}
          >
            <Typography fontWeight={"bold"} textAlign={"left"}>Items</Typography>
          </TableCell>
          <TableCell
            sx={{
              border: "none",
              p: 0.5,
              display: "flex",
              alignItems: 'flex-start',
              justifyContent: 'flex-end'
            }}
          >
            <Typography fontWeight={"bold"}>Price</Typography>
          </TableCell>
        </TableHead>
        <TableBody sx={{ minHeight: 300 }}>
          {(order.menu_items || []).map((order_item, Item_idx) => {
            return (
              <>
                <TableRow>
                  <TableCell
                    sx={{
                      border: "none",
                      p: 0.5,
                      width: "10%",
                      display: "flex",
                      alignItems: 'flex-start'
                    }}
                  >
                    <Typography fontWeight={"bold"}>{order_item.quantity}</Typography>
                  </TableCell>
                  <TableCell
                    sx={{
                      border: "none",
                      p: 0.5,
                      width: "60%",
                    }}
                  >
                    <Typography fontWeight={"bold"} textAlign={"left"}>{order_item.product_name}</Typography>
                    {order_item.notes && <Typography textAlign={"left"} fontStyle="italic">{order_item.notes}</Typography>}
                  </TableCell>
                  <TableCell
                    sx={{
                      border: "none",
                      p: 0.5,

                      display: "flex",
                      flexDirection: 'row',
                      alignItems: 'flex-start',
                      justifyContent: 'flex-end'
                    }}
                  >
                    <Typography fontWeight={"bold"}>{`${OUTLET_CURRENCY_SYMBOL}${order_item.product_price}`}</Typography>
                  </TableCell>
                </TableRow>
                {(order_item.options || []).map((option, option_idx) => {
                  return (
                    <>
                      <TableRow>
                        <TableCell
                          sx={{
                            border: "none",
                            p: 0.5,
                            width: "10%",
                            display: "flex",
                            alignItems: 'flex-start'
                          }}
                        >
                          {/* <Typography fontWeight={"bold"}>{Item_idx + 1}</Typography> */}
                        </TableCell>
                        <TableCell
                          sx={{
                            border: "none",
                            p: 0.5,
                          }}
                        >
                          <Typography textAlign={"left"}>{`${option.quantity} X ${option.modifier_name} [add-on]`}</Typography>
                        </TableCell>
                        <TableCell
                          sx={{
                            border: "none",
                            p: 0.5,
                            display: "flex",
                            alignItems: 'flex-start',
                            justifyContent: 'flex-end'
                          }}
                        >
                          <Typography fontWeight={"bold"}  >{`${OUTLET_CURRENCY_SYMBOL}${option.modifier_price}`}</Typography>
                        </TableCell>
                      </TableRow>
                    </>
                  )
                })}
              </>
            )
          })}
          <TableRow sx={{ mt: 4,  borderTop: "2px solid grey", }}>
            <TableCell
              sx={{
                border: "none",
                p: 0.5,
                width: "20%"
              }}
            >

            </TableCell>
            <TableCell
              sx={{
                border: "none",
                p: 0.5,
                width: "60%"
              }}
            >
              <Typography fontWeight={"bold"}>Subtotal</Typography>
            </TableCell>
            <TableCell
              sx={{
                border: "none",
                p: 0.5,
                display: "flex",
                alignItems: 'flex-start',
                justifyContent: 'flex-end'
              }}
            >
              <Typography fontWeight={"bold"}>{`${OUTLET_CURRENCY_SYMBOL}${order.sub_total || 0}`}</Typography>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>

      {/* <CustomDivider borderColor="grey" />
      <Stack
        direction={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
        mb={4}
      >
        <Typography fontWeight={"bold"}>Subtotal</Typography>
        <Typography fontWeight={"bold"}>{`${OUTLET_CURRENCY_SYMBOL}${order.sub_total}`}</Typography>
      </Stack> */}

      <Stack gap={1} direction={"row"}>
        {/* <CustomSquareButton icon={<RiDeleteBin7Line fontSize={"1.5rem"} />} />
        <CustomSquareButton icon={<FiEdit3 fontSize={"1.5rem"} />} /> */}
        {<CustomSquareButton icon={<Edit />} onClick={handleEditOrderClick} />}
        <CustomSquareButton icon={<Visibility />} onClick={handleViewOrderClick} />
        <CustomSquareButton icon={<Print />} onClick={handlePrintOrderClick} />
        {!order.is_paid && <CustomSquareButton disabled={order.is_paid === 1} title="Checkout" onClick={handleCheckOutClick} />}
      </Stack>

      {openPrintDialog && <OrderPrintDialog
        open={openPrintDialog}
        data={{
          order_uuid: order.order_uuid || "",
          outlet_uuid: order.outlet_uuid || "",
          order_type: "ORDER"
        }}
        onClose={() => setOpenPrintDialog(false)} />}
    </StandardCard>
  );
};
