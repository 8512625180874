import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';

// import { CONFIG } from 'src/config-global';
import { _bankingContacts, _bankingCreditCard, _bankingRecentTransitions } from './_mock';

import { Iconify } from './components/iconify';

import { BankingContacts } from './sections/banking-contacts';
import { BankingOverview } from './sections/banking-overview';
import { BankingQuickTransfer } from './sections/banking-quick-transfer';
import { BankingInviteFriends } from './sections/banking-invite-friends';
import { BankingCurrentBalance } from './sections/banking-current-balance';
import { BankingBalanceStatistics } from './sections/banking-balance-statistics';
import { BankingRecentTransitions } from './sections/banking-recent-transitions';
import { BankingExpensesCategories } from './sections/banking-expenses-categories';
import { MUI_MINIMAL_THEME_IMAGES_DIR } from '../../constants/constants';
import { QuickOrdersVIew } from './sections/QuickOrdersVIew';
import { IStoreState, useAppDispatch, useAppSelector } from '../../redux';
import React from 'react';
import { fetchDashboardExpenseCatgoriesAsync } from '../../redux/analytics/analytics.actions';
import { ExpenseCategories } from './sections/ExpenseCategories';
import { useOutletContext } from '../../providers';

// ----------------------------------------------------------------------

export function DashboardV2() {
 const {OUTLET_CURRENCY_SYMBOL} = useOutletContext()
 

  return (
    <>
      <Grid container spacing={3}>
        <Grid xs={12} md={7} lg={8}>
          <Box sx={{ gap: 3, display: 'flex', flexDirection: 'column' }}>
            <BankingOverview />
            <ExpenseCategories/>
            <BankingRecentTransitions
              title="Recent transitions"
              tableData={_bankingRecentTransitions}
              headLabel={[
                { id: 'description', label: 'Description' },
                { id: 'date', label: 'Date' },
                { id: 'amount', label: 'Amount' },
                { id: 'status', label: 'Status' },
                { id: '' },
              ]}
            />
          </Box>
        </Grid>

        <Grid xs={12} md={5} lg={4}>
          <Box sx={{ gap: 3, display: 'flex', flexDirection: 'column' }}>
            {/* <BankingCurrentBalance list={_bankingCreditCard} /> */}

            <QuickOrdersVIew />

            <BankingContacts
              title="Users"
              subheader="You have 122 contacts"
              list={_bankingContacts.slice(-5)}
            />

            <BankingInviteFriends
              price={`${OUTLET_CURRENCY_SYMBOL}50`}
              title={`Invite friends \n and earn`}
              description="Praesent egestas tristique nibh. Duis lobortis massa imperdiet quam."
              // imgUrl={`/assets/illustrations/illustration-receipt.webp`}
              imgUrl={`${MUI_MINIMAL_THEME_IMAGES_DIR}/assets/illustrations/illustration-receipt.webp`}
            />
          </Box>
        </Grid>
      </Grid>
    </>
  );
}
