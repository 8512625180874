import React from 'react'
import { IStoreState, useAppDispatch, useAppSelector } from '../../../../redux';
import { Box, Button, Link, Stack, Typography } from '@mui/material';
import { StandardCard } from '../../../../Components/Card';
import { FaHourglassEnd } from 'react-icons/fa';
import { CustomRoundButton } from '../../../../Components/CustomButton';
import { useAuthProvider, useOutletContext } from '../../../../providers';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useFormik } from 'formik';
import { POS_ORDERS_ROUTE } from '../../MenuAndOrders.constants';
import { IOrder, fetchSingleOrderAsync, upsertSingleOrderWithCallbackAsync } from '../../../../redux/orders';
import { isNotNull } from '../../../../helpers/isNotNull';
import { OrderItemCard } from './components/OrderItemCard';
import { OrderPaymentDialog } from './dialogs/OrderPaymentDialog/OrderPaymentDialog';
import { ExtraChargesDialog } from './dialogs/ExtraChargesDialog/ExtraChargesDialog';

export const MenuOrderLayout = () => {
    const { uuid: order_uuid } = useParams<{ uuid: string }>();
    const [searchParams] = useSearchParams();
    const { OUTLET_CURRENCY_SYMBOL, outlet_details: { outlet_uuid, outlet_name } } = useOutletContext()
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { authInfo } = useAuthProvider();

    const {
        data: singleOrderState,
    } = useAppSelector((storeState: IStoreState) => storeState.orders.single_order);

    const [openPrintDialog, setOpenPrintDialog] = React.useState<IOrder | null>(null);
    const [openPaymentDialog, setOpenPaymentDialog] = React.useState<IOrder | null>(null);
    const [openExtraChargesDialog, setOpenExtraChargesDialog] = React.useState<IOrder | null>(null);
    const [saveLoading, setSaveLoading] = React.useState(false);

    const {
        values,
        errors,
        setValues,
        isSubmitting,
        setSubmitting,
        handleChange,
        handleSubmit,
        setFieldValue,
    } = useFormik({
        initialValues: { ...singleOrderState },
        validate: (values) => {
            const errors: any = {};
            return errors;
        },
        onSubmit: async (values) => {
            dispatch(upsertSingleOrderWithCallbackAsync({
                payload: values,
                onSuccess(isSuccess, data) {
                    if (isSuccess && data) {
                        navigate(`${POS_ORDERS_ROUTE}`)
                    }
                },
            })).finally(() => {
                setSubmitting(false);
            });
        },
    });

    React.useEffect(() => {
        if (!order_uuid) return;
        dispatch(fetchSingleOrderAsync(order_uuid));
    }, [order_uuid]);

    React.useEffect(() => {
        if (order_uuid) {
            setValues({ ...singleOrderState })
        } else {
            setValues({
                ...singleOrderState,
                outlet_uuid,
                outlet_name
            })
        }
    }, [singleOrderState])

    // React.useEffect(() => {
    //     let newState = { ...singleOrderState }
    //     const tableUUID = searchParams.get('tableUUID');
    //     const tableNumber = searchParams.get('tableNumber');
    //     if (tableUUID && tableNumber) {
    //         newState = {
    //             ...newState,
    //             order_type: "DINE_IN",
    //             table_uuid: tableUUID,
    //             table_number: Number(tableNumber)
    //         }
    //     }
    //     setTimeout(() => {
    //         setValues(newState)
    //     }, 100);
    // }, [searchParams, singleOrderState])


    // React.useEffect(() => {
    //     let subTotal = 0;
    //     let totalTax = 0;
    //     values.menu_items.forEach((item) => {
    //         const currentSubTotal = isNotNull(item.product_price)
    //         totalTax += (currentSubTotal * isNotNull(item.tax_percentage)) / 100;
    //         subTotal += currentSubTotal
    //         item.options.forEach((option) => {
    //             const optionSubTotal = isNotNull(option.modifier_price)
    //             totalTax += (optionSubTotal * isNotNull(option.tax_percentage)) / 100;
    //             subTotal += optionSubTotal
    //         });
    //     });

    //     setValues({
    //         ...values,
    //         sub_total: subTotal,
    //         total_tax: Number(totalTax.toFixed(4)),
    //         total_amount: (subTotal + totalTax)
    //     })
    // }, [values.menu_items]);


    // const handelOrderAndCheckout = () => {
    //     return setOpenPaymentDialog(values)
    //     if (isCashPayMode(values.payment_mode)) return setOpenCashDialog(values)
    //     if (isCardPayMode(values.payment_mode)) return setOpenCardDialog(values)
    //     // if (isOnlinePayMode(values.payment_mode)) return setOpenOnlineDialog(values)

    // }

    const handlePaymentSuccess = () => {
        setOpenPrintDialog(openPaymentDialog)
        setOpenPaymentDialog(null)
    }

    return (
        <Box component="form" onSubmit={handleSubmit}>
            <Stack spacing={2} direction={"row"} justifyContent={"space-between"} alignItems={"flex-start"}>
                <Box>
                    <Typography variant="h4" fontWeight={"bold"} letterSpacing={0.5}>
                        {values.order_type === "TAKE_AWAY" ? "Take Away Order" : `Table ${values.table_number}`}
                    </Typography>
                    <Typography variant="h6" color={"#595959"} fontWeight={"500"}>
                        {authInfo.name}
                    </Typography>
                </Box>
                <Typography variant="h4" fontWeight={"bold"} letterSpacing={0.5}>
                    {`Order No.  ${values.order_number}`}
                </Typography>
            </Stack>
            <Box sx={{ mt: 2, height: (theme) => theme.breakpoints.up(900) ? "77vh" : "75vh" }}>
                {values?.menu_items?.length > 0 ? (
                    <>
                        <Box
                            sx={{
                                height: "55vh",
                                overflowY: "scroll",
                                pr: 1,
                            }}
                        >
                            {values.menu_items.map((item, index) => (
                                <OrderItemCard
                                    key={index}
                                    index={index + 1}
                                    product={item}
                                />
                            ))}
                        </Box>
                        <StandardCard
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "space-between",
                                height: "calc(100% - 55vh)",
                                overflowY: "scroll"
                            }}
                        >
                            <Box>
                                <Stack
                                    direction={"row"}
                                    alignItems={"center"}
                                    justifyContent={"space-between"}
                                >
                                    <Typography variant="h5" fontWeight={"bold"}>Sub Total</Typography>
                                    <Typography variant="h5" >
                                        {`${OUTLET_CURRENCY_SYMBOL}${values.sub_total}`}
                                    </Typography>
                                </Stack>
                                <Stack
                                    direction={"row"}
                                    alignItems={"center"}
                                    justifyContent={"space-between"}
                                >
                                    <Typography variant="h5" fontWeight={"bold"}>Tax</Typography>
                                    <Typography variant="h5" >
                                        {`${OUTLET_CURRENCY_SYMBOL}${values.total_tax}`}
                                    </Typography>
                                </Stack>
                                {values.extra_charges && values.extra_charges.map((e_charges, c_idx) => {
                                    if (isNotNull(e_charges.amount) <= 0) return <></>

                                    return (
                                        <>
                                            {c_idx === 0 && <Typography variant="h5" fontWeight={"bold"}>Extra Charges</Typography>}
                                            <Stack
                                                direction={"row"}
                                                alignItems={"center"}
                                                justifyContent={"space-between"}
                                            >
                                                <Typography variant="h6">{e_charges.title}</Typography>
                                                <Typography variant="h5" >
                                                    {`+${OUTLET_CURRENCY_SYMBOL}${e_charges.amount}`}
                                                </Typography>
                                            </Stack>
                                        </>
                                    )
                                })}

                                {/* <Divider /> */}
                                {values.discount_coupons && values.discount_coupons.map((coupon, c_idx) => {
                                    if (!coupon.is_valid) return <></>
                                    return (
                                        <>
                                            {c_idx === 0 && <Typography variant="h5" fontWeight={"bold"}>Coupons</Typography>}
                                            <Stack
                                                direction={"row"}
                                                alignItems={"center"}
                                                justifyContent={"space-between"}
                                            >
                                                <Typography variant="h5" fontWeight={"bold"}>{coupon.coupon_code}</Typography>
                                                <Typography variant="h5" >
                                                    {`-${OUTLET_CURRENCY_SYMBOL}${coupon.amount}`}
                                                </Typography>
                                            </Stack>
                                        </>
                                    )
                                })}
                                <Stack
                                    direction={"row"}
                                    alignItems={"center"}
                                    justifyContent={"space-between"}
                                >
                                    <Typography variant="h3" fontWeight={"bold"}>
                                        Total
                                    </Typography>
                                    <Typography variant="h3" fontWeight={"bold"}>
                                        {`${OUTLET_CURRENCY_SYMBOL}${values.total_amount}`}
                                    </Typography>
                                </Stack>
                                <Link
                                    sx={{ cursor: "pointer" }}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        setOpenExtraChargesDialog(values)
                                    }} >
                                    Coupon & Charges
                                </Link>

                            </Box>

                            <Stack>
                                <Stack direction={"row"} pt={1} gap={1}>
                                    <Button
                                        variant='outlined'
                                        fullWidth
                                        disabled={isSubmitting}
                                        type="submit"
                                    >Save</Button>
                                    <Button
                                        variant='contained'
                                        disabled={isSubmitting}
                                        fullWidth
                                        onClick={() => setOpenPaymentDialog(values)}
                                    >Pay Now</Button>

                                </Stack>
                            </Stack>
                        </StandardCard>
                    </>
                ) : (
                    <EmptyOrderCard />
                )}
            </Box>

            {/* {openCashDialog &&
                <CashPaymentDialog
                    open={true}
                    orderInfo={openCashDialog}
                    onClose={() => setOpenCashDialog(null)}
                />
            }
            {openCardDialog &&
                <CardPaymentDialog
                    open={true}
                    orderInfo={openCardDialog}
                    onClose={() => setOpenCardDialog(null)}
                />
            } */}
            {openPaymentDialog && <OrderPaymentDialog
                open={true}
                orderInfo={openPaymentDialog}
                onClose={() => setOpenPaymentDialog(null)}
                onPaymentSuccess={handlePaymentSuccess}
            />}
            {openExtraChargesDialog && <ExtraChargesDialog
                open={true}
                orderInfo={openExtraChargesDialog}
                onClose={() => setOpenExtraChargesDialog(null)}
            />}

        </Box>
    )
}



export const EmptyOrderCard: React.FC = () => {
    return (
        <StandardCard fullHeight>
            <Box display={"flex"} flexDirection={"column"} sx={{ height: "100%", pb: 2 }}>
                <Stack
                    direction={"column"}
                    gap={2}
                    alignItems={"center"}
                    justifyContent={"center"}
                    margin={"auto"}
                >
                    <FaHourglassEnd fontSize={"2.2rem"} />
                    <Typography variant="h4" fontWeight={"700"}>
                        No Items Added
                    </Typography>
                </Stack>
                <CustomRoundButton title="Place Order" disabled fullWidth />
            </Box>
        </StandardCard>
    );
};
