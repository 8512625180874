import { TableRow, TableCell, Typography, Box, styled } from '@mui/material'
import { DOLLER_SYMBOL } from '../../../../constants/constants'

interface IUberOrderItemCardPRops {
    quantity: number,
    item: string,
    price: string
    subItems: {
        quantity: number,
        item: string,
        price: string
    }[]
}

const MuiCustomTableCell = styled(TableCell)({
    borderBottom: "none",
    paddingBlock: 0
})
const MuiCustomTableCellEnd = styled(TableCell)({
    paddingBlock: 0,
})
export const UberOrderItemCard = () => {
    return (
        <>
            <TableRow>
                <MuiCustomTableCell width={80} sx={{ paddingTop: 2 }}>
                    <Typography variant='h5'>1X</Typography>
                </MuiCustomTableCell>
                <MuiCustomTableCell sx={{ paddingTop: 2 }}>
                    <Typography variant='h5'>Lentil soup</Typography>
                </MuiCustomTableCell>
                <MuiCustomTableCell align="right" sx={{ paddingTop: 2 }}>
                    <Typography variant='h5'> {`${DOLLER_SYMBOL} 50`}</Typography>
                </MuiCustomTableCell>
            </TableRow>

            <TableRow>
                <MuiCustomTableCell width={80} />
                <MuiCustomTableCell sx={{ pl: 4 }}>
                    <Typography variant='subtitle1' sx={{ color: "#ccc", mt: 1, textTransform: "uppercase" }}>Additional Toppings</Typography>
                </MuiCustomTableCell>
                <MuiCustomTableCell />
            </TableRow>

            <TableRow>
                <MuiCustomTableCellEnd width={80} sx={{ paddingBottom: 2 }} />
                <MuiCustomTableCellEnd  sx={{ paddingBottom: 2, pl: 4  }}>
                    <Typography textAlign={"left"} variant='body1'>Low Sodium</Typography>
                </MuiCustomTableCellEnd>
                <MuiCustomTableCellEnd align="right" sx={{ paddingBottom: 2 }}>
                    <Typography variant='h5'> {`${DOLLER_SYMBOL} 2`}</Typography>
                </MuiCustomTableCellEnd>
            </TableRow>
        </>
    )
}
