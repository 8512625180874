import { useRoutes } from "react-router-dom";
import "./App.css";
import { getAppRoutes } from "./router/router";
import { CssBaseline } from "@mui/material";
import 'reactflow/dist/style.css';
import { LoadingAndErrors } from "./views/Loading&Errors/LoadingAndErrors";
import { UberEatsProvider } from "./providers/UberEatsProvider/UberEatsProvider";
import { useAuthProvider } from "./providers";

function App() {
  const { authInfo: { user: { role_value } } } = useAuthProvider();
  const routing = useRoutes(getAppRoutes(role_value === "USER"));

  return (
    <>
      <UberEatsProvider>
        <CssBaseline />
        {routing}
      </UberEatsProvider>
      <LoadingAndErrors />
    </>
  );
}

export default App;