import { createAsyncThunk } from "@reduxjs/toolkit"
import { axios_api } from "../../../api/axios-api"
import { IQueryParams, getSearchQuery } from "../../../common"
import { ICompanyOutlet } from "./company-outlets.types"
import { defaultOutlet } from "./company-outlets.state"
import { uploadSingleFileAsync } from "../../../helpers/uploadFilesAsync"
import { closeLoaderWithMessage, openLoaderWithMessage } from "../../common"
import { createAsyncThunkPostWrapper } from "../../store.wrapper"

export const GET_OUTLET_BASE_URL = "/company/get-outlet"
const UPSERT_OUTLET_BASE_URL = "/company/upsert-outlet"


export const fetchOutletsListAsync = createAsyncThunk<{ data: ICompanyOutlet[], count: number }, IQueryParams>(
    'outlets/fetchOutletsListAsync', async (queryParams: IQueryParams) => {
        const searchQuery = getSearchQuery(queryParams);
        const response = await axios_api.get(`${GET_OUTLET_BASE_URL}${searchQuery}`)
        return response.data as { data: ICompanyOutlet[], count: number }
    },
)

export const fetchSingleOutletAsync = createAsyncThunk<ICompanyOutlet, string>(
    'outlets/fetchSingleOutletAsync', async (uuid: string, thunkAPI) => {
        const response = await axios_api.get(`${GET_OUTLET_BASE_URL}?outlet_uuid=${uuid}`)
        return response.data?.data?.length > 0 ? response.data?.data[0] : defaultOutlet
    },
)

interface IUpsertCompanyOutlet {
    payload: ICompanyOutlet
    file: File | null,
    onCallback: (isSuccess: boolean) => void
}

export const upsertCompanyOutletAsync = createAsyncThunkPostWrapper<ICompanyOutlet, IUpsertCompanyOutlet>(
    'outlets/upsertCompanyOutletAsync', async ({ payload, file, onCallback }: IUpsertCompanyOutlet, thunkAPI) => {
        const { create_ts, insert_ts, ...restPayload } = payload
        thunkAPI.dispatch(openLoaderWithMessage("Saving Changes..!"))
        if (file) {
            const path = await uploadSingleFileAsync({
                file,
                moduleName: "COMPANY",
                asPayload: {
                    company_name: payload.outlet_name,
                    file_name: file.name,
                    file_ext: file.name.split('.').pop() || "",
                }
            })
            restPayload.outlet_logo = path
        }
        const response = await axios_api.post(UPSERT_OUTLET_BASE_URL, restPayload)
        onCallback(true)
        return thunkAPI.fulfillWithValue(response.data)
    },
)


