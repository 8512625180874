import React from "react";
import { ISidebarMenuItem } from "../layout/FullLayout/sidebar/Siderbar.types";
import { posMenu } from "../layout/FullLayout/sidebar/menuItems";
import { useRolesContext } from "./RolesProvider/RolesProvider";
import { IMenuItem } from "../layout/FullLayout/sidebar/types";

export const useSecureMenuList = () => {

  const [sidebarMenuItems, setSidebarMenuItems] = React.useState<ISidebarMenuItem[]>(posMenu)

  React.useEffect(() => {
    setSidebarMenuItems(posMenu)
  }, [])

  const { roles } = useRolesContext();

  React.useEffect(() => {
    if (roles.length > 0) {
      const accessibleModuleIds = roles
        .filter(
          (module) =>
            module.show_module ===
            1 /* Add more conditions for other permissions if needed */,
        )
        .map((module) => module.module_key);

      // Function to filter menu items recursively
      const filterMenuItems = (menuList: ISidebarMenuItem[]) => {
        return menuList
          .map((item) => {
            if (item.children) {
              const filteredChildren = filterMenuItems(item.children);
              item.children = filteredChildren.filter(
                (childItem) =>
                  childItem.accessibleModules.some((id) =>
                    accessibleModuleIds.includes(id),
                  ) || childItem.allowFullAccess,
              );
            }
            return item;
          })
          .filter(
            (item) =>
              item.accessibleModules.some((id) => accessibleModuleIds.includes(id)) ||
              item.allowFullAccess,
          );
      };

      // Filter the static menu list based on accessibleModuleIds
      const filteredMenu = filterMenuItems(posMenu);
      setSidebarMenuItems(filteredMenu);
    }
  }, [roles]);

  return sidebarMenuItems;
};
