import { ACCESSIBLE_MUDULE_KEYS } from "../../constants/enum";
import { AuthorizedRoute } from "../../router/AuthorizedRoute";
import { ManageSingleShift } from "./AllShifts/ManageSingleShift";
import { ShiftsTable } from "./AllShifts/ShiftsTable";
import { ManagePunchInOut } from "./PunchInOut/ManagePunchInOut";
import { ALL_SHIFTS_ROUTE, SHIFTS_PUNCH_IN_OUT_ROUTE } from "./ShiftAndPunchInOut.constants";

export const shiftAndPunchInOutRoutes = [
    {
        path: ALL_SHIFTS_ROUTE,
        exact: true,
        element: <AuthorizedRoute moduleIds={[ACCESSIBLE_MUDULE_KEYS.SHIFT]}><ShiftsTable /></AuthorizedRoute>
    },
    {
        path: `${ALL_SHIFTS_ROUTE}/manage/:user_uuid/:shift_code`,
        exact: true,
        element: <AuthorizedRoute moduleIds={[ACCESSIBLE_MUDULE_KEYS.SHIFT]}><ManageSingleShift /></AuthorizedRoute>
    },
    {
        path: SHIFTS_PUNCH_IN_OUT_ROUTE,
        exact: true,
        element: <AuthorizedRoute moduleIds={[ACCESSIBLE_MUDULE_KEYS.SHIFT]}><ManagePunchInOut /></AuthorizedRoute>
    },
] 