import { ACCESSIBLE_MUDULE_KEYS } from "../../constants/enum";
import { AuthorizedRoute } from "../../router/AuthorizedRoute";
import { Inventory } from "./Inventory";
import { CATEGORIES_ROUTE, CUSTOMIZATION_ROUTE, INVENTORY_ROUTE, PRODUCTS_ROUTE } from "./Inventory.constants";
import { ManageSingleProduct } from "./Products/ManageSingleProduct";

export const inventoryRoutes = [

    // ##################### Products Route ############################
    {
        path: `${PRODUCTS_ROUTE}`,
        exact: true,
        element: <AuthorizedRoute moduleIds={[ACCESSIBLE_MUDULE_KEYS.PRODUCT]}><Inventory activeTab={0} /></AuthorizedRoute>
    },
    {
        path: `${PRODUCTS_ROUTE}/manage`,
        exact: true,
        element: <AuthorizedRoute moduleIds={[ACCESSIBLE_MUDULE_KEYS.PRODUCT]}><ManageSingleProduct /></AuthorizedRoute>
    },
    {
        path: `${PRODUCTS_ROUTE}/manage/:uuid`,
        exact: true,
        element: <AuthorizedRoute moduleIds={[ACCESSIBLE_MUDULE_KEYS.PRODUCT]}><ManageSingleProduct /></AuthorizedRoute>
    },
    // ##################### Categories Route ############################
    {
        path: CATEGORIES_ROUTE,
        exact: true,
        element: <AuthorizedRoute moduleIds={[ACCESSIBLE_MUDULE_KEYS.CATEGORY]}><Inventory activeTab={1} /></AuthorizedRoute>
    },
    // ##################### Customization Route ############################
    {
        path: `${CUSTOMIZATION_ROUTE}`,
        exact: true,
        element: <AuthorizedRoute moduleIds={[ACCESSIBLE_MUDULE_KEYS.PRODUCT]}><Inventory activeTab={2} /></AuthorizedRoute>
    },
    // ##################### Inventory List Route ############################
    {
        path: `${INVENTORY_ROUTE}`,
        exact: true,
        element: <AuthorizedRoute moduleIds={[ACCESSIBLE_MUDULE_KEYS.PRODUCT]}><Inventory activeTab={2} /></AuthorizedRoute>
    },


] 