import { ACCESSIBLE_MUDULE_KEYS } from "../../../constants/enum";
import { AuthorizedRoute } from "../../../router/AuthorizedRoute";
import { COMPANY_OUTLETS_ROUTE, OUTLET_REGISTERS_ROUTE, REGISTERS_STAFF_ROUTE } from "./CompanyOutlets.constants";
import { CompanyOutletsTable } from "./CompanyOutletsTable";
import { ManageCompanyOutletWithTabs } from "./ManageCompanyOutletWithTabs";
import { ManageSingleOutlet } from "./ManageSingleOutlet";
import { ManageSingleRegister } from "./OutletRegisters/ManageSingleRegister";
import { ManageSingleRegisterStaff } from "./RegisterStaff/ManageSingleRegisterStaff";

export const companyLocationsRoutes = [
    { path: `${COMPANY_OUTLETS_ROUTE}`, exact: true, element: <AuthorizedRoute moduleIds={[ACCESSIBLE_MUDULE_KEYS.OUTLET]}><CompanyOutletsTable /></AuthorizedRoute> },
    { path: `${COMPANY_OUTLETS_ROUTE}/manage`, exact: true, element: <AuthorizedRoute moduleIds={[ACCESSIBLE_MUDULE_KEYS.OUTLET]}><ManageSingleOutlet /></AuthorizedRoute> },
    { path: `${COMPANY_OUTLETS_ROUTE}/manage/:uuid`, exact: true, element: <AuthorizedRoute moduleIds={[ACCESSIBLE_MUDULE_KEYS.OUTLET]}><ManageCompanyOutletWithTabs /></AuthorizedRoute> },

    { path: `${OUTLET_REGISTERS_ROUTE}`, exact: true, element: <AuthorizedRoute moduleIds={[ACCESSIBLE_MUDULE_KEYS.OUTLET]}><ManageCompanyOutletWithTabs /></AuthorizedRoute> },
    { path: `${OUTLET_REGISTERS_ROUTE}/manage`, exact: true, element: <AuthorizedRoute moduleIds={[ACCESSIBLE_MUDULE_KEYS.OUTLET]}><ManageSingleRegister /></AuthorizedRoute> },
    { path: `${OUTLET_REGISTERS_ROUTE}/manage/:register_uuid`, exact: true, element: <AuthorizedRoute moduleIds={[ACCESSIBLE_MUDULE_KEYS.OUTLET]}><ManageSingleRegister /></AuthorizedRoute> },


    { path: `${REGISTERS_STAFF_ROUTE}/manage`, exact: true, element: <AuthorizedRoute moduleIds={[ACCESSIBLE_MUDULE_KEYS.OUTLET]}><ManageSingleRegisterStaff /></AuthorizedRoute> },
    { path: `${REGISTERS_STAFF_ROUTE}/manage/:register_staff_uuid`, exact: true, element: <AuthorizedRoute moduleIds={[ACCESSIBLE_MUDULE_KEYS.OUTLET]}><ManageSingleRegisterStaff /> </AuthorizedRoute>},

]