import { pos_app_routes } from "../../constants/constants";
import { ACCESSIBLE_MUDULE_KEYS } from "../../constants/enum";
import { AuthorizedRoute } from "../../router/AuthorizedRoute";
import { PaymentHistoryTable } from "./PaymentHistoryTable";



export const paymentHostoryRoutes = [

    // ##################### Payment Received Route ############################
    {
        path: pos_app_routes.private.accounting.userPaymentHistory,
        exact: true,
        element: <AuthorizedRoute moduleIds={[ACCESSIBLE_MUDULE_KEYS.PAYMENT_RECEIVED]}><PaymentHistoryTable /></AuthorizedRoute>
    },
] 