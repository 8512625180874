import React, { useState } from 'react'
import { Box, Button, InputAdornment, Paper, Typography } from "@mui/material";
import { Add, AutoFixHigh, Remove } from '@mui/icons-material';
import { useAppDispatch, useAppSelector } from '../../../redux';
import { IProduct } from '../../../redux/inventory';
import { usePublicOutletContext } from '../../../providers/PublicOutletContext';




export interface IMenuProductCardProps {
    data: IProduct;
}

export const MenuProductCard: React.FC<IMenuProductCardProps> = ({
    data,
}) => {
    const { OUTLET_CURRENCY_SYMBOL } = usePublicOutletContext()

    return (
        <Box
            display={"flex"}
            alignItems={"flex-start"}
            justifyContent={"flex-start"}
        >
            <Paper
                square={false}
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    alignContent: "space-between",
                    bgcolor: "#2C2D2C" /* FBC1D9, 2C2D2C */,
                    color: "#FFFFFF",
                    borderRadius: 1,
                    width: "100%",
                }}
            >
                <Box
                    width={"4%"}
                    bgcolor={"#FBC1D9"}
                    borderRadius={"8px 0 0 8px"}
                ></Box>
                <Box
                    display={"flex"}
                    flexDirection={"column"}
                    gap={1.8}
                    width={"96%"}
                    px={1.5}
                    pb={1.5}
                >
                    <Box pt={1} display={"flex"} justifyContent={"flex-start"} gap={1}>
                        <Typography
                            color={"#D0D0D0"}
                            variant="h5"
                            fontWeight={"bold"}
                        >
                            {data.category_name}
                        </Typography>

                        {data.is_out_of_stock &&
                            <Typography
                                color={"#545454"}
                                variant="h5"
                                fontWeight={"bold"}
                                sx={{
                                    background: "#ffe413",
                                    paddingX: 1,
                                    borderRadius: 0.5
                                }}
                            >
                                Out of stock
                            </Typography>
                        }
                    </Box>
                    <Box display="flex" justifyContent="space-between" alignItems="center" gap={1} >
                        <Box minWidth={"40%"}>
                            <Typography variant="h4" fontWeight={"bold"} letterSpacing={-0.5}>
                                {data.product_name}
                            </Typography>
                            <Typography
                                variant="h4"
                                color={"#D0D0D0"}
                                fontWeight={"600"}
                            >
                                {data.product_price_by === "WEIGHT" ? `${OUTLET_CURRENCY_SYMBOL}${data.product_price}/${data.weight_per_unit}${data.product_weight_unit}` : `${OUTLET_CURRENCY_SYMBOL}${data.product_price}`}
                            </Typography>
                        </Box>

                    </Box>

                </Box>
            </Paper>
        </Box>
    );
};

