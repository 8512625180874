import React from 'react'
import PageContainer from '../../Components/PageContainer/PageContainer'
import { Box, Grid } from '@mui/material'
import { MenuCategoryList } from './MenuCategory/MenuCategoryList'
import { MenuProductList } from './MenuProduct/MenuProductList'

export const AppPublicMenu = () => {

    return (
        <PageContainer title="Menu" description="Menu page">
            <Box sx={{ padding: 3 }}>
                <Grid container spacing={1.5} pt={2} flexWrap="wrap">
                    <Grid item xs={12} sm={3} md={3} lg={2}>
                        <MenuCategoryList />
                    </Grid>

                    <Grid item xs={12} sm={9} md={9} lg={10}>
                        <MenuProductList />
                    </Grid>
                </Grid>
            </Box>
        </PageContainer>
    )
}
