import { createSlice } from "@reduxjs/toolkit";
import { initialAccountingDashboardState } from "./analytics.types";
import { fetchDashboardExpenseCatgoriesAsync, fetchDashboardShiftsAsync, fetchDashboardTakeAwayOrdersAsync, fetchEarningBoardAsync } from "./analytics.actions";
import { LoadState } from "../../constants/enum";

const analyticsSlice = createSlice({
    initialState: initialAccountingDashboardState,
    name: "analytics",
    reducers: {
        clearAnalyticsState: (state) => {
            return initialAccountingDashboardState
        }
    },
    extraReducers: (builder) => {
        //#################################### Multiple Airline Flights ##############################################
        builder.addCase(fetchEarningBoardAsync.pending, (state, action) => {
            state.earningGraphLoading = LoadState.pending
        })
        builder.addCase(fetchEarningBoardAsync.fulfilled, (state, action) => {
            state.earningGraphLoading = LoadState.succeeded
            state.earningGraph = action.payload.data
        })
        builder.addCase(fetchEarningBoardAsync.rejected, (state, action) => {
            state.earningGraphLoading = LoadState.failed;
        })


        //#################################### fetchDashboardTakeAwayOrdersAsync ##############################################
        builder.addCase(fetchDashboardTakeAwayOrdersAsync.pending, (state, action) => {
            state.take_away_orders_list.loading = LoadState.pending
        })
        builder.addCase(fetchDashboardTakeAwayOrdersAsync.fulfilled, (state, action) => {
            state.take_away_orders_list.loading = LoadState.succeeded
            state.take_away_orders_list.data = action.payload.data
            state.take_away_orders_list.totalRecords = action.payload.totalRecords
        })
        builder.addCase(fetchDashboardTakeAwayOrdersAsync.rejected, (state, action) => {
            state.take_away_orders_list.loading = LoadState.failed;
            state.take_away_orders_list.error = action.payload as string;
        })



        //#################################### fetchDashboardTakeAwayOrdersAsync ##############################################
        builder.addCase(fetchDashboardExpenseCatgoriesAsync.pending, (state, action) => {
            state.expense_categories_list.loading = LoadState.pending
        })
        builder.addCase(fetchDashboardExpenseCatgoriesAsync.fulfilled, (state, action) => {
            state.expense_categories_list.loading = LoadState.succeeded
            state.expense_categories_list.data = action.payload.data
            state.expense_categories_list.overview = action.payload.overview
            state.expense_categories_list.totalRecords = action.payload.totalRecords
        })
        builder.addCase(fetchDashboardExpenseCatgoriesAsync.rejected, (state, action) => {
            state.expense_categories_list.loading = LoadState.failed;
            state.expense_categories_list.error = action.payload as string;
        })


           //#################################### fetchDashboardShiftsAsync ##############################################
           builder.addCase(fetchDashboardShiftsAsync.pending, (state, action) => {
            state.active_shifts_list.loading = LoadState.pending
        })
        builder.addCase(fetchDashboardShiftsAsync.fulfilled, (state, action) => {
            state.active_shifts_list.loading = LoadState.succeeded
            state.active_shifts_list.data = action.payload.data
            state.active_shifts_list.totalRecords = action.payload.totalRecords
        })
        builder.addCase(fetchDashboardShiftsAsync.rejected, (state, action) => {
            state.active_shifts_list.loading = LoadState.failed;
            state.active_shifts_list.error = action.payload as string;
        })

    },
});

export const analyticsReducer = analyticsSlice.reducer;
export const { clearAnalyticsState } = analyticsSlice.actions;
