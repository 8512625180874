import { ACCESSIBLE_MUDULE_KEYS } from "../../constants/enum";
import { AuthorizedRoute } from "../../router/AuthorizedRoute";
import { TableMenuLayout } from "./TableMenuLayout";
import { Tables } from "./Tables";
import { TABLES_LAYOUT_ROUTE, TABLES_ROUTE } from "./Tables.constants";

export const tablesRoutes = [
    {
        path: TABLES_ROUTE,
        exact: true,
        element: <AuthorizedRoute moduleIds={[ACCESSIBLE_MUDULE_KEYS.LAYOUT]}><Tables /></AuthorizedRoute>
    },
    {
        path: TABLES_LAYOUT_ROUTE,
        exact: true,
        element: <AuthorizedRoute moduleIds={[ACCESSIBLE_MUDULE_KEYS.LAYOUT]}><TableMenuLayout /></AuthorizedRoute>
    },
    {
        path: `${TABLES_ROUTE}/view/:uuid`,
        exact: true,
        element: <AuthorizedRoute moduleIds={[ACCESSIBLE_MUDULE_KEYS.LAYOUT]}><TABLES_ROUTE /></AuthorizedRoute>
    },
] 