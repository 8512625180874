import React from "react";
import { PublicOutletContext } from "./PublicOutletContext.context";


export function usePublicOutletContext() {
    const context = React.useContext(PublicOutletContext);
  
    if (!context) {
      throw new Error('useOutletContext: Context must be used inside AuthProvider');
    }
  
    return context;
  }