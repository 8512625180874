import { ACCESSIBLE_MUDULE_KEYS } from "../../../constants/enum";
import { AuthorizedRoute } from "../../../router/AuthorizedRoute";
import { ManageSingleReceipt } from "./ManageSingleReceipt";
import { RECEIPT_COMPOSER_ROUTE } from "./ReceiptComposer.constants";
import { ReceiptsTable } from "./ReceiptsTable";


export const receiptComposerRoutes = [
    { path: `${RECEIPT_COMPOSER_ROUTE}`, exact: true, element: <AuthorizedRoute moduleIds={[ACCESSIBLE_MUDULE_KEYS.RECEIPT_COMPOSER]}><ReceiptsTable /></AuthorizedRoute> },
    { path: `${RECEIPT_COMPOSER_ROUTE}/manage`, exact: true, element: <AuthorizedRoute moduleIds={[ACCESSIBLE_MUDULE_KEYS.RECEIPT_COMPOSER]}><ManageSingleReceipt /></AuthorizedRoute> },
    { path: `${RECEIPT_COMPOSER_ROUTE}/manage/:uuid`, exact: true, element: <AuthorizedRoute moduleIds={[ACCESSIBLE_MUDULE_KEYS.RECEIPT_COMPOSER]}><ManageSingleReceipt /></AuthorizedRoute> },
]