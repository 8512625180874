import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { LoadState } from "../../../constants/enum";
import { defaultCompanyOutletState } from "./company-outlets.state";
import { fetchOutletsListAsync, fetchSingleOutletAsync } from "./company-outlets.actions";
import { ICompanyOutlet } from "./company-outlets.types";

const outletsSlice = createSlice({
    initialState: defaultCompanyOutletState,
    name: "outlets",
    reducers: {
        setCurrentUserOutletInfo: (state, action: PayloadAction<ICompanyOutlet>) => {
            state.current_outlet.data = action.payload
        },
        clearOutletsListSync: (state) => {
            state.outlets_list.data = defaultCompanyOutletState["outlets_list"]["data"]
        },
        clearSingleOutletsSync: (state) => {
            state.single_outlet.data = defaultCompanyOutletState["single_outlet"]["data"]
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchOutletsListAsync.pending, (state, action) => {
            state.outlets_list.loading = LoadState.pending
        })
        builder.addCase(fetchOutletsListAsync.fulfilled, (state, action: PayloadAction<{ data: ICompanyOutlet[], count: number }>) => {
            state.outlets_list.loading = LoadState.succeeded
            state.outlets_list.data = action.payload.data
            state.outlets_list.count = action.payload.count
            state.outlets_list.error = null
        })
        builder.addCase(fetchOutletsListAsync.rejected, (state, action) => {
            state.outlets_list.error = action.error.message as string
        })




        builder.addCase(fetchSingleOutletAsync.pending, (state, action) => {
            state.single_outlet.loading = LoadState.pending
        })
        builder.addCase(fetchSingleOutletAsync.fulfilled, (state, action) => {
            state.single_outlet.loading = LoadState.succeeded
            state.single_outlet.data = action.payload
            state.single_outlet.error = null
        })
        builder.addCase(fetchSingleOutletAsync.rejected, (state, action) => {
            state.single_outlet.error = action.error.message as string
        })
    },
});

export const outletsReducer = outletsSlice.reducer;
export const { clearOutletsListSync, clearSingleOutletsSync,setCurrentUserOutletInfo } = outletsSlice.actions;
