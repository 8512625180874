/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button, Grid, IconButton, InputAdornment, Tooltip, Typography } from "@mui/material";
import React from "react";
import { useFormik } from "formik";
import { IStoreState, useAppDispatch, useAppSelector } from "../../../redux";
import { useNavigate, useParams } from "react-router-dom";
import { COMPANY_OUTLETS_ROUTE } from "./CompanyOutlets.constants";
import { IBusinessHours, ICompanyOutlet, IOpeningHours, clearSingleOutletsSync, fetchSingleOutletAsync, upsertCompanyOutletAsync } from "../../../redux/settings/outlets";
import { StandardCard } from "../../../Components/Card";
import { ProfileIntroCard } from "../../../Components/Profile/ProfileIntoCard";
import { ControlledCustomSelect, CustomCheckBoxWithLabel, OutlinedNumberField, OutlinedTextField } from "../../../Components/formComponents";
import { OutlinedDesktopTimePicker, OutlinedTimePicker } from "../../../Components/formComponents/CustomDatePicker";
import moment from "moment";
import { CompaniesDropDown } from "../Company/components/CompaniesDropDown";
import { capitalizeString } from "../../../helpers/capitalizeString";
import { AddCircle, Delete } from "@mui/icons-material";
import Add from "@mui/icons-material/Add";
import { PageLoader } from "../../../Components/PageLoader/PageLoader";
import { LoadState } from "../../../constants/enum";
import { outlet_currencies_list } from "../../../constants/constants";
import { MdOutlinePercent } from "react-icons/md";

export const ManageSingleOutlet = () => {
  const { uuid: outlet_uuid } = useParams<{ uuid?: string }>();
  const navigate = useNavigate()
  const dispatch = useAppDispatch();
  const { data: singleOutletInfo, loading } = useAppSelector((storeState: IStoreState) => storeState.settings.outlets.single_outlet);

  const [fileToUpload, setFileToUpload] = React.useState<File | null>(null);

  const {
    values,
    errors,
    isSubmitting,
    setSubmitting,
    setFieldValue,
    setValues,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: { ...singleOutletInfo },
    validate: (values) => {
      const errors: any = {};
      if (!values.company_uuid) {
        errors.company_uuid = "*This is required field";
      }
      if (!values.outlet_name) {
        errors.outlet_name = "*This is required field";
      }
      if (!values.outlet_email) {
        errors.outlet_email = "*This is required field";
      }
      if (!values.default_tax_rate) {
        errors.default_tax_rate = "*This is required field";
      }
      if (!values.outlet_currency) {
        errors.outlet_currency = "*This is required field";
      }
      if (!values.outlet_address) {
        errors.outlet_address = "*This is required field";
      }
      if (values.outlet_phone_number && values.outlet_phone_number.toString().length !== 10) {
        errors.outlet_phone_number = "*Phone number must be 10 digits";
      }
      return errors;
    },
    onSubmit: (values) => {
      dispatch(
        upsertCompanyOutletAsync({
          payload: values, file: fileToUpload, onCallback(isSuccess) {
            if (isSuccess) {
              navigate(COMPANY_OUTLETS_ROUTE)
            }
          }
        }),
      ).finally(() => {
        setSubmitting(false);
      });
    },
  });


  React.useEffect(() => {
    if (!outlet_uuid) return
    dispatch(fetchSingleOutletAsync(outlet_uuid));
  }, [outlet_uuid]);

  React.useEffect(() => {
    setValues(singleOutletInfo);
  }, [singleOutletInfo]);

  React.useEffect(() => {
    return () => {
      dispatch(clearSingleOutletsSync())
    }
  }, []);


  const handleBusinessHoursChange = (key: keyof ICompanyOutlet["business_hours"]) => (subKey: keyof IBusinessHours, value: any) => {
    setValues({
      ...values,
      business_hours: {
        ...values.business_hours,
        [key]: {
          ...values.business_hours[key],
          [subKey]: value
        }
      }
    })
  }

  const handleBusinessHoursTimeChange = (key: keyof ICompanyOutlet["business_hours"], index: number) => (subKey: keyof IOpeningHours, value: any) => {
    setValues({
      ...values,
      business_hours: {
        ...values.business_hours,
        [key]: {
          ...values.business_hours[key],
          opening_hours: [...values.business_hours[key]["opening_hours"]].map((hours, idx) => {
            if (idx === index) {
              return {
                ...hours,
                [subKey]: value
              }
            }
            return hours
          })
        }
      }
    })
  }

  const handleAddNewRowClick = (key: keyof ICompanyOutlet["business_hours"]) => () => {
    setValues({
      ...values,
      business_hours: {
        ...values.business_hours,
        [key]: {
          ...values.business_hours[key],
          opening_hours: [
            ...values.business_hours[key]["opening_hours"],
            { opens_at: null, closed_at: null }
          ]
        }
      }
    })
  }

  const handleDeleteRowClick = (key: keyof ICompanyOutlet["business_hours"], index: number) => () => {
    setValues({
      ...values,
      business_hours: {
        ...values.business_hours,
        [key]: {
          ...values.business_hours[key],
          opening_hours: [...values.business_hours[key]["opening_hours"]].splice(index, 1)
        }
      }
    })
  }

  return (
    <PageLoader loading={LoadState.pending === loading}>
      <form onSubmit={handleSubmit}>
        <Typography variant='h2'>Manage Outlet</Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} md={3}>
            <StandardCard
              sx={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <ProfileIntroCard
                fileUrl={values.outlet_logo}
                title="Change Logo"
                subTitle="Change Outlet logo from here"
                onChange={(file) => setFileToUpload(file)}
                onFileReset={() => setFieldValue("outlet_logo", null)}

              />
            </StandardCard>
          </Grid>
          <Grid item xs={12} md={9}>
            <StandardCard
              heading="Outlet Information"
            >
              <Grid container spacing={2}>
                <Grid item xs={12} md={3}>
                  <OutlinedTextField
                    label="Outlet Name"
                    id="outlet_name"
                    value={values.outlet_name}
                    onChange={handleChange}
                    error={errors.outlet_name}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <OutlinedTextField
                    label="Outlet Code"
                    id="outlet_code"
                    value={values.outlet_code}
                    onChange={handleChange}
                    error={errors.outlet_code}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <CompaniesDropDown
                    value={values.company_uuid}
                    onChange={(newValue) => setFieldValue("company_uuid", newValue)}
                    error={errors.company_uuid}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <OutlinedTextField
                    label="Outlet Email"
                    id="outlet_email"
                    value={values.outlet_email}
                    onChange={handleChange}
                    error={errors.outlet_email}
                  />
                </Grid>

                <Grid item xs={12} md={3}>
                  <OutlinedTextField
                    label="Outlet Phone Number"
                    id="outlet_phone_number"
                    type="number"
                    value={values.outlet_phone_number}
                    onChange={handleChange}
                    error={errors.outlet_phone_number}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <OutlinedTimePicker
                    label="Outlet Opening Time"
                    fullWidth
                    format="hh:mm A"
                    value={moment(values.outlet_opening_time)}
                    onChange={(newValue) => {
                      setFieldValue("outlet_opening_time", newValue.toISOString());
                    }}
                    error={errors.outlet_opening_time}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <OutlinedTimePicker
                    label="Outlet Closing Time"
                    fullWidth
                    format="hh:mm A"
                    value={moment(values.outlet_closing_time)}
                    onChange={(newValue) => {
                      setFieldValue("outlet_closing_time", newValue.toISOString());
                    }}
                    error={errors.outlet_closing_time}
                  />
                </Grid>

                <Grid item xs={12} md={3}>
                  <OutlinedTextField
                    label="Outlet GST Number"
                    id="outlet_gsthst_number"
                    value={values.outlet_gsthst_number}
                    onChange={handleChange}
                    error={errors.outlet_gsthst_number}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <ControlledCustomSelect
                    label="Default Currency"
                    name="outlet_currency"
                    fullWidth
                    options={outlet_currencies_list}
                    placeholder="Receipt Type"
                    value={values.outlet_currency}
                    onChange={handleChange}
                    error={errors.outlet_currency}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <OutlinedNumberField
                    label="Default Tax Rate"
                    id="default_tax_rate"
                    value={values.default_tax_rate}
                    onChange={handleChange}
                    error={errors.default_tax_rate}
                    InputProps={{
                      endAdornment: (
                          <InputAdornment position="start">
                              <MdOutlinePercent fontSize={"18px"} />
                          </InputAdornment>
                      ),
                  }}
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <OutlinedTextField
                    label="Outlet Address"
                    id="outlet_address"
                    multiline
                    minRows={3}
                    value={values.outlet_address}
                    onChange={handleChange}
                    error={errors.outlet_address}
                  />
                </Grid>
              </Grid>
            </StandardCard>
          </Grid>

          <Grid item xs={12} md={12}>
            <StandardCard
              heading="Business Hours"
            >
              <Grid container spacing={1} alignItems={"center"}>
                {values.business_hours && Object.keys(values.business_hours).map((key) => {
                  const businessKeyData = values.business_hours[key as "monday"]

                  return (
                    <>
                      {/* { key: obj[key].value } */}
                      <Grid item xs={12} md={1.5}>
                        <Typography variant="h5" pl={2}>{capitalizeString(key)}</Typography>
                      </Grid>
                      <Grid item xs={12} md={1}>
                        <CustomCheckBoxWithLabel
                          checked={businessKeyData.is_closed === 1 ? true : false}
                          label="Closed"
                          name="company_locations_y_n"
                          onChange={(evt) => handleBusinessHoursChange(key as "monday")("is_closed", evt.currentTarget.checked ? 1 : 0)}
                        />
                      </Grid>

                      {!businessKeyData.is_closed &&
                        <Grid item xs={12} md={1.5}>
                          <CustomCheckBoxWithLabel
                            checked={businessKeyData.open_24_hours === 1 ? true : false}
                            label="Open 24 Hours"
                            name="open_24_hours"
                            onChange={(evt) => handleBusinessHoursChange(key as "monday")("open_24_hours", evt.currentTarget.checked ? 1 : 0)}
                          />
                        </Grid>
                      }
                      <Grid item xs={12} md={5}>
                        {(!businessKeyData.is_closed && !businessKeyData.open_24_hours) && businessKeyData.opening_hours.map((hours, hrs_idx) => {
                          return (

                            <Grid container spacing={2} direction={"row"} mt={0}>
                              <Grid item xs={12} md={5}>
                                <OutlinedDesktopTimePicker
                                  label="Opening Time"
                                  fullWidth
                                  format="hh:mm A"
                                  value={moment(hours.opens_at)}
                                  startTime={hrs_idx === 1 ? businessKeyData.opening_hours[0].closed_at as string : undefined}
                                  onChange={(newValue) => handleBusinessHoursTimeChange(key as "monday", hrs_idx)("opens_at", newValue.toISOString())}
                                />
                              </Grid>
                              <Grid item xs={12} md={5}>
                                <OutlinedDesktopTimePicker
                                  label="Closing Time"
                                  fullWidth
                                  format="hh:mm A"
                                  value={moment(hours.closed_at)}
                                  startTime={hours.opens_at || undefined}
                                  onChange={(newValue) => handleBusinessHoursTimeChange(key as "monday", hrs_idx)("closed_at", newValue.toISOString())}
                                />
                              </Grid>
                              {(businessKeyData.opening_hours.length > 0 && businessKeyData.opening_hours.length < 2) &&
                                <Grid item xs={12} md={2}>
                                  <Tooltip title="Add New Hours">
                                    <IconButton onClick={handleAddNewRowClick(key as "monday")}>
                                      <Add />
                                    </IconButton>
                                  </Tooltip>
                                </Grid>}
                              {(hrs_idx === 1) &&
                                <Grid item xs={12} md={2}>
                                  <Tooltip title="Delete Hours">
                                    <IconButton onClick={handleDeleteRowClick(key as "monday", hrs_idx)}>
                                      <Delete />
                                    </IconButton>
                                  </Tooltip>
                                </Grid>}
                            </Grid>

                          )
                        })}
                      </Grid>

                      <Grid item xs={12} md={12} />
                    </>
                  );
                })}
              </Grid>
            </StandardCard>
          </Grid>
        </Grid>


        <Box sx={{ mt: 4 }}>
          <Button
            sx={{ width: "10%" }}
            disabled={isSubmitting}
            variant="contained"
            type="submit"
          >
            Save
          </Button>
        </Box>
      </form>
    </PageLoader>
  );
};
